type AcceptableCurrency = 'NGN' | 'USD' | 'GBP';

export const formatCurrency = (value: number | string, currency: AcceptableCurrency = 'NGN') => {
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency,
    });

    return formatter.format(+value);
};
