import React from 'react';
import styled from 'styled-components';

const StyledDiv = styled.div`
    position: relative;
    color: black;

    .form__header {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
    }

    .form__back-button {
        position: absolute;
        top: 30px;
        left: -160px;
    }

    .form__header h1 {
        color: black;
        text-align: center;
        font: Bold 39px/49px Adobe Clean;
        color: #000000;
        opacity: 1;
    }

    a {
        color: var(--primary-color);
    }

    .form__subheader {
        text-align: left;
        font: Bold 14px/17px Adobe Clean;
        letter-spacing: 1.4px;
        color: #9a9a9a;
        text-transform: uppercase;
        opacity: 1;
    }
`;

export const EntryFormContainer: React.FC = ({ children }) => {
    return <StyledDiv>{children}</StyledDiv>;
};
