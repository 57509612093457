type Arg = string | undefined | boolean | null | Arg[];

export const generateClassNames = (...classNames: Arg[]): string => {
    return classNames.reduce<string>((acc, current) => {
        if (!current) {
            return acc;
        }

        if (Array.isArray(current)) {
            return generateClassNames(...current);
        }

        return acc + ' ' + current;
    }, '');
};
