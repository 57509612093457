import React from 'react';
import { Button } from 'reactstrap';
import { ActivityItemStyleWrapper } from './activity-item.style';

type Props = {
    title: string;
    timeline: string;
    type: string;
};

export class ActivityItem extends React.Component<Props, {}> {
    public render() {
        const { title, timeline, type } = this.props;
        const btnTitle = type.toUpperCase();

        return (
            <ActivityItemStyleWrapper>
                <div className="activityItemWrapper">
                    <div className="item-1">
                        <p>
                            <i>{timeline}</i>
                        </p>
                    </div>
                    <div className="item-2">
                        <p>{title}</p>
                    </div>
                    <div className="item-3">
                        <Button type="button" className={`${type}`} disabled>
                            {btnTitle}
                        </Button>
                    </div>
                </div>
            </ActivityItemStyleWrapper>
        );
    }
}
