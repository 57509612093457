import { Allow, IsIn, IsString, Matches, MinLength } from 'class-validator';
import { BusinessNatures } from '../../../util/constants';
import { User } from '../../response';
import { BaseModel } from '../base-model';

export class UpdateCorporateClientModel implements BaseModel {
    @IsString({ message: 'Invalid id' })
    public id: string = '';

    @IsString()
    @MinLength(3, {
        message: 'Must be at least $constraint1 characters long',
    })
    public Business_Name: string = '';

    @IsIn(BusinessNatures, { message: 'Invalid selection' })
    public Business_Nature: string = '';

    @Matches(/^[12]\d{3}$/, { message: 'Invalid year format' })
    public Creation_Year: string = '';

    @IsString()
    @MinLength(3, {
        message: 'Must be at least $constraint1 characters long',
    })
    public Registration_Number: string = '';

    @IsString()
    @MinLength(3, {
        message: 'Must be at least $constraint1 characters long',
    })
    public Registered_Address: string = '';

    @Allow()
    public PhoneNumber: string = '';

    public Email: string = '';

    constructor(client: User) {
        if (client) {
            this.id = client.id;
            this.Business_Name = client.business_Name;
            this.Business_Nature = client.business_Nature;
            this.PhoneNumber = client.phoneNumber;
            this.Creation_Year = client.creation_Year.toString();
            this.Registered_Address = client.registered_Address;
            this.Registration_Number = client.registration_Number;
            this.Email = client.email;
            return;
        }
    }

    public beforeValidation() {}
}
