import styled from 'styled-components';

export const FormButton = styled.button`
    background: var(--primary-color);
    padding: 0 3rem;
    height: 57px;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 1.4px;
    color: white;
    border-radius: 0.5rem;
    border: none;
    outline: none !important;
    appearance: none;
    display: flex;
    justify-content: center;
    align-items: center;

    :disabled {
        background: #848484;
        cursor: not-allowed;
    }

    svg {
        margin-right: 5px;
        font-size: 12px;
    }

    &.small {
        height: 40px;
        padding: 0 25px;

        svg {
            height: 13px;
            width: 13px;
            stroke-width: 3px;
        }
    }
`;
