import { motion, MotionProps } from 'framer-motion';
import { capitalize } from 'lodash';
import React from 'react';
import { Check, Info, Triangle, X } from 'react-feather';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { SystemNotification } from '../../../models/response';
import { NotificationsActions } from '../../../store/notifications/notifications.actions';
import { NotificationTypes } from '../../../util/constants';

enum Colors {
    Success = '#18A956',
    Info = '#1A9BFC',
    Error = '#FD6D6B',
    Warning = '#FEBF2C',
}

const Container = styled(motion.div)`
    width: 100%;
    display: flex;
    background: white;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 8px;
    overflow: hidden;

    .toast__indicator {
        display: block;
        content: ' ';
        background: red;
        min-height: 70px;
        height: inherit;
        width: 8px;
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
    }

    .toast__body {
        display: flex;
        flex: 1;
    }

    .toast__icon {
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 20px;
        height: 20px;
        border-radius: 100%;
        margin: 20px 10px;
    }

    .toast__content {
        flex: 1;
        flex-shrink: 0;
        padding: 16px 0;
    }

    .toast__dismiss-button {
        background: transparent !important;
        border: none !important;
        outline: none !important;
        padding: 0 !important;
        margin: 20px !important;
        color: #888888;
    }

    .toast__header {
        font-size: 14px;
        font-weight: bold;
    }

    .toast__message {
        display: block;
        font-size: 12px;
        color: #888888;
    }

    .success {
        background: ${Colors.Success};
    }

    .info {
        background: ${Colors.Info};
    }

    .error {
        background: ${Colors.Error};
    }

    .warning {
        background: ${Colors.Warning};
    }
`;

const NOTIFICATION_TIMEOUT = 5 * 1000; // 5 Seconds

export const ToastNotification: React.FC<SystemNotification> = ({ id, type, content, sticky }) => {
    const dispatch = useDispatch();
    const dismissNotification = React.useCallback(() => {
        dispatch(NotificationsActions.remove(id));
    }, [dispatch, id]);

    const timeout_duration = type === NotificationTypes.Error ? NOTIFICATION_TIMEOUT * 10 : NOTIFICATION_TIMEOUT;

    const state = React.useMemo(() => {
        switch (type) {
            case NotificationTypes.Success:
                return 'success';
            case NotificationTypes.Info:
                return 'info';
            case NotificationTypes.Error:
                return 'error';
            case NotificationTypes.Warning:
                return 'warning';
        }
    }, [type]);

    const Icon = React.useMemo(() => {
        switch (type) {
            case NotificationTypes.Success:
                return Check;
            case NotificationTypes.Info:
                return Info;
            case NotificationTypes.Error:
                return X;
            case NotificationTypes.Warning:
                return Triangle;
        }
    }, [type]);

    React.useEffect(() => {
        const timeout = setTimeout(() => {
            if (!sticky) {
                dismissNotification();
            }
        }, timeout_duration);

        return () => {
            clearTimeout(timeout);
        };
    }, [dismissNotification, timeout_duration, sticky]);

    const animationProps = React.useMemo(
        () =>
            ({
                initial: { x: 200, opacity: 0 },
                animate: { x: 0, opacity: 1 },
                exit: { opacity: 0 },
            } as MotionProps),
        []
    );

    return (
        <Container {...animationProps}>
            <div className={`toast__indicator ${state}`} />
            <div className="toast__body">
                <div className={`toast__icon ${state}`}>
                    <Icon size={9} strokeWidth={3} />
                </div>
                <div className="toast__content">
                    <span className="toast__header">{capitalize(state)}</span>
                    {content.map((message, i) => (
                        <span className="toast__message" key={i}>
                            {message}
                        </span>
                    ))}
                </div>
                <button className="toast__dismiss-button" onClick={dismissNotification}>
                    <X size={18} strokeWidth={2} />
                </button>
            </div>
        </Container>
    );
};
