import { AuthActionNames } from '..';
import { Service } from '../../models/response';
import { ReduxAction } from '../common/redux-action';
import { ServiceActionNames, ServiceState } from './services.types';

const initialState: ServiceState = {
    services: [],
    serviceBookings: [],
};

export const serviceReducer = (
    state = initialState,
    action: ReduxAction<ServiceActionNames & AuthActionNames.LOG_OUT>
) => {
    switch (action.type) {
        case ServiceActionNames.LIST: {
            return {
                ...state,
                services: action.payload,
            };
        }

        case ServiceActionNames.RETRIEVE: {
            return {
                ...state,
                serviceDetails: action.payload,
            };
        }

        case ServiceActionNames.CLEAR: {
            return {
                ...state,
                serviceDetails: undefined,
            };
        }

        case ServiceActionNames.ADD: {
            const _state = { ...state };
            _state.services.unshift(action.payload);
            console.table(_state.services);
            return _state;
        }

        case ServiceActionNames.UPDATE: {
            const _state = { ...state };
            const serviceIndex = _state.services.findIndex((_) => _.id === (action.payload as Service).id);

            if (serviceIndex < 0) {
                return state;
            }

            _state.services[serviceIndex] = action.payload;

            if (_state.serviceDetails && _state.serviceDetails.id === action.payload.id) {
                _state.serviceDetails = action.payload;
            }

            return _state;
        }

        case ServiceActionNames.GET_BOOKINGS: {
            return {
                ...state,
                serviceBookings: action.payload.bookings,
                serviceBookingsMeta: action.payload.meta,
            };
        }

        case AuthActionNames.LOG_OUT: {
            return { ...initialState };
        }

        default: {
            return state;
        }
    }
};
