import { ReduxAction } from '../common/redux-action';
import { LoadingActionNames } from './loading.types';

export class LoadingActions {
    public static setLoading(id: string, message?: string): ReduxAction<LoadingActionNames.SET_LOADING> {
        return {
            type: LoadingActionNames.SET_LOADING,
            payload: { id, message },
        };
    }

    public static setDone(id: string): ReduxAction<LoadingActionNames.SET_DONE> {
        return {
            type: LoadingActionNames.SET_DONE,
            payload: { id },
        };
    }
}
