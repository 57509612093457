import { Formik } from 'formik';
import { isMatch } from 'lodash';
import React from 'react';
import { Book, Briefcase, Check } from 'react-feather';
import { useDispatch } from 'react-redux';
import { ModalBody, ModalFooter } from 'reactstrap';
import { CustomPrimaryButton } from '../../../../components';
import { FormTextInput } from '../../../../components/form';
import { CustomModal } from '../../../../components/utilities/modal/modal';
import { UpdateGovernmentOfficialModel, UpdateIndividualClientModel } from '../../../../models/request';
import { User } from '../../../../models/response';
import { NotificationsThunk } from '../../../../store/notifications/notifications.thunk';
import {
    ClientTypesMap,
    GovernmentStaffTypes,
    GovernmentStaffTypesMap,
    MeansOfIdList,
    NigerianStates,
    NotificationTypes,
} from '../../../../util/constants';
import { validateFormValues } from '../../../../util/validation';

interface Props {
    isOpen: boolean;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    client: User;
    handleUpdateClient: (userDetails: UpdateGovernmentOfficialModel) => void;
}

const StaffTypeField = () => (
    <div className="col-6">
        <FormTextInput
            className="wide"
            name="GovernmentStaffType"
            placeholder="STAFF TYPE"
            type="text"
            as="select"
            icon={Briefcase}
        >
            <option value="" disabled>
                SELECT OFFICE TYPE
            </option>
            {Object.entries(GovernmentStaffTypesMap).map(([value, type], i) => (
                <option value={value} key={i}>
                    {type}
                </option>
            ))}
        </FormTextInput>
    </div>
);

const ClientTypeField = () => (
    <div className="col-6">
        <FormTextInput
            name="ClientTypeId"
            placeholder="CLIENT TYPE"
            type="text"
            as="select"
            icon={Briefcase}
            className="wide"
        >
            <option value={0} disabled>
                CLIENT TYPE
            </option>
            {Object.entries(ClientTypesMap).map(([value, name], i) => (
                <option value={value} key={i}>
                    {name}
                </option>
            ))}
        </FormTextInput>
    </div>
);

export const UpdateGovernmenttModal: React.FC<Props> = ({ isOpen, setIsOpen, client, handleUpdateClient }) => {
    const dispatch = useDispatch();

    return (
        <CustomModal isOpen={isOpen} setIsOpen={setIsOpen} title="Edit Profile">
            <Formik
                validate={validateFormValues}
                enableReinitialize
                initialValues={new UpdateGovernmentOfficialModel(client)}
                onSubmit={(clientDetails) => {
                    if (isMatch(new UpdateGovernmentOfficialModel(client), clientDetails)) {
                        dispatch(NotificationsThunk.create(NotificationTypes.Info, 'No changes were made'));
                        return setIsOpen(false);
                    }

                    handleUpdateClient(clientDetails);
                }}
            >
                {({ handleSubmit }) => (
                    <form onSubmit={handleSubmit}>
                        <ModalBody>
                            <div className="form-row">
                                <div className="col-6">
                                    <FormTextInput
                                        name="Name_Of_Government_Officials_Office"
                                        label="Government Office Name"
                                        type="text"
                                        disabled
                                    />
                                </div>
                                <div className="col-6">
                                    <FormTextInput
                                        name="Registered_Address"
                                        label="Office Registered Address"
                                        type="text"
                                    />
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="col-6">
                                    <FormTextInput disabled name="Email" label="Email Address" type="email" />
                                </div>
                                <div className="col-6">
                                    <FormTextInput name="PhoneNumber" label="Phone Number" type="text" />
                                </div>
                            </div>

                            {client.governmentStaffType === GovernmentStaffTypes.State ? (
                                <React.Fragment>
                                    <div className="form-row mb-2">
                                        <StaffTypeField />
                                        <div className="col-6">
                                            <FormTextInput
                                                className="wide"
                                                name="State"
                                                placeholder="STATE"
                                                type="text"
                                                as="select"
                                                icon={Briefcase}
                                            >
                                                <option value="">SELECT STATE</option>
                                                {NigerianStates.map((state, i) => (
                                                    <option value={state} key={i}>
                                                        {state}
                                                    </option>
                                                ))}
                                            </FormTextInput>
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <ClientTypeField />
                                    </div>
                                </React.Fragment>
                            ) : (
                                <div className="form-row">
                                    <StaffTypeField />
                                </div>
                            )}
                        </ModalBody>
                        <ModalFooter className="d-flex justify-content-between align-items-center">
                            <CustomPrimaryButton type="submit">
                                <Check size={14} strokeWidth={3} />
                                <span>Edit Client</span>
                            </CustomPrimaryButton>
                            <button
                                className="modal-footer__button"
                                type="button"
                                onClick={setIsOpen.bind(undefined, false)}
                            >
                                <span>Close</span>
                            </button>
                        </ModalFooter>
                    </form>
                )}
            </Formik>
        </CustomModal>
    );
};
