import { AnimatePresence } from 'framer-motion';
import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { ApplicationState } from '../../../store';
import { ToastNotification } from './toast-notification';

const Container = styled.div`
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 1081;
    width: 380px;
    padding: 20px;

    @media screen and (max-width: 560px) {
        width: 100%;
    }

    > * + * {
        margin-top: 20px;
    }
`;

export const ToastNotificationArea: React.FC = () => {
    const { notifications } = useSelector((state: ApplicationState) => state.notifications);

    return (
        <Container>
            <AnimatePresence>
                {notifications.map((notification) => (
                    <ToastNotification key={notification.id} {...notification} />
                ))}
            </AnimatePresence>
        </Container>
    );
};
