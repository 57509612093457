import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { ReduxAction } from '../common/redux-action';
import { AuthActionNames, AuthState } from './auth.types';

const AUTH_REDUCER_KEY = 'AUTH_REDUCER';

const initialState: AuthState = {
    authUser: undefined,
};

const reducer = (state = initialState, action: ReduxAction<AuthActionNames>) => {
    switch (action.type) {
        case AuthActionNames.LOG_IN: {
            return { ...action.payload };
        }

        case AuthActionNames.LOG_OUT: {
            return { ...initialState };
        }

        default: {
            return state;
        }
    }
};

export const authReducer = persistReducer({ key: AUTH_REDUCER_KEY, storage }, reducer);
