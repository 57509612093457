import React from 'react';
import { LogOut } from 'react-feather';
import { NavLink } from 'react-router-dom';
import { AuthContext } from '../../../context';
import { useSidebar } from '../sidebar-hook';
import { SidebarMenuStyleWrapper } from './side-bar.style';

type State = {
    activeMenu?: any;
};

export const SidebarMenu: React.FC<State> = (props) => {
    const { user } = React.useContext(AuthContext.Context);
    const { links, handleLogout } = useSidebar();

    return (
        <SidebarMenuStyleWrapper>
            <img alt="logo" src={require('../../../assets/images/logo-lg.png')} width={84} />
            <div className="navWrapper">
                {links
                    .filter((_) => !_.inactive)
                    .map(({ name, link, icon: Icon, inactive }, i) => {
                        return (
                            <NavLink
                                to={link}
                                className="nav-item"
                                onClick={(e: React.SyntheticEvent) => {
                                    if (inactive) {
                                        e.preventDefault();
                                    }
                                }}
                                key={i}
                            >
                                <div className="nav-item__icon-container">
                                    <Icon className="nav-item__icon" />
                                </div>
                                <div className="nav-item__name">
                                    <span>{name}</span>
                                </div>
                            </NavLink>
                        );
                    })}
            </div>
            <div className="profile-box d-flex">
                <div className="p-2 flex-shrink-1">
                    <img className="profile-box__img" alt="user" src={require('../../../assets/images/personal.png')} />
                </div>
                <div className="d-flex align-items-center p-1 w-100 contentBox">
                    <span>{user && (user.lastName || user.business_Name)}</span>
                </div>
                <div className="p-2 flex-shrink-1">
                    <div className="box" onClick={handleLogout}>
                        <div className="divCircle">
                            <LogOut size={12} />
                        </div>
                    </div>
                </div>
            </div>
        </SidebarMenuStyleWrapper>
    );
};
