import { merge } from 'lodash';
import { Booking } from '../../models/response';
import { AuthActionNames } from '../auth';
import { ReduxAction } from '../common/redux-action';
import { BookingActionNames, BookingsState } from './bookings.types';

const initialState: BookingsState = {
    bookings: [],
    meta: undefined,
};

export const bookingsReducer = (
    state = initialState,
    action: ReduxAction<BookingActionNames & AuthActionNames.LOG_OUT>
): BookingsState => {
    switch (action.type) {
        case BookingActionNames.GET_ALL: {
            return {
                ...state,
                bookings: action.payload.data,
                meta: action.payload.meta,
            };
        }

        case BookingActionNames.GET_ONE: {
            return {
                ...state,
                bookingDetails: action.payload as Booking,
            };
        }

        case BookingActionNames.CLEAR: {
            return {
                ...state,
                bookingDetails: undefined,
            };
        }

        case BookingActionNames.ADD: {
            const _state = { ...state };
            _state.bookings.unshift(action.payload);
            return _state;
        }

        case BookingActionNames.UPDATE: {
            const _state = { ...state };
            const bookingIndex = _state.bookings.findIndex((_) => _.id === (action.payload as Booking).id);

            if (_state.bookingDetails && _state.bookingDetails?.id === action.payload.id) {
                merge({}, _state.bookingDetails, action.payload);
            }

            if (bookingIndex < 0) {
                return state;
            }

            merge({}, _state.bookings[bookingIndex], action.payload);
            _state.bookings[bookingIndex].bookingStatus = action.payload.bookingStatus;
            return _state;
        }

        case AuthActionNames.LOG_OUT: {
            return { ...initialState };
        }

        default: {
            return state;
        }
    }
};
