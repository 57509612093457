import { capitalize, orderBy } from 'lodash';
import moment from 'moment';
import React from 'react';
import { MoreVertical } from 'react-feather';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Cell } from 'react-table';
import { CustomDropdown, CustomDropdownItem, CustomPrimaryButton, DataTable } from '../../../components';
import { UpdateBookingModel } from '../../../models/request';
import { Booking, Transaction } from '../../../models/response';
import { BookingsThunk } from '../../../store/bookings/bookings.thunk';
import { ApiResponse } from '../../../util/api-client';
import { BookingStatuses } from '../../../util/constants';
import { formatCurrency } from '../../../util/misc';

interface BookingsTableProps {
    transactions: Transaction[];
    compact?: boolean;
    refreshFunction?: (...args: any[]) => any;
    meta?: ApiResponse<Booking[]>['meta'];
}

export const BookingsTransactionsTable: React.FC<BookingsTableProps> = ({ transactions, meta, refreshFunction }) => {
    const dispatch = useDispatch();

    const handleUpdateBooking = React.useCallback(
        (bookingDetails: UpdateBookingModel) => {
            dispatch(BookingsThunk.update(bookingDetails, refreshFunction));
        },
        [dispatch, refreshFunction]
    );

    const handleSetStatus = React.useCallback(
        (booking: Booking, status: BookingStatuses) => {
            if (booking.bookingStatus !== status) {
                booking.bookingStatus = status;
                handleUpdateBooking(new UpdateBookingModel(booking));
            }
        },
        [handleUpdateBooking]
    );

    return (
        <DataTable
            columns={[
                {
                    Header: 'Date',
                    accessor: 'dateCreated',
                    Cell: ({ row: { original } }: Cell<Transaction>) => {
                        return <em>{moment(original.dateCreated).format('Do MMMM, YYYY')}</em>;
                    },
                },
                {
                    Header: 'Service',
                    accessor: 'service.name' as any,
                    Cell: ({ row: { original } }: Cell<Transaction>) => {
                        return <em>{original.transactType}</em>;
                    },
                },
                {
                    Header: 'Booking Price',
                    Cell: (cell: Cell<Transaction>) => <span>{formatCurrency(cell.row.original.amount)}</span>,
                },
                {
                    Header: 'Passengers',
                    accessor: 'quantity' as any,
                    Cell: (cell: Cell<Transaction>) => <span>{cell.row.original.quantity}</span>,
                },
                {
                    Header: 'Status',
                    accessor: 'status',
                    Cell: (cell: Cell<Transaction>) => <span>{cell.row.original.status ?? 'Unpaid'}</span>,
                },
                {
                    Header: 'Invoice Number',
                    accessor: 'invoiceNo',
                    Cell: ({ row: { original } }: Cell<Transaction>) => {
                        return <span>{original.invoiceNo ?? 'N/A'}</span>;
                    },
                },
                {
                    Header: 'RRR Number',
                    accessor: 'rrrNo' as any,
                    Cell: ({ row: { original } }: Cell<Transaction>) => <span>{original.rrrNo ?? 'N/A'}</span>,
                },
                {
                    Header: 'Actions',
                    Cell: ({ row: { original: booking } }: Cell<Transaction>) => {
                        return (
                            <React.Fragment>
                                <CustomDropdown
                                    className="mr-2"
                                    triggerComponent={() => {
                                        return (
                                            <CustomPrimaryButton className="small">
                                                <MoreVertical size={12} />
                                            </CustomPrimaryButton>
                                        );
                                    }}
                                >
                                    <CustomDropdownItem>
                                        <Link to={`/app/booking/${booking.transactionId}`}>View Booking</Link>
                                    </CustomDropdownItem>
                                </CustomDropdown>
                            </React.Fragment>
                        );
                    },
                },
            ]}
            data={orderBy(transactions, (_) => new Date(_.dateCreated), 'desc')}
            meta={meta}
        />
    );
};
