import { IsBoolean, IsPositive } from 'class-validator';
import { Service } from '../../response';
import { BaseModel } from '../base-model';
import { CreateServiceModel } from './create-service.model';

export class UpdateServiceModel extends CreateServiceModel implements BaseModel {
    @IsPositive({ message: 'Invalid id' })
    public Id: number = 0;

    @IsBoolean({ message: 'Must be active or inactive' })
    public Status: boolean = true;

    constructor(service: Service) {
        super();

        if (service) {
            this.Id = service.id;
            this.Name = service.name;
            this.Description = service.description;
            this.Price = service.price;
            this.Status = service.status;
            this.ServiceTypeId = service.serviceTypeId;
            return;
        }
    }
}
