import React from 'react';
import { useSelector } from 'react-redux';
import { AuthenticatedUser } from '../models/response';
import { ApplicationState } from '../store';
import { ClientTypes } from '../util/constants';

type AccessTypes = 'view' | 'manage';

interface AuthContextState {
    user?: AuthenticatedUser;
    canAccess: (strict?: boolean) => boolean | undefined;
    isClientOfType: (clientType: ClientTypes) => boolean;
}

const initialState: AuthContextState = {
    canAccess: () => false,
    isClientOfType: () => false,
};

const Context = React.createContext<AuthContextState>(initialState);

const Provider: React.FC = ({ children }) => {
    const { authUser: user } = useSelector((state: ApplicationState) => state.auth);

    const canAccess = React.useCallback(
        (strict?: boolean) => {
            if (!user) {
                return false;
            }

            if (strict) {
                // In case you need an exact match
                return user?.role === 'Client';
            }

            return user.role === 'Client';
        },
        [user]
    );

    const isClientOfType = React.useCallback(
        (clientType: ClientTypes) => {
            return (
                (canAccess() && user?.clientTypeId === ClientTypes.International) || user?.clientTypeId === clientType
            );
        },
        [user, canAccess]
    );

    const contextState = React.useMemo(() => ({ user, canAccess, isClientOfType }), [user, canAccess, isClientOfType]);
    return <Context.Provider value={contextState}>{children}</Context.Provider>;
};

export const AuthContext = {
    Context,
    Provider,
    Consumer: Context.Consumer,
};
