import { Booking, Service } from '../../models/response';
import {
    AddServiceAction,
    ClearAction,
    GetAllAction,
    GetOneAction,
    ServiceActionNames,
    ServiceState,
    UpdateServiceAction,
} from './services.types';

export class ServiceActions {
    public static allServices(services: any): GetAllAction {
        return {
            type: ServiceActionNames.LIST,
            payload: services,
        };
    }

    public static viewService(service: any): GetOneAction {
        return {
            type: ServiceActionNames.RETRIEVE,
            payload: service,
        };
    }

    public static clearService(): ClearAction {
        return {
            type: ServiceActionNames.CLEAR,
        };
    }

    public static addService(service: Service): AddServiceAction {
        return {
            type: ServiceActionNames.ADD,
            payload: service,
        };
    }

    public static updateService(service: Service): UpdateServiceAction {
        return {
            type: ServiceActionNames.UPDATE,
            payload: service,
        };
    }

    public static getServiceBookings(bookings: Booking[], meta: ServiceState['serviceBookingsMeta']) {
        return {
            type: ServiceActionNames.GET_BOOKINGS,
            payload: { bookings, meta },
        };
    }
}
