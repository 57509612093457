import { validateSync } from 'class-validator';
import { BaseModel } from '../../models/request/base-model';
import { mapErrorsToForm } from './map-errors-to-form';

export function validateFormValues<T extends BaseModel = any>(values: T) {
    if ('beforeValidation' in values) {
        (values as any).beforeValidation();
    }

    const validationErrors = validateSync(values, {
        whitelist: true,
    });

    const mappedErrors = mapErrorsToForm(validationErrors);
    return mappedErrors;
}
