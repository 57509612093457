import styled from 'styled-components';

export const LayoutStyleWrapper = styled.div`
    height: 92vh;

    @media only screen and (min-width: 970px) {
        .layoutWrapper {
            display: block;
            width: calc(100% - 300px);
            flex: 1;
            height: 100%;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 300px;
            background: #fff;

            .mainWrapper {
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                width: 100%;
                min-height: 100vh;
                background: #ccc;
                position: relative;
                align-items: stretch;
                background: #fff;
            }

            .bodyContent {
                padding: 3rem 2rem 2rem 4rem;
                flex: 1 0 70%;
                width: 70%;
                max-width: 100%;
                height: 100%;
                min-height: 100vh;
                background: #fff;
            }

            .bodyContentSwitch {
                padding: 3rem 2rem 2rem 4rem;
                flex: 1 0 70%;
                width: 100%;
                max-width: 100%;
                height: 100%;
                min-height: 100vh;
                background: #fff;
            }
        }

        .rightSidebar {
            background: #fefdfb 0% 0% no-repeat padding-box;
            border: 1px solid #dddddd;
            color: #000;
            padding: 3rem 2rem 2rem 2rem;
            line-height: 2rem;
            flex: 1 0 30%;
            width: 30%;
            max-width: 30%;
            min-height: 100vh;

            h4 {
                font-weight: bold;
                font-size: 18px;
            }

            .quickDiv {
                background-color: #ffffff;
                border: 3px solid #f4f4f4;
                border-radius: 14px;
                padding: 0.75em 1em;
                margin-top: 1em;

                & > * + * {
                    margin-top: 0.65em;
                }

                .header {
                    color: #a1a1a1;
                    font-size: 13px;
                    font-size: 400;
                }

                p {
                    font-size: 16px;
                    font-weight: 600;
                    line-height: 1.5rem;
                }
            }

            .contactDiv {
                margin-top: 2rem;

                p {
                    font-size: 13px;
                    font-weight: bold;
                }

                .btn-btn {
                    i {
                        font-size: 11px;
                    }

                    span {
                        font-size: 12px;
                        letter-spacing: 1.2px;
                    }
                }
            }
        }
    }

    @media only screen and (max-width: 970px) {
        display: flex;
        flex-direction: column;

        .layoutWrapper {
            position: relative;
            height: 100%;

            .bodyContent,
            .bodyContentSwitch {
                background: #fff;
                padding: 2rem 2rem 175px;
            }
        }

        .rightSidebar {
            display: none;
        }
    }
`;
