import { title } from 'process';
import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { ModalButton } from '../../../../../components/utilities/modal/modal-button';

interface TermsAndConditionsModalProps {
    isOpen: boolean;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    title?: string;
    children?: React.ReactNode;
    footer?: React.ReactNode;
    size?: 'sm' | 'lg' | 'xl';
    centered?: boolean;
    className?: string;
    style?: React.CSSProperties;
}

export const TermsAndConditionsModal: React.FC<TermsAndConditionsModalProps> = ({
    isOpen,
    setIsOpen,
    title = 'Terms and Conditions',
    children,
    footer,
    size = 'lg',
    centered = true,
    className = '',
    style = { maxWidth: '1000px', width: '90%' },
}) => {
    const close = () => setIsOpen(false);

    return (
        <>
            <Modal
                isOpen={isOpen}
                toggle={close}
                size={size}
                centered={centered}
                className={`modal-right ${className}`}
                style={style}
            >
                <div style={{ display: 'flex', padding: '20px 25px', justifyContent: 'space-between' }}>
                    <span style={{ fontWeight: 'bold', fontSize: '18px' }}>{title}</span>
                    <ModalButton onClick={close} className="border-0" />
                </div>

                <ModalBody style={{ padding: '20px', color: '#000000' }}>{children}</ModalBody>
                {footer && <ModalFooter>{footer}</ModalFooter>}
            </Modal>
        </>
    );
};
