import * as React from 'react';
import { Link } from 'react-router-dom';
import { Collapse, Container, Nav, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';

import { CustomPrimaryButton } from '../index';
import './NavMenu.scss';

export default class LandingPageNav extends React.PureComponent<{}, { isOpen: boolean }> {
    public state = {
        isOpen: false,
    };

    public render() {
        return (
            <header>
                <Navbar
                    expand="lg"
                    className="navbar-expand-sm navbar-toggleable-sm  border-0 bg-white"
                    light
                    fixed="top"
                >
                    <Container fluid={true} style={{ marginRight: '5%', marginLeft: '5%' }}>
                        <NavbarBrand className="mr-lg-5 landing-nav" to="/" tag={Link}>
                            <img alt="..." src={require('../../assets/images/logo-new.png')} />
                        </NavbarBrand>
                        <NavbarToggler onClick={this.toggle} className="mr-2" />
                        <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={this.state.isOpen} navbar>
                            <Nav className="align-items-lg-center ml-lg-auto" navbar>
                                <React.Fragment>
                                    <NavItem>
                                        <NavLink href="#services">
                                            <strong> Our Services</strong>
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink href="#FAQ">
                                            <strong>FAQs</strong>
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink href="#partners">
                                            <strong>Partners</strong>
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink href="#contact">
                                            <strong> Contact</strong>
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink tag={Link} to="/app/dashboard">
                                            <div className="btnWrapper py-5">
                                                <CustomPrimaryButton
                                                    className="btn bg-green border-pill text-white shadow rounded-pill px-3 border-0"
                                                    as={Link}
                                                    to="/register"
                                                >
                                                    Register
                                                </CustomPrimaryButton>
                                            </div>
                                        </NavLink>
                                    </NavItem>
                                </React.Fragment>
                            </Nav>
                        </Collapse>
                    </Container>
                </Navbar>
            </header>
        );
    }

    private toggle = () => {
        this.setState({
            isOpen: !this.state.isOpen,
        });
    };
}
