import styled from 'styled-components';

export const TopBarStyleWrapper = styled.div`
    .topbarWrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 3rem;

        .breadcrumbBox {
            flex: 1;
            display: flex;
            justify-content: flex-end;
            margin-right: 50px;
            height: 36px;
        }
    }
`;
