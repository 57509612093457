import { useField } from 'formik';
import React from 'react';
import styled from 'styled-components';

const FormLargeRadioButtonContainer = styled.label`
    background: white;
    border: 1px solid #dadada;
    border-radius: 0.5rem;
    min-width: max-content;
    max-width: 400px;
    padding: 1rem;
    display: flex;
    position: relative;
    align-items: center;

    .form-large-radio-button__indicator-container {
        flex-shrink: 0;
        display: block;
        background: white;
        border: 1px solid #dadada;
        width: 27px;
        height: 27px;
        border-radius: 100%;
        padding: 6px;
    }

    .form-large-radio-button__indicator {
        display: block;
        height: 100%;
        width: 100%;
        background: var(--primary-color);
        border-radius: 100%;
    }

    .form-large-radio-button__input {
        opacity: 0;
        position: absolute;
    }

    .form-large-radio-button__label {
        font-size: 18px;
        letter-spacing: 0px;
        color: black;
        text-transform: uppercase;
    }
`;

interface FormLargeRadioButtonProps extends React.ComponentPropsWithoutRef<'label'> {
    name: string;
    label: string;
    value: any;
}

export const FormLargeRadioButton: React.FC<FormLargeRadioButtonProps> = ({ name, value, label, ...props }) => {
    const [field] = useField({ name, value });

    const selected = React.useMemo(() => field.value === value || field.value === `${value}`, [field.value, value]);

    return (
        <FormLargeRadioButtonContainer {...props}>
            <section className="form-large-radio-button__indicator-container">
                {selected && <span className="form-large-radio-button__indicator" />}
            </section>
            <input
                className="form-large-radio-button__input"
                type="radio"
                checked={selected}
                {...field}
                value={value}
            />
            <span className="ml-3 form-large-radio-button__label">{label}</span>
        </FormLargeRadioButtonContainer>
    );
};
