import React from 'react';
import { ChevronRight } from 'react-feather';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const Container = styled.div`
    display: inline-flex;
    background: #fff 0% 0% no-repeat padding-box;
    border: 1px solid #dadada;
    border-radius: 0.5rem;
    align-items: center;
    padding: 12px 20px;

    a.aps-breadcrumb {
        color: black;
    }

    .aps-breadcrumb {
        font-size: 14px;
        color: #dadada;
    }

    .aps-breadcrumb + .aps-breadcrumb {
        margin-left: 8px;
    }

    @media screen and (max-width: 970px) {
        position: absolute;
        opacity: 0;
    }
`;

export interface Breadcrumb {
    name: string;
    path: string;
}

export const Breadcrumbs: React.FC<{ breadcrumbs: Breadcrumb[] }> = ({ breadcrumbs }) => {
    const previousPages = breadcrumbs.slice(0, -1);
    const currentPage = breadcrumbs.slice(-1)[0];

    return breadcrumbs.length > 0 ? (
        <Container>
            {previousPages.map(({ name, path }) => {
                return (
                    <React.Fragment key={name}>
                        <Link className="aps-breadcrumb" to={path}>
                            {name}
                        </Link>
                        <ChevronRight className="aps-breadcrumb" size={14} />
                    </React.Fragment>
                );
            })}
            <span className="aps-breadcrumb">{currentPage.name}</span>
        </Container>
    ) : null;
};
