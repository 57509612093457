import React from 'react';
import { ChevronLeft, Send } from 'react-feather';
import { Link } from 'react-router-dom';
import { Nav, NavItem, NavLink } from 'reactstrap';
import { history, isPreviousLocationWithinApp } from '../../util/history';
import { generateClassNames } from '../../util/misc/generate-class-names';
import { AppLayoutStyle, Header } from './app-layout-style';
import { CustomPrimaryButton } from '../utilities/custom-primary-button/custom-primary-button';
import saveAs from 'file-saver';
import { NotificationsThunk } from '../../store';
import { ApiClient } from '../../util/api-client';
import { NotificationTypes } from '../../util/constants';
import { useDispatch } from 'react-redux';

export interface TabLink {
    index: number;
    name: string;
    query?: [] | [string, any];
}

const Wrapper: React.FC = ({ children }) => {
    return <AppLayoutStyle>{children}</AppLayoutStyle>;
};

const Body: React.FC<{ className?: string }> = ({ children, className }) => {
    return <div className={generateClassNames('content-body', className)}>{children}</div>;
};

const TabHeader: React.FC<{
    links?: TabLink[];
    activeTab?: number;
    setActiveTab?: React.Dispatch<React.SetStateAction<number>>;
}> = ({ activeTab, setActiveTab, links, children }) => {
    const dispatch = useDispatch();

    const exportBookingFile = async () => {
        const config: RequestInit = {
            ...ApiClient.getFetchConfig(),
        };

        try {
            dispatch(NotificationsThunk.create(NotificationTypes.Info, `Downloading document`));

            const response = await fetch(
                `${process.env.REACT_APP_BASE_URL}/transactions/export-booking-transactions`,
                config
            );

            try {
                const downloadedFile = await response.blob();
                const documentName = 'Booking transactions';
                saveAs(downloadedFile, documentName + '.csv');
            } catch (e) {
                dispatch(NotificationsThunk.create(NotificationTypes.Error, 'Error saving file'));
            }
        } catch (e) {
            dispatch(NotificationsThunk.create(NotificationTypes.Error, 'Error downloading file'));
        }
    };

    const exportFundsFile = async () => {
        const config: RequestInit = {
            ...ApiClient.getFetchConfig(),
        };

        try {
            dispatch(NotificationsThunk.create(NotificationTypes.Info, `Downloading document`));

            const response = await fetch(
                `${process.env.REACT_APP_BASE_URL}/transactions/export-funds-transactions`,
                config
            );

            try {
                const downloadedFile = await response.blob();
                const documentName = 'Fund transactions';
                saveAs(downloadedFile, documentName + '.csv');
            } catch (e) {
                dispatch(NotificationsThunk.create(NotificationTypes.Error, 'Error saving file'));
            }
        } catch (e) {
            dispatch(NotificationsThunk.create(NotificationTypes.Error, 'Error downloading file'));
        }
    };

    const exportRegFile = async () => {
        const config: RequestInit = {
            ...ApiClient.getFetchConfig(),
        };

        try {
            dispatch(NotificationsThunk.create(NotificationTypes.Info, `Downloading document`));

            const response = await fetch(
                `${process.env.REACT_APP_BASE_URL}/transactions/export-registration-transactions`,
                config
            );

            try {
                const downloadedFile = await response.blob();
                const documentName = 'Registrations transactions';
                saveAs(downloadedFile, documentName + '.csv');
            } catch (e) {
                dispatch(NotificationsThunk.create(NotificationTypes.Error, 'Error saving file'));
            }
        } catch (e) {
            dispatch(NotificationsThunk.create(NotificationTypes.Error, 'Error downloading file'));
        }
    };

    const handleExport = () => {
        switch (activeTab) {
            case 1:
                exportBookingFile();
                break;
            case 2:
                exportFundsFile();
                break;
            case 3:
                exportRegFile();
                break;
            default:
                break;
        }
    };

    return (
        <div className="tab-header bold" style={{ fontWeight: 600 }}>
            {links && activeTab !== undefined && setActiveTab && (
                <Nav className="tab-nav" tabs>
                    {links.map(({ index, name, query }) => (
                        <NavItem className="tab-nav__item" key={index}>
                            <NavLink
                                className="tab-nav__link"
                                active={index === activeTab}
                                onClick={() => {
                                    setActiveTab(index);

                                    if (query) {
                                        history.push(query.length ? `?${query[0]}=${query[1]}` : '?');
                                    }
                                }}
                            >
                                {name}
                            </NavLink>
                        </NavItem>
                    ))}

                    <CustomPrimaryButton
                        style={{
                            borderRadius: '10px',
                            alignSelf: 'center',
                            padding: '1.2rem .8rem',
                            marginTop: '5px',
                        }}
                        onClick={handleExport}
                        className="inverted small"
                    >
                        <Send size={10} strokeWidth={3} />
                        <span>{'Export'}</span>
                    </CustomPrimaryButton>
                </Nav>
            )}
            {children}
        </div>
    );
};

const TabBody: React.FC<React.ComponentPropsWithoutRef<'div'>> = ({ className, ...props }) => {
    return <div className={generateClassNames('tab-body', className)} {...props}></div>;
};

const HeaderBackButton: React.FC<{
    showIf?: () => boolean;
    action?: () => void;
}> = ({ showIf, action }) => {
    return showIf?.() || isPreviousLocationWithinApp() ? (
        <Link
            className="header__back-button"
            to={'#'}
            onClick={(e) => {
                e.preventDefault();

                if (action && showIf?.()) {
                    action();
                    return;
                }

                history.goBack();
            }}
        >
            <ChevronLeft size={20} strokeWidth={3} />
        </Link>
    ) : null;
};

export const AppLayout = {
    Wrapper,
    Header,
    HeaderBackButton,
    Body,
    TabHeader,
    TabBody,
};
