import { Booking, Service } from '../../models/response';
import { ApiResponse } from '../../util/api-client';
import { ReduxAction } from '../common/redux-action';

export interface ServiceState {
    services: Service[];
    serviceDetails?: Service;
    serviceBookings: Booking[];
    serviceBookingsMeta?: ApiResponse<Booking[]>['meta'];
}

export enum ServiceActionNames {
    LIST = 'SERVICE_LIST',
    ADD = 'SERVICE_ADD',
    RETRIEVE = 'SERVICE_RETRIEVE',
    CLEAR = 'SERVICE_CLEAR',
    UPDATE = 'SERVICE_UPDATE',
    GET_BOOKINGS = 'SERVICE_GET_BOOKINGS',
}

export interface GetAllAction extends ReduxAction<typeof ServiceActionNames.LIST, Service[]> {}

export interface GetOneAction extends ReduxAction<typeof ServiceActionNames.RETRIEVE, Service> {}

export interface ClearAction extends ReduxAction<typeof ServiceActionNames.CLEAR> {}

export interface AddServiceAction extends ReduxAction<typeof ServiceActionNames.ADD, Service> {}

export interface UpdateServiceAction extends ReduxAction<typeof ServiceActionNames.UPDATE, Service> {}
