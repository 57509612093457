export enum Genders {
    Male = 'Male',
    Female = 'Female',
}

export const GendersMap = {
    [Genders.Male]: 'Male',
    [Genders.Female]: 'Female',
};

export enum Student {
    Yes = 'Yes',
    No = 'No',
}
