import { Booking } from '../../models/response';
import { ApiResponse } from '../../util/api-client';
import { ReduxAction } from '../common/redux-action';

export interface BookingsState {
    bookings: Booking[];
    meta: ApiResponse<Booking[]>['meta'];
    bookingDetails?: Booking;
}

export enum BookingActionNames {
    GET_ALL = 'BOOKING_GET_ALL',
    GET_ONE = 'BOOKING_GET_ONE',
    CLEAR = 'BOOKING_CLEAR',
    ADD = 'BOOKING_ADD',
    UPDATE = 'BOOKING_UPDATE',
}

export interface GetAllBookingsAction extends ReduxAction<typeof BookingActionNames.GET_ALL, ApiResponse<Booking[]>> {}

export interface GetOneBookingAction extends ReduxAction<typeof BookingActionNames.GET_ONE, Booking> {}

export interface ClearBookingAction extends ReduxAction<typeof BookingActionNames.CLEAR> {}

export interface AddBookingAction extends ReduxAction<typeof BookingActionNames.ADD, Booking> {}

export interface UpdateBookingAction extends ReduxAction<typeof BookingActionNames.UPDATE, Booking> {}
