import { Formik } from 'formik';
import React from 'react';
import { Mail } from 'react-feather';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { EntryFormContainer } from '../../../components';
import { Breadcrumb, Breadcrumbs } from '../../../components/breadcrumbs/breadcrumbs';
import { FormButton, FormTextInput } from '../../../components/form';
import Layout, { StickyBackground } from '../../../components/layout';
import { ForgetPasswordModel } from '../../../models/request';
import { AuthThunk } from '../../../store/auth/auth.thunk';
import { validateFormValues } from '../../../util/validation';

export const ForgetPasswordPage: React.FC = () => {
    const breadcrumbs: Breadcrumb[] = [
        { name: 'Home', path: '/' },
        { name: 'Forget password', path: window.location.href },
    ];

    const dispatch = useDispatch();
    const handleFogetPassword = React.useCallback(
        (data: ForgetPasswordModel) => {
            dispatch(AuthThunk.requestPasswordReset(data));
        },
        [dispatch]
    );

    return (
        <Layout>
            <StickyBackground />
            <div className="px-5 py-3">
                <Breadcrumbs breadcrumbs={breadcrumbs} />
                <EntryFormContainer>
                    <Formik
                        validate={validateFormValues}
                        initialValues={new ForgetPasswordModel()}
                        onSubmit={handleFogetPassword}
                    >
                        {({ handleSubmit }) => (
                            <form onSubmit={handleSubmit} className="d-flex flex-column align-items-center">
                                <header className="form__header mb-5">
                                    <h1>Forgot password</h1>
                                    <span>Enter email address to reset your password</span>
                                </header>
                                <main className="d-flex flex-column align-items-center mb-5">
                                    <FormTextInput
                                        className="mb-1 wide"
                                        name="Email"
                                        placeholder="EMAIL ADDRESS"
                                        type="email"
                                        icon={Mail}
                                    />
                                </main>
                                <footer className="d-flex flex-column align-items-center">
                                    <FormButton className="mb-4" type="submit">
                                        <span>Reset Password</span>
                                    </FormButton>
                                    {/* <span>
                                        Don't have an account?{' '}
                                        <Link to="/register">
                                            <u>Sign up</u>
                                        </Link>
                                    </span> */}
                                </footer>
                            </form>
                        )}
                    </Formik>
                </EntryFormContainer>
            </div>
        </Layout>
    );
};
