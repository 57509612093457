import { IsNotEmpty, IsNumber, Min } from 'class-validator';
import { formatCurrency } from '../../../util/misc';
import { BaseModel } from '../base-model';

export class FundWalletModel implements BaseModel {
    @IsNumber()
    @Min(50000, { message: 'Amount cannot be lower than NGN 50000' })
    @IsNotEmpty({ message: 'Please enter an amount' })
    public amount = '';

    public beforeValidation() {
        this.amount = this.amount;
    }
}
