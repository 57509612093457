import { Dispatch } from 'redux';
import { CustomerSurveyModel } from '../../models/request';
import { FeedbackService } from '../../services/feedback.service';
import { NotificationTypes } from '../../util/constants';
import { history } from '../../util/history';
import { generateLoader } from '../../util/misc';
import { NotificationsThunk } from '../notifications/notifications.thunk';
import { FeedbackFormValues } from '../../components/client-feedback/validate-feedback';
import { ClientFeedbackService } from '../../services/clientFeedback.services';

export class FeedbackThunk {
    public static submit(survey: CustomerSurveyModel) {
        return async (dispatch: Dispatch) => {
            try {
                const response = await generateLoader(
                    FeedbackService.sumbitSurvey(survey),
                    dispatch,
                    'Submitting survey'
                );

                if (response.data) {
                    NotificationsThunk.create(NotificationTypes.Success, 'Survey submitted successfully')(dispatch);

                    return history.replace('/');
                }

                throw new Error('Survey submission failed');
            } catch (error) {
                // TODO: dispatch error to errorReducer
                // to be implemented
            }
        };
    }

    public static clientFeedback(feedback: FeedbackFormValues) {
        return async (dispatch: Dispatch) => {
            try {
                const response = await generateLoader(
                    ClientFeedbackService.feedback(feedback),
                    dispatch,
                    'Submitting feedback'
                );

                if (response.data) {
                    NotificationsThunk.create(NotificationTypes.Success, 'Feedback submitted successfully')(dispatch);
                    localStorage.removeItem('showFeedbackForm');
                    console.log('resss-', response.data);
                }

                throw new Error('Feedback submission failed');
            } catch (error) {
                // TODO: dispatch error to errorReducer
                // to be implemented
            }
        };
    }
}
