import { ForgetPasswordModel, ResetPasswordModel, UserLoginModel } from '../models/request';
import { AuthenticatedUser } from '../models/response';
import { ApiClient } from '../util/api-client';

export class AuthService {
    public static async logIn(login: UserLoginModel) {
        return ApiClient.post<UserLoginModel, AuthenticatedUser>('users/auth', login);
    }

    public static async requestPasswordReset(data: ForgetPasswordModel) {
        return ApiClient.post<ForgetPasswordModel, AuthenticatedUser>('users/forgot-password', data);
    }

    public static async requestResetPassword(data: ResetPasswordModel) {
        return ApiClient.patch<ResetPasswordModel, AuthenticatedUser>('users/password-reset', data);
    }

    public static async verifyEmail(code: string) {
        return ApiClient.get('users/email-verification/' + code);
    }
}
