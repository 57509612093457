import React from 'react';
import { Button } from 'reactstrap';
import './CustomButton.scss';

type Props = {
    title: string;
    type: string;
    disable: boolean;
};

class CustomButton extends React.Component<Props> {
    public render() {
        const { title, type, disable } = this.props;

        return (
            <React.Fragment>
                <Button type="button" disabled={disable} color={type} className="custom-button">
                    {title}
                </Button>
            </React.Fragment>
        );
    }
}

export default CustomButton;
