import * as React from 'react';
import styled from 'styled-components';
import backgroundImage from '../assets/images/patternmm.svg';
import NavMenu from './nav-menu/nav-menu';

export const StickyBackground = styled.div`
    height: 100vh;
    width: 100vw;
    background: white;
    background-position-x: center;
    background-position-y: bottom;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url(${backgroundImage});
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
`;

export default (props: { children?: React.ReactNode }) => (
    <React.Fragment>
        <NavMenu />
        <main>{props.children}</main>
    </React.Fragment>
);
