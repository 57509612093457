import { Font } from '@react-pdf/renderer';
import * as React from 'react';
import { Route, Switch } from 'react-router';
import { PageLoader, ToastNotificationArea } from './components';
import { ExternalRoute, ProtectedRoute } from './components/protected-route';
import { AuthContext } from './context';
import {
    ClientService,
    DashboardPage,
    ForgetPasswordPage,
    LoginPage,
    NoMatchPage,
    RegisterAccountPage,
    RegisterCorporateOrganizationPage,
    RegisterGovernmentOfficialPage,
    RegisterIndividualPage,
    RegistrationSuccessfulPage,
    ResetPasswordPage,
    ServicePage,
    TransactionServicesPage,
    UserActivitiesPage,
    UserProfilePage,
    VerifyEmailPage,
    ViewTransactionServicePage,
    RegisterFamilyPage,
} from './pages';
import { CreateBookingPage, ViewBookingPage } from './pages/admin/bookings';
import { CustomerSurveyPage } from './pages/customer-survey';
import { Invoice } from './pages/invoice';
import LandingPage from './pages/landing-page/landing-page';
import { Roles } from './util/constants';

Font.register({
    family: 'Oswald',
    src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf',
});

export default () => (
    <AuthContext.Provider>
        <PageLoader />
        <Switch>
            <ExternalRoute exact path="/" component={LandingPage} />
            <ExternalRoute exact path="/login" component={LoginPage} />
            <ExternalRoute exact path="/forget-password" component={ForgetPasswordPage} />
            <ExternalRoute exact path="/register" component={RegisterAccountPage} />
            <ExternalRoute exact path="/register-government-official" component={RegisterGovernmentOfficialPage} />
            <ExternalRoute
                exact
                path="/register-corporate-organization"
                component={RegisterCorporateOrganizationPage}
            />
            <ExternalRoute exact path="/register-individual" component={RegisterIndividualPage} />
            <ExternalRoute exact path="/register-family" component={RegisterFamilyPage} />
            <ExternalRoute exact path="/registration-success" component={RegistrationSuccessfulPage} />
            <ExternalRoute exact path="/verify-email/:code" component={VerifyEmailPage} />
            <ExternalRoute path="/reset-password/:code" component={ResetPasswordPage} />
            <ExternalRoute path="/customer-survey/:code" component={CustomerSurveyPage} />

            {/* TODO: Protected routes here */}
            <ProtectedRoute path="/app/profile" component={UserProfilePage} />
            <ProtectedRoute path="/app/dashboard" component={DashboardPage} />
            <ProtectedRoute path="/app/client-services" component={ClientService} />
            <ProtectedRoute path="/app/activities" component={UserActivitiesPage} />
            <ProtectedRoute path="/app/services" component={ServicePage} /* role={[Roles.Client, Roles.Admin]} */ />
            <ProtectedRoute path="/app/service/:service_id/create-booking" component={CreateBookingPage} />
            {/* <ProtectedRoute path="/app/service/:service_id" component={ViewServicePage} /> */}
            {/* <ProtectedRoute path="/app/bookings" component={BookingsPage} /> */}
            <ProtectedRoute path="/app/booking/:booking_id" component={ViewBookingPage} />
            <ProtectedRoute path="/app/transaction-services" component={TransactionServicesPage} />
            <ProtectedRoute
                path="/app/transaction-service/:transactionId"
                component={ViewTransactionServicePage}
                // role={[Roles.Accountant, Roles.Auditor, Roles.Commercial, Roles.Admin, Roles.Client]}
            />
            <Route path="/invoice" component={Invoice} />
            {/* When there is no page match */}
            <Route path="*">
                <NoMatchPage />
            </Route>
        </Switch>
        <ToastNotificationArea />
    </AuthContext.Provider>
);
