import { Airport } from '../../models/response';
import { ReduxAction } from '../common/redux-action';
import { AirportsActionNames, AirportsState } from './airports.types';

const initialState: AirportsState = {
    airports: [],
};

export const airportsReducer = (state = initialState, action: ReduxAction<AirportsActionNames>): AirportsState => {
    switch (action.type) {
        case AirportsActionNames.LIST: {
            return { ...state, airports: action.payload as Airport[] };
        }

        default: {
            return state;
        }
    }
};
