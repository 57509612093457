import { ReduxAction } from '../common/redux-action';
import { LoadingActionNames, LoadingState } from './loading.types';

const initialState: LoadingState = {
    tasks: {},
};

export const loadingReducer = (state = initialState, action: ReduxAction<LoadingActionNames>): LoadingState => {
    switch (action.type) {
        case LoadingActionNames.SET_LOADING: {
            const _state = { ...state };
            _state.tasks[action.payload.id] = action.payload.message;
            return _state;
        }

        case LoadingActionNames.SET_DONE: {
            const _state = { ...state };
            delete _state.tasks[action.payload.id];
            return _state;
        }

        default: {
            return state;
        }
    }
};
