import React from 'react';
import { SidebarMenu, TopNavigation } from '../index';
import { WalletView } from '../wallet';
import { LayoutStyleWrapper } from './auth-layout.style';

interface Props {
    showSidebar?: boolean;
}

class AuthLayout extends React.Component<Props, {}> {
    public static defaultProps = {
        showSidebar: true,
    };

    public render() {
        return (
            <React.Fragment>
                <TopNavigation />
                <LayoutStyleWrapper>
                    <SidebarMenu />
                    <div className="layoutWrapper">
                        <div className="mainWrapper">
                            <div className="bodyContent">{this.props.children}</div>
                            {this.props.showSidebar && <WalletView />}
                        </div>
                    </div>
                </LayoutStyleWrapper>
            </React.Fragment>
        );
    }
}

export default AuthLayout;
