import { Dropdown, DropdownMenu } from 'reactstrap';
import styled from 'styled-components';

export const StyledDropdown = styled(Dropdown)``;

export const CustomDropdownItem = styled.div`
    display: flex;
    width: 100%;
    padding: 0;
    background: transparent;
    border: none;
    font-size: 12px !important;
    font-weight: normal !important;
    justify-content: space-between;
    align-items: center;
    height: 20px;
    color: black;
    cursor: pointer;

    * {
        font-weight: normal !important;
        color: black !important;
    }
`;

export const CustomDropdownMenu = styled(DropdownMenu)`
    background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 7px;
    opacity: 1;
    width: 150px;
    min-width: 120px;
    padding: 16px;

    ${CustomDropdownItem} + ${CustomDropdownItem} {
        margin-top: 13px;
    }
`;
