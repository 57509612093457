import { Allow, IsEnum, Max, Min, MinLength, ValidateIf } from 'class-validator';
import { EOptions } from '../../../util/constants';
import { BaseModel } from '../base-model';

const MIN_RANK = 1;
const MAX_RANK = 5;

export class CustomerSurveyModel implements BaseModel {
    @Allow()
    public ActivationCode!: string;

    @Min(MIN_RANK, { message: 'Cannot be less than $constraint1' })
    @Max(MAX_RANK, { message: 'Cannot be more than $constraint1' })
    public FriendlinessRank: number = MIN_RANK;

    @Min(MIN_RANK, { message: 'Cannot be less than $constraint1' })
    @Max(MAX_RANK, { message: 'Cannot be more than $constraint1' })
    public KnowledgeRank: number = MIN_RANK;

    @Min(MIN_RANK, { message: 'Cannot be less than $constraint1' })
    @Max(MAX_RANK, { message: 'Cannot be more than $constraint1' })
    public QuicknessRank: number = MIN_RANK;

    @IsEnum(EOptions, { message: 'Invalid selection' })
    public EOptions: EOptions = EOptions.Yes;

    @MinLength(3, {
        message: 'Must be at least $constraint1 character(s) long',
    })
    @ValidateIf((o: CustomerSurveyModel) => !!o.FeedbackText)
    public FeedbackText: string = '';

    constructor(code: string) {
        this.ActivationCode = code;
    }

    public beforeValidation() {
        // tslint:disable: no-bitwise
        this.FriendlinessRank = +this.FriendlinessRank | 0;
        this.KnowledgeRank = +this.KnowledgeRank | 0;
        this.QuicknessRank = +this.QuicknessRank | 0;
        this.EOptions = +this.EOptions;
    }
}
