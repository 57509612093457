import React from 'react';
import { formatTime } from '../../../util/misc/formatters';
import { FlagTimeStyleWrapper } from './flag-time.style';

export const FlagTime: React.FC<{}> = (props) => {
    const [time, setTime] = React.useState(new Date());
    const formattedTime = React.useMemo(() => formatTime(time), [time]);

    React.useEffect(() => {
        const interval = setInterval(() => {
            setTime(new Date());
        }, 5 * 1000); // Update every five seconds

        return clearInterval.bind(globalThis, interval);
    });

    return (
        <FlagTimeStyleWrapper {...props}>
            <img alt="nigeria-flag" src={require('../../../assets/images/flag.png')} />
            <span>{formattedTime}</span>
        </FlagTimeStyleWrapper>
    );
};
