import moment from 'moment';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import { Link } from 'react-router-dom';
import { Col, Row, TabContent, TabPane } from 'reactstrap';
import styled from 'styled-components';
import {
    AppLayout,
    AuthLayout,
    CustomPrimaryButton,
    FlagTime,
    SignOutSwitchComponent,
    TabLink,
} from '../../../../components';
import { AuthContext } from '../../../../context';
import {
    UpdateCorporateClientModel,
    UpdateGovernmentOfficialModel,
    UpdateIndividualClientModel,
} from '../../../../models/request';
import { User } from '../../../../models/response';
import { AirportsThunk, ApplicationState } from '../../../../store';
import { UsersThunk } from '../../../../store/users/users.thunk';
import { ClientTypesMap, OrgCatergoryTypes, OrgCatergoryTypesMap } from '../../../../util/constants';
import { UpdateCorporateClientModal, UpdateGovernmenttModal, UpdateIndividualClientModal } from '../../manage-clients';

export const HeaderStyleWrapper = styled.div`
    display: flex;
    flex-direction: row;
    .left__header {
        flex: 1;
        display: flex;
        flex-direction: row;
    }
    .right {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
    }
`;

const StyleWrapper = styled.div`
    .detail-row {
        display: grid;
        grid-template-columns: 200px 1fr;
        padding: 20px 0;
        font-size: 14px;
        border-bottom: 1px solid #eaeaea;
    }

    .detail-row-others {
        display: flex;
        flex-direction: column;
        padding: 20px 0;
        font-size: 14px;
        border-bottom: 1px solid #eaeaea;
    }
`;

interface DetailRow {
    property: string;
    value?: string | number | undefined;
    bold?: boolean;
    link?: string;
    isLogout?: boolean;
}

export const UserProfilePage: React.FC = () => {
    const links: TabLink[] = [{ index: 1, name: 'My Profile' }];
    const [activeTab, setActiveTab] = React.useState(1);
    const [ClientModalOpen, setClientModalOpen] = React.useState(false);
    const { canAccess } = React.useContext(AuthContext.Context);

    const { auth, users } = useSelector((state: ApplicationState) => state);
    const userProfile = users?.userDetails;
    const business_name = userProfile?.business_Name;
    const full_name = userProfile && `${userProfile.firstName} ${userProfile.lastName}`;
    const OrgCategoryName =
        OrgCatergoryTypesMap[userProfile?.organization_CategoryId as keyof typeof OrgCatergoryTypesMap]; // Type assertion/casting

    const nameAndNatureOfClient =
        OrgCategoryName && OrgCatergoryTypesMap[OrgCatergoryTypes.Government]
            ? [{ property: 'Name Of Government Officials Office', value: business_name }]
            : [
                  { property: 'Business Name', value: business_name },
                  { property: 'Nature of Business', value: userProfile?.business_Nature },
              ];

    const dispatch = useDispatch();

    const fetchUser = React.useCallback(() => {
        dispatch(UsersThunk.getUser(auth.authUser?.id));
    }, [dispatch, auth.authUser]);

    const handleUpdateIndividualClient = React.useCallback(
        (userDetails: UpdateIndividualClientModel) => {
            dispatch(
                UsersThunk.updateIndividualClient(userDetails, () => {
                    setClientModalOpen(false);
                    fetchUser();
                })
            );
        },
        [dispatch, fetchUser]
    );

    const handleUpdateCorprateClient = React.useCallback(
        (userDetails: UpdateCorporateClientModel) => {
            dispatch(
                UsersThunk.updateCorporateClient(userDetails, () => {
                    setClientModalOpen(false);
                    fetchUser();
                })
            );
        },
        [dispatch, fetchUser]
    );

    const handleUpdateGovernmentClient = React.useCallback(
        (userDetails: UpdateGovernmentOfficialModel) => {
            console.log('data', userDetails);
            dispatch(
                UsersThunk.updateGovernmentClient(userDetails, () => {
                    setClientModalOpen(false);
                    fetchUser();
                })
            );
        },
        [dispatch, fetchUser]
    );

    React.useEffect(() => {
        fetchUser();
        dispatch(AirportsThunk.list());
    }, [dispatch, auth, fetchUser]);

    const details: DetailRow[] = (
        userProfile?.firstName ? [{ property: 'Full Name', value: full_name }] : nameAndNatureOfClient
    )
        .concat([
            { property: 'Service Type', value: ClientTypesMap[userProfile?.clientTypeId ?? 1] },
            { property: 'Client Type', value: OrgCategoryName ?? '' },
            { property: 'Address', value: userProfile?.registered_Address },
        ])
        .concat([
            { property: 'Email Address', value: userProfile?.email },
            { property: 'Phone Number', value: userProfile?.phoneNumber },
            { property: 'Date Registered', value: moment(userProfile?.dateCreated).format('Do MMMM, YYYY') },
        ]);

    return (
        <AuthLayout showSidebar>
            <React.Fragment>
                {/* {userProfile &&
                    canAccess() && ( // To confirm requirment */}
                {/* // {userProfile && canAccess() && !userProfile.firstName && ( */}

                {userProfile && OrgCategoryName == OrgCatergoryTypesMap[OrgCatergoryTypes.Corporate] && (
                    <UpdateCorporateClientModal
                        isOpen={ClientModalOpen}
                        setIsOpen={setClientModalOpen}
                        client={userProfile as User}
                        handleUpdateClient={handleUpdateCorprateClient}
                    />
                )}
                {userProfile && OrgCategoryName == OrgCatergoryTypesMap[OrgCatergoryTypes.Government] && (
                    <UpdateGovernmenttModal
                        isOpen={ClientModalOpen}
                        setIsOpen={setClientModalOpen}
                        client={userProfile as User}
                        handleUpdateClient={handleUpdateGovernmentClient}
                    />
                )}
                {userProfile && OrgCategoryName == OrgCatergoryTypesMap[OrgCatergoryTypes.Individual] && (
                    <UpdateIndividualClientModal
                        isOpen={ClientModalOpen}
                        setIsOpen={setClientModalOpen}
                        client={userProfile as User}
                        handleUpdateClient={handleUpdateIndividualClient}
                    />
                )}

                <Row className="d-flex">
                    <Col lg={3}>
                        <FlagTime />
                    </Col>
                    <Col lg={9} className="d-flex justify-content-end">
                        <SignOutSwitchComponent />
                    </Col>
                </Row>
                <Row className="mt-5">
                    <Col lg={6}>
                        <AppLayout.Wrapper>
                            <AppLayout.Header>
                                <HeaderStyleWrapper>
                                    <div className="left__header">
                                        <h3 className="header__title">My Profile</h3>
                                    </div>
                                    <div className="right d-flex justify-content-end">
                                        <CustomPrimaryButton onClick={setClientModalOpen.bind(undefined, true)}>
                                            <span>Edit Profile</span>
                                        </CustomPrimaryButton>
                                    </div>
                                </HeaderStyleWrapper>
                            </AppLayout.Header>
                            <AppLayout.Body>
                                <AppLayout.TabHeader links={links} activeTab={activeTab} setActiveTab={setActiveTab} />
                                <AppLayout.TabBody>
                                    <TabContent activeTab={activeTab} className="tab__content">
                                        <TabPane tabId={1}>
                                            <StyleWrapper>
                                                {details.map(({ property, value }, i) => {
                                                    return (
                                                        <div className="detail-row" key={i}>
                                                            <b>{property}</b>
                                                            <span>{value}</span>
                                                        </div>
                                                    );
                                                })}
                                            </StyleWrapper>
                                        </TabPane>
                                    </TabContent>
                                </AppLayout.TabBody>
                            </AppLayout.Body>
                        </AppLayout.Wrapper>
                    </Col>
                </Row>
            </React.Fragment>
        </AuthLayout>
    );
};
