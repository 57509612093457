import { IsEmail, Matches, MinLength } from 'class-validator';
import { BaseModel } from '../base-model';

export class UserLoginModel implements BaseModel {
    @IsEmail(undefined, { message: 'Email is not valid' })
    public Email!: string;

    @MinLength(1, {
        message: `Password must not be empty`,
    })

    // @Matches(CONTAINS_DIGIT, {
    //     message: 'Password must contain a digit',
    // })
    // @Matches(CONTAINS_SPECIAL_CHARACTER, {
    //     message: 'Password must contain a special character',
    // })
    // @Matches(CONTAINS_LOWERCASE, {
    //     message: 'Password must contain an lowercase letter',
    // })
    // @Matches(CONTAINS_UPPERCASE, {
    //     message: 'Password must contain an uppercase letter',
    // })
    // @MinLength(PASSWORD_MIN_LENGTH, {
    //     message: `Password must be at least ${PASSWORD_MIN_LENGTH} character(s) long`,
    // })
    public Password!: string;

    constructor() {
        this.Email = '';
        this.Password = '';
    }

    public beforeValidation() {}
}
