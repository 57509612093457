import { Allow, IsBoolean, IsEnum, MinLength, ValidateIf } from 'class-validator';
import { Genders, Honorifics } from '../../../util/constants';
import { BaseModel } from '../base-model';

export class CreatePassenger implements BaseModel {
    @MinLength(3, {
        message: 'Must be at least $constraint1 character(s) long',
    })
    public FirstName: string = '';

    public additionalServiceId: number = 0;

    @MinLength(3, {
        message: 'Must be at least $constraint1 character(s) long',
    })
    @ValidateIf((o: CreatePassenger) => !!o.MiddleName)
    public MiddleName: string = '';

    @MinLength(3, {
        message: 'Must be at least $constraint1 character(s) long',
    })
    public LastName: string = '';

    @IsEnum(Honorifics, { message: 'Invalid selection' })
    public Designation: Honorifics = Honorifics.Miss;

    @MinLength(3, {
        message: 'Must be at least $constraint1 character(s) long',
    })
    public schoolName: string = '';

    @MinLength(11, {
        message: 'Must be at least $constraint1 character(s) long',
    })
    public mobileNumber: string = '';

    @IsBoolean({ message: 'Invalid selection' })
    public isStudent: boolean = false;

    @MinLength(11, {
        message: 'Must be at least $constraint1 character(s) long',
    })
    public nin: string = '';

    @MinLength(3, {
        message: 'Must be at least $constraint1 character(s) long',
    })
    @ValidateIf((o: CreatePassenger) => o.Others)
    public Special_Requirement: string = '';

    @IsBoolean({ message: 'Invalid selection' })
    public Wheel_Chair: boolean = false;

    @Allow()
    public Others: boolean = false;

    @IsEnum(Genders, { message: 'Invalid gender selection' })
    public Gender: Genders = Genders.Female;

    public beforeValidation() {}
}
