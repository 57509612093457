import { PDFDownloadLink } from '@react-pdf/renderer';
import saveAs from 'file-saver';
import React, { useContext } from 'react';
import { File, Send } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useParams } from 'react-router';
import { TabContent, TabPane } from 'reactstrap';
import styled from 'styled-components';
import { HeaderStyleWrapper, Invoice } from '../..';
import { AppLayout, AuthLayout, Breadcrumb, CustomPrimaryButton, TopBar } from '../../../components';
import { AuthContext } from '../../../context';
import { Transaction, User } from '../../../models/response';
import { AirportsThunk, ApplicationState, NotificationsThunk, UsersThunk } from '../../../store';
import { ApiClient } from '../../../util/api-client';
import { NotificationTypes, Roles } from '../../../util/constants';
import { formatDate } from '../../../util/misc/formatters';
import { RegistrationTransactionsTable } from '../components';
import { StyleWrapper } from './view-fund-service';

interface Props {
    bookingId?: string;
    breadcrumbs: Breadcrumb[];
    abortFetchTransaction?: boolean;
}

export const ViewRegistrationTransaction: React.FC<Props> = ({
    bookingId,
    breadcrumbs: crumbs,
    abortFetchTransaction,
}) => {
    const id = bookingId ?? useParams<{ booking_id: string }>().booking_id;
    const { user: loggedInUser } = useContext(AuthContext.Context)!;
    const { userDetails } = useSelector(({ users }: ApplicationState) => users);
    const { airports } = useSelector(({ airports }: ApplicationState) => airports);
    const { transactionType, transactionTypeMeta } = useSelector((state: ApplicationState) => state.transactions);
    const dispatch = useDispatch();

    console.log('dd', transactionType);

    const makePayment = () => window.open('https://www.faan.gov.ng/faan-payments/', '_blank');
    React.useEffect(() => {
        dispatch(AirportsThunk.list());
        dispatch(UsersThunk.getUser(transactionType[0]?.transactionId));

        // if (!abortFetchTransaction) {
        //     dispatch(TransactionsThunk.retrieveByTrasactionId(+(id as string)));
        // }
    }, [dispatch, id, abortFetchTransaction, transactionType]);

    const canPay = React.useMemo(
        () =>
            !transactionType[0]?.paymentStatus ||
            (transactionType[0]?.paymentStatus === 'Unpaid' && loggedInUser?.role === Roles.Client),
        [transactionType, loggedInUser]
    );

    const exportInvoice = React.useCallback(
        async (transaction: Transaction, fileType: string) => {
            const config: RequestInit = {
                ...ApiClient.getFetchConfig(),
            };

            const path =
                fileType === 'invoice'
                    ? '/transactions/transaction-receipt/' // if fileType is invoice return '/tras....../'
                    : fileType === 'receipt'
                    ? '/transactions/transaction-receipt/' // else if fileType is reciept return '/tras....../'
                    : ''; // elase return emplty string

            const name = `${transactionType[0].business_Name} - ${transactionType[0].transactType} - ${formatDate(
                transactionType[0].dateCreated
            )}`;

            try {
                dispatch(NotificationsThunk.create(NotificationTypes.Info, `Downloading document`));
                const response = await fetch(`${process.env.REACT_APP_BASE_URL}${path}${transaction.id}`, config);

                try {
                    const downloadedFile = await response.blob();
                    saveAs(downloadedFile, name + '.pdf');
                } catch (e) {
                    dispatch(NotificationsThunk.create(NotificationTypes.Error, 'Error saving file'));
                }
            } catch (e) {
                dispatch(NotificationsThunk.create(NotificationTypes.Error, 'Error downloading file'));
            }
        },
        [dispatch]
    );

    const exportFile = React.useCallback(async () => {
        const config: RequestInit = {
            ...ApiClient.getFetchConfig(),
        };

        try {
            dispatch(NotificationsThunk.create(NotificationTypes.Info, `Downloading document`));

            const response = await fetch(
                `${process.env.REACT_APP_BASE_URL}/booking-services/export/${transactionType[0]?.id}`,
                config
            );

            try {
                const downloadedFile = await response.blob();
                const documentName = transactionType[0]?.business_Name;
                saveAs(downloadedFile, documentName + '.pdf');
            } catch (e) {
                dispatch(NotificationsThunk.create(NotificationTypes.Error, 'Error saving file'));
            }
        } catch (e) {
            dispatch(NotificationsThunk.create(NotificationTypes.Error, 'Error downloading file'));
        }
    }, [dispatch, transactionType]);

    const breadcrumbs: Breadcrumb[] = crumbs ?? [
        { name: 'Dashboard', path: '/app/dashboard' },
        { name: 'All Bookings', path: '/app/bookings' },
    ];

    if (!transactionType.length) {
        return <Redirect to="/app/transaction-services" />;
    }

    return (
        <AuthLayout showSidebar={false}>
            <TopBar breadcrumbs={breadcrumbs} />
            {true && (
                <React.Fragment>
                    <AppLayout.Wrapper>
                        <AppLayout.Header>
                            <HeaderStyleWrapper>
                                <div className="left__header d-flex align-items-start">
                                    <AppLayout.HeaderBackButton />
                                </div>
                                <div className="right text-right">
                                    {/* {transactionType.length > 0 && (
                                        <CustomPrimaryButton
                                            className="inverted small"
                                            onClick={() => exportInvoice(transactionType[0])}
                                        >
                                            <File size={10} strokeWidth={3} />
                                            <span>
                                                Get {transactionType[0].paymentVerified ? 'receipt' : 'invoice'}
                                            </span>
                                        </CustomPrimaryButton>
                                    )} */}
                                    {transactionType.length > 0 &&
                                        (transactionType[0]?.paymentStatus &&
                                        transactionType[0]?.paymentStatus.toLocaleLowerCase() === 'paid' ? (
                                            <CustomPrimaryButton
                                                className="inverted small"
                                                onClick={() => exportInvoice(transactionType[0], 'receipt')}
                                            >
                                                <File size={10} strokeWidth={3} />
                                                <span>Get Receipt</span>
                                            </CustomPrimaryButton>
                                        ) : (
                                            <CustomPrimaryButton
                                                className="inverted small"
                                                onClick={() => exportInvoice(transactionType[0], 'invoice')}
                                            >
                                                <File size={10} strokeWidth={3} />
                                                <span>Get Invoice</span>
                                            </CustomPrimaryButton>
                                        ))}
                                    {/* <CustomPrimaryButton
                                        className="inverted small ml-2"
                                        onClick={(e: any) => {
                                            e.preventDefault();
                                            exportFile();
                                        }}
                                    >
                                        <Send size={10} strokeWidth={3} />
                                        <span>Export</span>
                                    </CustomPrimaryButton> */}
                                </div>
                            </HeaderStyleWrapper>
                        </AppLayout.Header>
                        <AppLayout.Body>
                            <AppLayout.TabHeader>
                                <div className="my-4 d-flex justify-content-between">Registration Details</div>
                            </AppLayout.TabHeader>
                            <AppLayout.TabBody>
                                <TabContent activeTab={1} className="tab__content">
                                    <TabPane tabId={1}>
                                        <StyleWrapper>
                                            {transactionType.length ? (
                                                <RegistrationTransactionsTable
                                                    transactions={transactionType}
                                                    // hideOptions
                                                    meta={transactionTypeMeta}
                                                />
                                            ) : (
                                                <p>There are no funds transactions</p>
                                            )}
                                        </StyleWrapper>

                                        {canPay && (
                                            <CustomPrimaryButton
                                                onClick={makePayment}
                                                target="_blank"
                                                disabled={!transactionType.length}
                                            >
                                                <span>Pay now</span>
                                            </CustomPrimaryButton>
                                        )}
                                    </TabPane>
                                </TabContent>
                            </AppLayout.TabBody>
                        </AppLayout.Body>
                    </AppLayout.Wrapper>
                </React.Fragment>
            )}
        </AuthLayout>
    );
};

// export const StyleWrapper = styled.div`
//     width: 100%;
//     display: flex;
//     flex-direction: column;
//     margin-bottom: 1rem;

//     .detail-row {
//         display: grid;
//         grid-template-columns: 200px 1fr;
//         padding: 20px 0;
//         font-size: 14px;
//         border-bottom: 2px solid #eaeaea;
//     }
// `;
