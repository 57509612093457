import React from 'react';
import styled from 'styled-components';
import { Service } from '../../../../models/response';
import { ClientTypesMap } from '../../../../util/constants';
import { formatCurrency } from '../../../../util/misc';

const StyleWrapper = styled.div`
    max-width: 500px;
    display: flex;
    flex-direction: column;
    margin-bottom: 5rem;

    .detail-row {
        display: grid;
        grid-template-columns: 200px 1fr;
        padding: 20px 0;
        font-size: 14px;
        border-bottom: 1px solid #eaeaea;
    }
`;

interface DetailRow {
    property: string;
    value: string | number;
    bold?: boolean;
}

interface ServiceInformationComponentProps {
    service: Service;
}

export const ServiceInformationComponent: React.FC<ServiceInformationComponentProps> = ({ service }) => {
    const details: DetailRow[] = [
        { property: 'Service Name', value: service.name, bold: true },
        { property: 'Description', value: service.description },
        { property: 'Service Fee', value: formatCurrency(service.price), bold: true },
        {
            property: 'Service Type',
            value: ClientTypesMap[service.serviceTypeId],
            bold: true,
        },
    ];
    return (
        <StyleWrapper>
            {details.map(({ property, value, bold }, i) => {
                const Tag = bold ? 'b' : 'span';
                return (
                    <div className="detail-row" key={i}>
                        <span>{property}</span>
                        <Tag>{value}</Tag>
                    </div>
                );
            })}
        </StyleWrapper>
    );
};
