import * as React from 'react';
import { Link } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import { Container, CustomPrimaryButton } from '../../components';
import { LandingPageStyleWrapper } from './landing-page.style';
import Arrival from '../../assets/images/arrivalService.png';
import ArrivalIcon from '../../assets/images/arrivalServiceIcon.png';
import Departure from '../../assets/images/departureService.png';
import DepartureIcon from '../../assets/images/departureServiceIcon.png';
import Car from '../../assets/images/carService.png';
import CarIcon from '../../assets/images/carServiceIcon.png';
import Lounge from '../../assets/images/loungeService.png';
import LoungeIcon from '../../assets/images/loungeServiceIcon.png';
import LandingPageNav from '../../components/nav-menu/landing-page-nav';
import FAQ from '../../components/accordion/FQA';
import Layout from '../../components/layout';

class LandingPage extends React.Component<{}, {}> {
    public render() {
        return (
            <Layout>
                <LandingPageStyleWrapper>
                    <div className="landing">
                        <LandingPageNav />
                        <section className="leaderBoard">
                            <Container>
                                <div className="row">
                                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                        <div className="contentWrapper">
                                            {/* <h1 className="text text-white font-weight-bolder">
                                            The number one provider of fast track airports services in Nigeria.
                                        </h1> */}
                                            <div className="titleConatiner mt-6">
                                                {' '}
                                                <div className="text text-white font-weight-bolder">The number one</div>
                                                <div className="text text-white font-weight-bolder">
                                                    provider of fast track
                                                </div>
                                                <div className="text text-white font-weight-bolder">
                                                    airports services in Nigeria.{' '}
                                                </div>
                                            </div>

                                            <p className="text-white">
                                                From your home to the airport to your destination, we remove the stress
                                                and hassles, leaving you with a fabulous travel experience.
                                            </p>
                                            <div className="btnWrapper py-4">
                                                <CustomPrimaryButton
                                                    type="button"
                                                    className="logBtn ml-3 py-3"
                                                    as={Link}
                                                    to="/login"
                                                >
                                                    Log into account
                                                </CustomPrimaryButton>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Container>
                        </section>
                    </div>
                    <section id="services">
                        <div className="text-center mt-5 font-weight-bolder">
                            <h3>Our Services</h3>
                            <p>We remove the stress and hassle, and leaving you with a fabulous travel experience.</p>
                        </div>
                        <div className="container-fluid bg-custom-green pb-4">
                            <Container>
                                <Row>
                                    <Col>
                                        <img src={Arrival} alt="arrival" width="600px" height="450px" />
                                    </Col>
                                    <Col>
                                        <div className="mt-7">
                                            <img src={ArrivalIcon} alt="Arrival icon" width="50px" height="50px" />
                                            <h3 className="my-4">Arrival Services</h3>
                                            <p className="">
                                                Receiving arrival clients, handling immigration clearance, <br />{' '}
                                                baggage collection and customs formalities.
                                            </p>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </div>

                        <div className="container-fluid pb-4">
                            <Container>
                                <Row>
                                    <Col>
                                        <img src={Departure} alt="departure" width="600px" height="450px" />
                                    </Col>
                                    <Col>
                                        <div className="mt-7">
                                            <img src={DepartureIcon} alt="Departure icon" width="50px" height="50px" />
                                            <h3 className="my-4">Departure Service</h3>
                                            <p className="">
                                                Receiving intending passengers at departure level and <br /> conducting
                                                check-in formalities with airlines, border agencies <br /> clearance.
                                            </p>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                        <div className="container-fluid bg-custom-green">
                            <Container>
                                <Row>
                                    <Col>
                                        <img src={Car} alt="car" width="600px" height="450px" />
                                    </Col>
                                    <Col>
                                        <div className="mt-7">
                                            <img src={CarIcon} alt="Car icon" width="50px" height="50px" />
                                            <h3 className="my-4">Car Services</h3>
                                            <p className="">
                                                Securing car hire for clients where needed. <br /> Making reservations
                                                and logistics arrangement on domestic <br /> routes.
                                            </p>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                        <div className="container-fluid p-4">
                            <Container>
                                <Row>
                                    <Col>
                                        <img src={Lounge} alt="Lounge" width="600px" height="450px" />
                                    </Col>
                                    <Col>
                                        <div className="mt-7">
                                            <img src={LoungeIcon} alt="Lounge icon" width="50px" height="50px" />
                                            <h3 className="my-4">Lounge Services</h3>
                                            <p className="">
                                                Executive lounge access and convenience.
                                                <br /> And any other travel comfort client may require in line with{' '}
                                                <br /> best practice.
                                            </p>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                        <div className="text-center mt-3 font-weight-bolder">
                            <h3>Book Our Services Now</h3>

                            <div className="btnWrapper py-4">
                                <CustomPrimaryButton
                                    className="btn bg-green border-pill text-white shadow rounded-pill px-3 border-0"
                                    as={Link}
                                    to="/register"
                                >
                                    Register
                                </CustomPrimaryButton>
                            </div>
                        </div>
                    </section>
                    <section id="FAQ">
                        <div className="container-fluid faq-section p-lg-5 p-md-5">
                            <Container>
                                <div className="text-white text-center my-lg-6 my-md-6">
                                    <h3>Frequently Asked Questions</h3>
                                    <p>
                                        We remove the stress and hassle, and leaving you with a fabulous travel
                                        experience.
                                    </p>
                                </div>
                                <FAQ />
                            </Container>
                        </div>
                    </section>

                    <section className="pt-5 pb-5" id="partners">
                        <Container>
                            <Row>
                                <Col>
                                    <div>
                                        <img
                                            alt="award2"
                                            width="260px"
                                            height="160px"
                                            src={require('../../assets/images/award03.jpeg')}
                                        />
                                    </div>
                                </Col>
                                <Col>
                                    <div>
                                        <img
                                            alt="award1"
                                            width="260px"
                                            height="130px"
                                            src={require('../../assets/images/award1.png')}
                                        />
                                    </div>
                                </Col>
                                <Col>
                                    <div>
                                        <img
                                            alt="award3"
                                            width="260px"
                                            height="130px"
                                            src={require('../../assets/images/award3.jpeg')}
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <hr />
                        </Container>
                    </section>
                    <section className="infoSection pt-3 pb-5">
                        <Container>
                            <Row>
                                <Col>
                                    <div>
                                        <h3>Lagos </h3>
                                        <p>
                                            Murtala Muhammed International <br />
                                            Airport, Lagos. <br />
                                            <b>Tel:</b> +234 8160857222 <br />
                                            <b>Tel:</b> +234 7038639882 <br />
                                            {/* <b>Email:</b> hello@faanaps.com <br /> */}
                                            <b>Email:</b> faanprotocolhq@faan.gov.ng
                                        </p>
                                    </div>
                                </Col>
                                <Col>
                                    <div>
                                        <h3>Port Harcourt</h3>
                                        <p>
                                            Port Harcourt Airport, Kaicon Express
                                            <br />
                                            Way, Port Harcourt. <br />
                                            <b>Tel:</b> +234 8160857222 <br />
                                            <b>Tel:</b> +234 7038639882 <br />
                                            {/* <b>Email:</b> hello@faanaps.com <br /> */}
                                            <b>Email:</b> faanprotocolhq@faan.gov.ng
                                        </p>
                                    </div>
                                </Col>
                                <Col>
                                    <div>
                                        <h3>Abuja</h3>
                                        <p>
                                            Nnamdi Azikiwe International <br />
                                            Airport, Abuja. <br />
                                            <b>Tel:</b> +234 8160857222 <br />
                                            <b>Tel:</b> +234 7038639882 <br />
                                            {/* <b>Email:</b> hello@faanaps.com <br /> */}
                                            <b>Email:</b> faanprotocolhq@faan.gov.ng
                                        </p>
                                    </div>
                                </Col>
                                <Col>
                                    <div>
                                        <h3>Kano</h3>
                                        <p>
                                            Mallam Aminu Kano International <br />
                                            Airpot, Kano. <br />
                                            <b>Tel:</b> +234 8160857222 <br />
                                            <b>Tel:</b> +234 7038639882 <br />
                                            {/* <b>Email:</b> hello@faanaps.com <br /> */}
                                            <b>Email:</b> faanprotocolhq@faan.gov.ng
                                        </p>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </section>
                    <section className="footerSection bg-custom-green-2" id="contact">
                        <div className="footerBottom pt-4 pb-4 ">
                            <Container>
                                <div className="row_1">
                                    <div className="col_1">
                                        <div className="copyright">
                                            © Copyright {new Date().getFullYear()} Airport Protocol Service. All rights
                                            reserved.
                                        </div>
                                    </div>
                                    <div className="col_2">
                                        <div className="copyright">
                                            <b>Credit</b> -{' '}
                                            <a
                                                className="text-dark"
                                                href="https://needletechnology.com.ng"
                                                rel="noreferrer"
                                            >
                                                Needle Technology
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </Container>
                        </div>
                    </section>
                </LandingPageStyleWrapper>
            </Layout>
        );
    }
}

export default LandingPage;
