import { omit } from 'lodash';
import { User } from '../../models/response';
import { ReduxAction } from '../common/redux-action';
import { UsersActionNames, UsersState } from './users.types';

const initialState: UsersState = {
    usersBookings: [],
    users: [],
    usersMeta: undefined,
    clients: [],
    clientsMeta: undefined,
    dashboardStats: undefined,
};

export const usersReducer = (state = initialState, action: ReduxAction<UsersActionNames>): UsersState => {
    switch (action.type) {
        case UsersActionNames.ADD_USER: {
            const _state = { ...state };
            _state.users.unshift(action.payload);
            return _state;
        }

        case UsersActionNames.GET_USERS: {
            return {
                ...state,
                users: action.payload.data,
                usersMeta: action.payload.meta,
            };
        }

        case UsersActionNames.GET_CLIENTS: {
            return {
                ...state,
                clients: action.payload.data,
                clientsMeta: action.payload.meta,
            };
        }

        case UsersActionNames.GET_CLIENT_STATS: {
            return {
                ...state,
                clientStats: action.payload,
            };
        }

        case UsersActionNames.GET_CLIENT_BOOKING_STAT: {
            return {
                ...state,
                clientBookingStats: action.payload,
            };
        }

        case UsersActionNames.GET_ACTIVITIES: {
            return {
                ...state,
                userActivities: action.payload,
            };
        }

        case UsersActionNames.GET_DASHBOARD_STATS: {
            return {
                ...state,
                dashboardStats: action.payload,
            };
        }

        case UsersActionNames.GET_USER: {
            return {
                ...state,
                userDetails: action.payload,
            };
        }

        case UsersActionNames.GET_USERBOOKINGS: {
            return {
                ...state,
                usersBookings: action.payload,
            };
        }

        case UsersActionNames.UPDATE:
        case UsersActionNames.UPDATE_CLIENT: {
            const _state = { ...state };
            const userIndex = _state.users.findIndex((_) => _.id === (action.payload as User).id);

            if (userIndex < 0) {
                return state;
            }

            const currentUser = _state.users[userIndex];
            const updatedUser = (_state.users[userIndex] = {
                ...currentUser,
                ...omit(action.payload, ['clientFiles']),
            });

            if (_state.userDetails && _state.userDetails.id === action.payload.id) {
                _state.userDetails = updatedUser;
            }

            return _state;
        }

        default: {
            return state;
        }
    }
};
