import React from 'react';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { Cell, Column } from 'react-table';
import { CustomPrimaryButton, DataTable } from '../../../components';
import { Transaction } from '../../../models/response';
import { TransactionsActions } from '../../../store';
import { ApiResponse } from '../../../util/api-client';
import { formatCurrency } from '../../../util/misc';
import { formatDate } from '../../../util/misc/formatters';

interface Props {
    transactions: Transaction[];
    meta?: ApiResponse<Transaction[]>['meta'];
    isBooking?: boolean;
}

export const TransactionServicesTable: React.FC<Props> = ({ transactions, meta, isBooking }) => {
    const history = useHistory();
    const dispatch = useDispatch();

    const extraColumns: Column<Transaction>[] = [
        {
            Header: 'Client',
            accessor: 'business_Name',
        },
        {
            Header: 'RRR Number',
            accessor: 'rrrNo',
            Cell: ({ row: { original } }: Cell<Transaction>) => {
                return <span style={{ color: 'var(--primary-color)' }}>{original.rrrNo ?? 'Processing...'}</span>;
            },
        },
        {
            Header: 'Payment Status',
            accessor: 'paymentStatus',
            Cell: ({ row: { original } }: Cell<Transaction>) => {
                const color = original.paymentStatus ? 'var(--primary-color)' : '#D09611';
                return <span style={{ color }}>{original.paymentStatus ?? 'Unpaid'}</span>;
            },
        },
        {
            Header: 'Funded By',
            accessor: 'createdBy',
            Cell: ({ row: { original } }: Cell<Transaction>) => {
                return <span style={{ color: 'var(--primary-color)' }}>{original.createdBy ?? 'N/A'}</span>;
            },
        },
    ];

    return (
        <DataTable
            columns={[
                {
                    Header: 'Date',
                    accessor: 'dateCreated',
                    Cell: ({ row: { original } }: Cell<Transaction>) => {
                        return <span>{formatDate(original.dateCreated)}</span>;
                    },
                },
                {
                    Header: 'Service',
                    accessor: 'transactType',
                    Cell: ({ row: { original } }: Cell<Transaction>) => {
                        return <span>{original.transactType}</span>;
                    },
                },
                {
                    Header: 'Amount',
                    accessor: 'amount',
                    Cell: ({ row: { original } }: Cell<Transaction>) => {
                        return <span>{formatCurrency(original.amount)}</span>;
                    },
                },
                {
                    Header: 'Funded By',
                    accessor: 'createdBy',
                    Cell: ({ row: { original } }: Cell<Transaction>) => {
                        return <span style={{ color: 'var(--primary-color)' }}>{original.createdBy ?? 'N/A'}</span>;
                    },
                },
                ...extraColumns,
                {
                    Header: 'Action',
                    Cell: ({ row: { original: transaction } }: Cell<Transaction>) => {
                        return transaction.transactType === 'Funding' ? (
                            <CustomPrimaryButton
                                className="small inverted"
                                onClick={() => {
                                    dispatch(TransactionsActions.retrieveType([transaction], undefined));
                                    history.push(
                                        `/app/transaction-service/${transaction.transactionId}?type=${transaction.transactionType}`
                                    );
                                }}
                            >
                                View Service
                            </CustomPrimaryButton>
                        ) : (
                            <CustomPrimaryButton
                                as={Link}
                                className="small inverted"
                                to={
                                    transaction.transactionType === 1
                                        ? `/app/client/${transaction.transactionId}`
                                        : `/app/transaction-service/${transaction.transactionId}?type=${transaction.transactionType}`
                                }
                            >
                                View service
                            </CustomPrimaryButton>
                        );
                    },
                },
            ]}
            data={transactions}
            meta={meta}
        />
    );
};
