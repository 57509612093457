import styled from 'styled-components';
import backgroundImage from '../../assets/images/bgimage.jpg';
import FAQImage from '../../assets/images/fqa-bg.png';

export const LandingPageStyleWrapper = styled.div`
    @media only screen and (min-width: 600px) {
        .leaderBoard {
            background-position-x: center;
            background-repeat: no-repeat;
            background-size: cover;
            background-image: url(${backgroundImage});
            height: 100vh;
            .contentWrapper {
                padding-right: 50px;
                padding-top: 100px;

                .titleConatiner {
                    padding: 0px;
                    margin-bottom: 30px !important;
                }

                h5 {
                    font-size: 14px !important;
                    color: #000000;
                    letter-spacing: 1.4px !important;
                    font: Bold 14px/17px Adobe Clean !important;
                }

                .text {
                    letter-spacing: 0px !important;
                    font-size: 40px !important;
                    font-weight: bolder;
                    line-height: 60px !important;
                    // margin-bottom: 20px !important;
                }

                p {
                    font-size: 20px !important;
                    line-height: 30px !important;
                }

                .btnWrapper {
                    display: flex !important;
                    flex-direction: row !important;
                    margin-top: 20px !important;

                    .getStartedBtn {
                        width: 200px !important;
                        background-color: #18a956 !important;
                        color: #fff !important;
                        font-size: 16px !important;
                        letter-spacing: 1.4px !important;
                        border-radius: 30px !important;
                        border: 0 !important;
                        height: 50px !important;
                        text-transform: uppercase;
                    }

                    .logBtn {
                        height: unset !important;
                        width: 200px !important;
                        background-color: transparent !important;
                        color: #fff !important;
                        font-size: 16px !important;
                        letter-spacing: 1.4px !important;
                        border-radius: 30px !important;
                        border: 1px solid #fff !important;
                        box-shadow: none !important;
                        text-transform: uppercase;
                    }
                }

                span {
                    display: block !important;
                    margin-top: 10px !important;
                    font-size: 13px !important;

                    a {
                        color: #18a956 !important;
                    }
                }
            }

            img {
                max-width: 100% !important;
                max-height: 450px !important;
            }
        }

        .bg-custom-green {
            background-color: rgba(38, 169, 85, 0.05) !important;
        }

        .bg-custom-green-2 {
            background-color: rgba(38, 169, 85, 0.12) !important;
        }

        .faq-section {
            background-repeat: no-repeat;
            background-size: cover;
            background-image: url(${FAQImage});
            background-position: center;
        }

        .serviceSection {
            background-color: #18a956 !important;
            padding-top: 50px !important;
            border: 1px solid #707070 !important;

            img {
                max-width: 200px !important;
                height: auto !important;
            }

            .contentWrapper {
                display: flex !important;
                flex-direction: column !important;
                align-items: center !important;
                justify-content: center !important;

                h1 {
                    color: #fff !important;
                    font-weight: bold !important;
                }

                .btnWrapper {
                    display: flex !important;
                    flex-direction: row !important;
                    margin-top: 10px !important;

                    .getStartedBtn {
                        width: 200px !important;
                        background-color: #fff !important;
                        color: #18a956 !important;
                        font-size: 12px !important;
                        letter-spacing: 1.4px !important;
                        border-radius: 8px !important;
                        border: 0 !important;
                        height: 50px !important;
                    }

                    .logBtn {
                        width: 250px !important;
                        background-color: unset !important;
                        color: #fff !important;
                        font-size: 13px !important;
                        letter-spacing: 1.4px !important;
                        border-radius: 8px !important;
                        border: 1px solid #fff !important;
                        box-shadow: none !important;
                    }
                }
            }
        }
        .infoSection {
            .logoWrapper {
                text-align: right !important;

                img {
                    max-width: 150px !important;
                    height: auto;
                }
            }
            .pDiv {
                width: 700px !important;

                p {
                    font-size: 17px !important;
                }
            }

            .row_1.address {
                display: flex !important;
                flex-direction: row !important;

                .col_1 {
                    margin-right: 40px;
                }

                h3 {
                    font-weight: bold !important;
                    font-size: 24px !important;
                }

                p {
                    font-size: 14px !important;
                }
            }
        }

        .footerSection {
            .topFooter {
                .row_1 {
                    display: flex !important;
                    flex-direction: row !important;
                }

                .col_1 {
                    flex: 1;
                }

                .col_2 {
                    flex: 2;
                    display: flex;
                    flex-direction: row-reverse;
                }
            }

            .footerBottom {
                .row_1 {
                    display: flex !important;
                    flex-direction: row !important;
                }

                .col_1 {
                    flex: 1;
                }

                .col_2 {
                    flex: 2;
                    display: flex;
                    flex-direction: row-reverse;
                }

                font-size: 13px !important;
            }
        }
    }

    @media only screen and (max-width: 600px) {
        .leaderBoard {
            background-position-x: center;
            background-repeat: no-repeat;
            background-size: cover;
            background-image: url(${backgroundImage});
            height: 100vh;
            .contentWrapper {
                // border: 2px solid red;
                margin-top: 100px;

                h5 {
                    font-size: 14px !important;
                    color: #000000;
                    letter-spacing: 1.4px !important;
                    font: Bold 14px/17px Adobe Clean !important;
                }

                h1 {
                    letter-spacing: 0px !important;
                    font-size: 40px !important;
                    font-weight: bold;
                    line-height: 40px !important;
                    margin-bottom: 20px !important;
                }

                p {
                    font-size: 16px !important;
                    line-height: 25px !important;
                    margin-top: 20px;
                }

                .btnWrapper {
                    display: flex !important;
                    flex-direction: column !important;
                    margin-top: 20px !important;
                    justify-content: center;
                    align-items: center;

                    .getStartedBtn {
                        width: 100% !important;
                        background-color: #18a956 !important;
                        color: #fff !important;
                        font-size: 16px !important;
                        letter-spacing: 1.4px !important;
                        border-radius: 30px !important;
                        border: 0 !important;
                        height: 50px !important;
                        text-transform: uppercase;
                        margin-bottom: 20px;
                    }

                    .logBtn {
                        width: 100% !important;
                        height: 50px !important;
                        background-color: transparent !important;
                        color: #fff !important;
                        font-size: 16px !important;
                        letter-spacing: 1.4px !important;
                        border-radius: 30px !important;
                        border: 1px solid #fff !important;
                        box-shadow: none !important;
                        text-transform: uppercase;
                    }
                }

                span {
                    display: block !important;
                    margin-top: 10px !important;
                    font-size: 13px !important;

                    a {
                        color: #18a956 !important;
                    }
                }
            }

            img {
                max-width: 100% !important;
                max-height: 400px !important;
            }
        }

        .serviceSection {
            background-color: #fff !important;
            padding: 50px 0 !important;
            border: 1px solid #707070 !important;

            img {
                display: none;
            }

            .contentWrapper {
                h1 {
                    color: #fff !important;
                    font-weight: bold !important;
                    font-size: 25px;
                    text-align: center;
                    line-height: 27px !important;
                }

                .btnWrapper {
                    display: flex !important;
                    flex-direction: row !important;
                    margin-top: 15px !important;
                    padding: 0 2rem !important;

                    .getStartedBtn {
                        width: 200px !important;
                        background-color: #fff !important;
                        color: #18a956 !important;
                        font-size: 12px !important;
                        letter-spacing: 1.4px !important;
                        border-radius: 8px !important;
                        border: 0 !important;
                        height: 50px !important;
                    }

                    .logBtn {
                        width: 300px !important;
                        background-color: unset !important;
                        color: #fff !important;
                        font-size: 13px !important;
                        letter-spacing: 1.4px !important;
                        border-radius: 8px !important;
                        border: 1px solid #fff !important;
                        box-shadow: none !important;
                    }
                }
            }
        }

        .infoSection {
            .logoWrapper {
                text-align: left !important;
                margin-bottom: 10px;

                img {
                    max-width: 100px !important;
                    height: auto;
                }
            }

            .pDiv {
                p {
                    font-size: 15px !important;
                }
            }

            .row_1.address {
                display: flex !important;
                flex-direction: row !important;

                .col_1 {
                    margin-right: 40px;
                }

                h3 {
                    font-weight: bold !important;
                    font-size: 20px !important;
                }

                p {
                    font-size: 12px !important;
                }
            }
        }

        .footerSection {
            .topFooter {
                .row_1 {
                    display: flex !important;
                    flex-direction: column !important;
                }
            }

            .footerBottom {
                .row_1 {
                    display: flex !important;
                    flex-direction: column !important;
                }

                .copyright {
                    margin-top: 10px;
                }

                font-size: 13px !important;
            }
        }
    }
`;
