import { Document, Image, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import { find } from 'lodash';
import React, { useMemo } from 'react';
import Logo from '../../assets/images/logo.png';
import { Airport, Booking, Transaction, User } from '../../models/response';
import { formatDate } from '../../util/misc/formatters';

const addBorder = (width = 1, color = '#e4f5eb', style = 'solid') => ({
    borderWidth: width,
    borderColor: color,
    borderStyle: style,
});

const Pair = ({ children }: { children: React.ReactNode }) => (
    <View
        style={{
            display: 'flex',
            flexDirection: 'row',
        }}
    >
        {children}
    </View>
);

const Listing = ({ name, value, styles }: { name: string; value: any; styles?: any }) => (
    <View
        style={{
            display: 'flex',
            flexDirection: 'row',
            width: '50%',
            marginBottom: 3,
            marginRight: 3,
            padding: '10pt 15pt',
            ...addBorder(),
            ...styles,
        }}
    >
        <View
            style={{
                backgroundColor: 'white',
                width: 120,
                marginRight: 3,
            }}
        >
            <Text style={{ fontSize: 10 }}>{name.toUpperCase()}</Text>
        </View>
        <View
            style={{
                backgroundColor: 'white',
                flex: 1,
            }}
        >
            <Text style={{ fontSize: 10 }}>{'' + value}</Text>
        </View>
    </View>
);

const TableHead = ({ name, width, flex }: { name: string; width?: number; flex?: number }) => (
    <View
        style={{
            backgroundColor: 'white',
            padding: '10pt 15pt',
            width,
            borderWidth: 1,
            borderColor: '#e4f5eb',
            flex,
        }}
    >
        <Text style={{ fontSize: 10 }}>{name.toUpperCase()}</Text>
    </View>
);

const TableCell = ({ value, width, flex }: { value: number | string; width?: number; flex?: number }) => (
    <View
        style={{
            padding: '10pt 15pt',
            borderLeftWidth: 1,
            borderRightWidth: 1,
            borderLeftColor: '#e4f5eb',
            borderRightColor: '#e4f5eb',
            width,
            flex,
        }}
    >
        <Text style={{ fontSize: 10 }}>{value}</Text>
    </View>
);

interface Props {
    transaction: Transaction;
    airports: Airport[];
    details: User | Booking;
}

export const Invoice: React.FC<Props> = ({ transaction, airports, details }) => {
    const isBooking = useMemo(() => details && 'applicationUserId' in details, [details]);
    const client = isBooking ? (details as Booking).applicationUser : (details as User);
    const airport = useMemo(() => find(airports, { id: details?.airportId })?.name ?? 'N/A', [airports, details]);
    const date = new Date().getFullYear();

    const description = useMemo(() => {
        return transaction.transactType === 'Protocol Services'
            ? `${transaction.transactType} - ${(details as Booking)?.service?.name} at ${airport}`
            : transaction.transactType;
    }, [details, airport, transaction.transactType]);

    return (
        <Document>
            <Page style={styles.body}>
                <Image src={Logo} style={{ height: 21, width: 48 }} />
                <View style={styles.header}>
                    <Text style={{ fontWeight: 'bold', fontSize: 18, textAlign: 'center', marginBottom: 3 }}>
                        Federal Airports Authority of Nigeria
                    </Text>
                    {isBooking && <Text style={{ textAlign: 'center', fontSize: 14, marginBottom: 3 }}>{airport}</Text>}
                    <Text style={{ textAlign: 'center', fontSize: 14, marginBottom: 3 }}>
                        {transaction.transactType}
                    </Text>
                </View>
                <Text style={{ color: '#18A956', textAlign: 'center', fontSize: 14 }}>INVOICE</Text>
                <View
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        backgroundColor: 'white',
                        padding: '10pt 15pt',
                        marginTop: 20,
                        marginBottom: 3,
                        fontSize: 11,
                    }}
                >
                    <Text>{}</Text>
                    <Text style={{ color: '#18A956' }}>Invoice No: {transaction.id ?? 'N/A'}</Text>
                </View>
                <View>
                    <Pair>
                        <Listing styles={{ marginRight: 20 }} name="name" value={transaction.business_Name} />
                        <Listing name="revenue code" value={transaction.invoiceNo ?? 'N/A'} />
                    </Pair>
                    <Pair>
                        <Listing
                            styles={{ marginRight: 20 }}
                            name="address"
                            value={client?.registered_Address ?? 'N/A'}
                        />
                        <Listing name="invoice date" value={formatDate(transaction.dateCreated)} />
                    </Pair>
                    <Pair>
                        <Listing styles={{ marginRight: 20 }} name="phone number" value={client?.phoneNumber} />
                    </Pair>
                </View>
                <View
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        backgroundColor: 'white',
                        padding: '10pt 15pt',
                        marginRight: 3,
                        marginTop: 20,
                        marginBottom: 3,
                        fontSize: 11,
                        color: '#18A956',
                    }}
                >
                    <Text>ITEMS</Text>
                </View>
                <View
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginBottom: 3,
                    }}
                >
                    <TableHead name="S/N" width={50} />
                    <TableHead name="Description" flex={1} />
                    <TableHead name="Quantity" width={90} />
                    <TableHead name="Rate" width={70} />
                    <TableHead name="Amount" width={90} />
                </View>
                <View
                    style={{
                        width: '100%',
                        backgroundColor: 'white',
                        display: 'flex',
                        flexDirection: 'row',
                        height: 200,
                    }}
                >
                    <TableCell value="1" width={50} />
                    <TableCell value={description} flex={1} />
                    <TableCell value={transaction.quantity} width={90} />
                    <TableCell value={isBooking ? (details as Booking).service.price : 'N/A'} width={70} />
                    <TableCell value={transaction.amount} width={90} />
                </View>
                <Text style={{ marginTop: 10, fontSize: 12 }}>Please note that TSA processing fee applies.</Text>
                <Text style={{ marginTop: 10, fontSize: 8 }}>
                    &copy;Copyright {date} Airport Protocol Service. All rights reserved.
                </Text>
            </Page>
        </Document>
    );
};

// Font.register({
//     family: 'Oswald',
//     src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf',
// });

const styles = StyleSheet.create({
    body: {
        paddingTop: 35,
        paddingBottom: 65,
        paddingHorizontal: 30,
        // fontFamily: 'Oswald',
    },
    header: {
        marginBottom: 30,
    },
});
