import { FundWalletModel, WalletBalanceModel } from '../models/request';
import { Transaction, User } from '../models/response';
import { ApiClient } from '../util/api-client';
import { TransactionTypes } from '../util/constants';
import { convertCurrencyToDecimal } from '../util/misc';

export class TransactionsService {
    public static list(page?: number) {
        const query = page ? `?pageNumber=${page}` : '';

        return ApiClient.get<Transaction[]>('transactions/services' + query);
    }

    public static retrieveByTransactionId(id: Transaction['transactionId']) {
        return ApiClient.get<Transaction[]>('transactions/services/' + id);
    }

    public static retrieveTransactionTypeById(id: User['id'], type: TransactionTypes) {
        return ApiClient.get<Transaction[]>(`transactions/services-by-type/${type}/${id}`);
    }

    public static getTransactionByType(type: number, pageNumber: number) {
        return ApiClient.get<Transaction[]>(`transactions/services-by-type/${type}?PageNumber=${pageNumber}`);
    }

    public static fundWallet(model: FundWalletModel) {
        const covartedModel = convertCurrencyToDecimal(model.amount);
        model.amount = covartedModel;
        return ApiClient.post<FundWalletModel, Transaction>('users/fund-wallet', model);
    }

    public static getWalletBalance() {
        return ApiClient.get<WalletBalanceModel>('transactions/wallet-balance');
    }
}
