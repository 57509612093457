import { filter } from 'lodash';
import { SystemNotification } from '../../models/response';
import { ReduxAction } from '../common/redux-action';
import { NotificationsActionNames, NotificationsState } from './notifications.types';

const initialState: NotificationsState = {
    notifications: [],
    idCounter: 1,
};

export const notificationsReducer = (
    state = initialState,
    action: ReduxAction<NotificationsActionNames>
): NotificationsState => {
    switch (action.type) {
        case NotificationsActionNames.ADD: {
            const _state = { ...state };
            const notification = action.payload as SystemNotification;
            notification.id = _state.idCounter;
            _state.notifications.push(notification);
            _state.idCounter++;
            return _state;
        }

        case NotificationsActionNames.REMOVE: {
            const _state = { ...state };
            const id = action.payload as number;
            _state.notifications = filter(_state.notifications, (_) => _.id !== id);
            return _state;
        }

        default: {
            return state;
        }
    }
};
