import moment from 'moment';
import { AuthenticatedUser, Booking } from '../../models/response';
import { BookingStatuses, Roles } from '../constants';

const MIN_HOURS_BEFORE_CANCELLATION = 6;

export const canCancelBooking = (booking: Booking, user: AuthenticatedUser) => {
    if (
        user.role !== Roles.Client ||
        user.id !== booking.applicationUserId ||
        booking.bookingStatus !== BookingStatuses.Processing
    ) {
        return false;
    }

    const dateSection = moment(booking.date_Of_Travel);
    const timeSection = moment(booking.date_Of_Arrival);

    const cancellationCutoff = moment(
        Date.UTC(
            dateSection.year(),
            dateSection.month(),
            dateSection.date(),
            timeSection.hours(),
            timeSection.minutes()
        )
    ).subtract(MIN_HOURS_BEFORE_CANCELLATION, 'h');

    if (moment().isSameOrAfter(cancellationCutoff)) {
        return false;
    }

    return true;
};
