import styled from 'styled-components';

export const TopNavigationStyle = styled.div`
    @media only screen and (min-width: 970px) {
        display: none !important;
    }

    -webkit-box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.1);
    position: sticky !important;
    z-index: 99 !important;
    padding: 20px !important;
    top: 0;
    left: 0;
    right: 0;
    height: 8vh;
    display: block;
    background-color: #fff !important;

    nav {
        display: flex;
        flex-direction: row;

        span {
            display: block;
        }

        .nav__right {
            flex: 1;
            cursor: pointer;
        }

        .nav__image {
            flex: 1;
        }

        .nav__left {
            flex: 1;
        }
    }

    .mobile__sidebar__wrapper {
        background-color: rgb(0, 0, 0);
        background-color: rgba(0, 0, 0, 0.4);
        z-index: 9999;
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        overflow: auto;

        .mobile__sidebar {
            background-color: white;
            width: 80%;
            max-width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            padding: 3rem;
            display: flex;
            flex-direction: column;

            .navWrapper {
                margin-top: 54px;
                flex: 1;
            }

            .nav-item {
                display: grid;
                grid-template-columns: 14px 1fr;
                column-gap: 1rem;
                font-size: 14px;
                font-weight: bold;
                color: black;
                height: 56px;
                border-radius: 7px;
                padding: 0 28px;
            }

            .nav-item__icon-container,
            .nav-item__name {
                display: flex;
                align-items: center;
            }

            .nav-item__icon {
                height: 12px;
                width: 12px;
                stroke-width: 3px;
            }

            .nav-item.active {
                background: #f0f9f4;
                color: var(--primary-color);
            }

            .profile-box {
                margin-top: 5rem;

                p {
                    font-size: 13px;
                    font-weight: bold;
                }
            }

            .profile-box .profile-box__img {
                width: 30px !important;
                height: auto;
            }
        }
    }
`;
