import { Formik } from 'formik';
import React from 'react';
import { Lock } from 'react-feather';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { EntryFormContainer } from '../../../components';
import { Breadcrumb, Breadcrumbs } from '../../../components/breadcrumbs/breadcrumbs';
import { FormButton, FormTextInput } from '../../../components/form';
import Layout, { StickyBackground } from '../../../components/layout';
import { ResetPasswordModel } from '../../../models/request';
import { AuthThunk } from '../../../store/auth/auth.thunk';
import { validateFormValues } from '../../../util/validation';

export const ResetPasswordPage: React.FC = (props) => {
    const breadcrumbs: Breadcrumb[] = [
        { name: 'Home', path: '/' },
        { name: 'Reset password', path: window.location.href },
    ];

    const { code } = useParams();

    const dispatch = useDispatch();
    const handleResetPassword = React.useCallback(
        (data: ResetPasswordModel) => {
            data.ActivationCode = code as string;
            dispatch(AuthThunk.requestResetPassword(data));
        },
        [dispatch, code]
    );

    return (
        <Layout>
            <StickyBackground />
            <div className="px-5 py-3">
                <Breadcrumbs breadcrumbs={breadcrumbs} />
                <EntryFormContainer>
                    <Formik
                        validate={validateFormValues}
                        initialValues={new ResetPasswordModel()}
                        onSubmit={handleResetPassword}
                    >
                        {({ handleSubmit }) => (
                            <form onSubmit={handleSubmit} className="d-flex flex-column align-items-center">
                                <header className="form__header mb-5">
                                    <h1>Reset password</h1>
                                    <span>Enter New Password to reset your old password</span>
                                </header>
                                <main className="d-flex flex-column align-items-center mb-5">
                                    <FormTextInput
                                        className="mb-3"
                                        name="Password"
                                        placeholder="PASSWORD"
                                        type="password"
                                        icon={Lock}
                                        toggleField
                                    />
                                    <FormTextInput
                                        name="ConfirmPassword"
                                        placeholder="CONFIRM PASSWORD"
                                        type="password"
                                        icon={Lock}
                                        toggleField
                                    />
                                </main>
                                <footer className="d-flex flex-column align-items-center">
                                    <FormButton className="mb-4" type="submit">
                                        <span>RESET PASSWORD</span>
                                    </FormButton>
                                </footer>
                            </form>
                        )}
                    </Formik>
                </EntryFormContainer>
            </div>
        </Layout>
    );
};
