import { Airport } from '../../models/response';
import { ReduxAction } from '../common/redux-action';
import { AirportsActionNames } from './airports.types';

export class AirportsActions {
    public static list(airports: Airport[]): ReduxAction<AirportsActionNames.LIST> {
        return {
            type: AirportsActionNames.LIST,
            payload: airports,
        };
    }
}
