import React from 'react';
import { Activity, Bell, Grid, Home, User } from 'react-feather';
import { useDispatch } from 'react-redux';
import { AuthThunk } from '../../store/auth/auth.thunk';

interface SidebarLink {
    name: string;
    link: string;
    icon: React.FC<any>;
    inactive?: boolean;
}

export const useSidebar = () => {
    const dispatch = useDispatch();

    const handleLogout = React.useCallback(() => {
        dispatch(AuthThunk.logOut());
    }, [dispatch]);

    const links: SidebarLink[] = [
        { name: 'Dashboard', link: '/app/dashboard', icon: Home },
        {
            name: 'All Services',
            link: '/app/services',
            icon: Grid,
        },
        {
            name: 'Transactions',
            link: '/app/transaction-services',
            icon: Grid,
        },
        {
            name: 'Users Activities',
            link: '/app/activities',
            icon: Activity,
        },
        {
            name: 'Wallet',
            link: '/app/wallet',
            icon: Bell,
            inactive: true,
        },
        {
            name: 'Notifications',
            link: '/app/notifications',
            icon: Bell,
            inactive: true,
        },
        { name: 'Profile', link: '/app/profile', icon: User },
    ];

    return {
        links,
        handleLogout,
    };
};
