import React from 'react';
import { NoMatchPageStyleWrapper } from './404-page.style';

export const NoMatchPage: React.FC<{}> = (props) => {
    return (
        <NoMatchPageStyleWrapper>
            <h1>Page not found</h1>
        </NoMatchPageStyleWrapper>
    );
};
