export enum MeansOfId {
    NationalId = 'National identification card',
    NationalPassport = 'National passport',
    VoterCard = 'Voter card',
    DriversLicense = "Driver's license",
}

export const MeansOfIdList = [
    MeansOfId.NationalId,
    MeansOfId.NationalPassport,
    MeansOfId.VoterCard,
    MeansOfId.DriversLicense,
];
