import { Field, useField } from 'formik';
import React from 'react';
import { Check } from 'react-feather';
import styled from 'styled-components';

export const CheckBoxContainer = styled.label`
    display: flex;
    position: relative;
    align-items: center;
    flex-wrap: wrap;
    margin: 0;
    cursor: pointer;

    input {
        opacity: 0;
        filter: opacity(0);
        position: absolute;
        top: 0;
        left: 0;
        margin: 0;
        width: 100%;
        height: 100%;
    }

    input:hover ~ .form-checkbox__body {
        border-color: #aaa;
    }

    input:focus ~ .form-checkbox__body {
        border-color: black;
    }

    .form-checkbox__body {
        height: 25px;
        width: 25px;
        background: white;
        border: 1px solid #dadada;
        border-radius: 6px;
        opacity: 1;
        color: var(--primary-color);
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all 0.25s ease;
    }

    .form-checkbox__body.valid {
        border-color: var(--primary-color);
    }

    .form-checkbox__body.invalid {
        border-color: #e03225;
    }

    .form-checkbox__label {
        margin-left: 10px;
    }

    .form-checkbox__validation-text {
        font-size: 13px;
        padding: 5px 0;
        color: #f44336;
        width: 100%;
        display: block;
    }

    &.disabled {
        cursor: no-drop;
    }
`;

interface FormCheckBoxProps extends React.ComponentPropsWithoutRef<'input'> {
    name: string;
    disabled?: boolean;
    hideError?: boolean;
    label?: string | React.ReactNode;
}

interface PresentationalFormCheckBoxProps {
    checked: boolean | undefined;
    label?: string | React.ReactNode;
}

export const FormCheckBox: React.FC<FormCheckBoxProps> = ({ disabled, hideError, label, ...props }) => {
    const [field, meta] = useField(props);

    const validState = meta.touched ? (meta.error ? false : true) : null;
    const validationClass = validState === null ? '' : validState ? ' valid' : ' invalid';

    return (
        <CheckBoxContainer className={disabled ? 'disabled' : ''}>
            <Field type="checkbox" disabled={disabled} {...props} />
            <div className={`form-checkbox__body` + validationClass}>
                {field.value && <Check className="form-checkbox__icon" size={16} strokeWidth={3} />}
            </div>
            {label && <span className="form-checkbox__label">{label}</span>}
            {!hideError && validState === false && <span className="form-checkbox__validation-text">{meta.error}</span>}
        </CheckBoxContainer>
    );
};

export const PresentationalFormCheckBox: React.FC<PresentationalFormCheckBoxProps> = ({ checked }) => {
    return (
        <CheckBoxContainer>
            <input type="checkbox" defaultChecked={checked} disabled />
            {checked && <Check className="check" />}
        </CheckBoxContainer>
    );
};
