import React from 'react';
import styled from 'styled-components';

interface FormStepperProps {
    steps: number;
    currentStep: number;
}

export const Div = styled.div`
    .form-stepper {
        background: #dedede;
        height: 4px;
        width: 113px;
        display: flex;
    }

    .form-stepper__step {
        flex: 1;
        height: 100%;
        background: transparent;
    }

    .form-stepper__step--active {
        background: var(--primary-color);
    }
`;

export const FormStepper: React.FC<FormStepperProps> = ({ steps, currentStep = 1 }) => {
    const stepsActivated: boolean[] = React.useMemo(() => {
        return new Array(steps).fill(false).map((_, index) => index + 1 <= currentStep);
    }, [steps, currentStep]);

    return (
        <Div>
            <span className="form-stepper">
                {stepsActivated.map((isActivated, i) => (
                    <span
                        className={'form-stepper__step' + (isActivated ? ' form-stepper__step--active' : '')}
                        key={i}
                    />
                ))}
            </span>
        </Div>
    );
};
