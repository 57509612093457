import { flatten } from 'lodash';
import { NotificationTypes } from '../../util/constants';

export type AcceptableNotificationContentTypes =
    | string
    | string[]
    | Record<string, string>
    | Record<string, string[]>
    | undefined;

export class SystemNotification {
    public id!: number;
    public content: string[];

    constructor(public type: NotificationTypes, content: AcceptableNotificationContentTypes, public sticky?: boolean) {
        if (!content) {
            this.content = [];
            return;
        }

        if (typeof content === 'string') {
            this.content = [content];
            return;
        }

        if (Array.isArray(content)) {
            this.content = content;
            return;
        }

        this.content = flatten(Object.values(content)).map((message) => {
            return message;
        });
    }
}
