import { SystemNotification } from '../../models/response';
import { ReduxAction } from '../common/redux-action';
import { NotificationsActionNames } from './notifications.types';

export class NotificationsActions {
    public static add(notification: SystemNotification): ReduxAction<NotificationsActionNames.ADD, SystemNotification> {
        return {
            type: NotificationsActionNames.ADD,
            payload: notification,
        };
    }

    public static remove(id: number): ReduxAction<NotificationsActionNames.REMOVE, number> {
        return {
            type: NotificationsActionNames.REMOVE,
            payload: id,
        };
    }
}
