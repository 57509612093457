import { Equals, IsEnum, IsIn, IsString, MinLength, ValidateIf } from 'class-validator';
import { GovernmentStaffTypes, NigerianStates } from '../../../util/constants';
import { BaseModel } from '../base-model';
import { CommonRegistrationModel } from './common-registration-model';

export class CreateGovernmentOfficialModel extends CommonRegistrationModel implements BaseModel {
    @IsString()
    @MinLength(3, {
        message: 'Must be at least $constraint1 characters long',
    })
    public Business_Name: string = '';

    @IsString()
    @MinLength(3, {
        message: 'Must be at least $constraint1 characters long',
    })
    public Name_Of_Government_Officials_Office: string = '';

    @IsEnum(GovernmentStaffTypes, { message: 'Invalid selection' })
    public GovernmentStaffType?: string = '';

    @Equals(true, { message: 'You must accept the terms and conditions' })
    public Accept?: boolean = false;

    @IsIn(NigerianStates, { message: 'Invalid selection' })
    @ValidateIf((o: CreateGovernmentOfficialModel) => o.GovernmentStaffType === GovernmentStaffTypes.State)
    public State?: string = '';

    public beforeValidation() {
        super.beforeValidation();
        this.Business_Name = this.Name_Of_Government_Officials_Office;
    }

    public finalize() {
        if (this.GovernmentStaffType === GovernmentStaffTypes.Federal) {
            delete this.State;
        }

        delete this.GovernmentStaffType;
    }
}
