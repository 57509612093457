import styled from 'styled-components';

export const FlagTimeStyleWrapper = styled.div`
    display: flex;
    align-items: center;

    img {
        max-width: 60px;
        height: auto;
    }

    font-weight: bold;
    font-size: 14px;

    @media only screen and (max-width: 600px) {
        display: none;
    }
`;
