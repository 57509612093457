import {
    ChangeUserStatusModel,
    EditUserModel,
    EditUserProfileModel,
    UpdateClientModel,
    UpdateCorporateClientModel,
    UpdateGovernmentOfficialModel,
    UpdateIndividualClientModel,
} from '../models/request';
import { BookingServicesStat, ClientStats, User, UserActivity } from '../models/response';
import { ApiClient } from '../util/api-client';
import { ClientStatuses } from '../util/constants';

export class UsersService {
    public static async getDashboardStats() {
        return ApiClient.get<any>('users/dashboard-stats');
    }

    public static async createGovernmentOfficial(userDetails: FormData) {
        return ApiClient.post<FormData, User>('users/government', userDetails, true);
    }

    public static async createCorporateOrganization(userDetails: FormData) {
        return ApiClient.post<FormData, User>('users/corporate', userDetails, true);
    }

    public static async createIndividual(userDetails: FormData) {
        return ApiClient.post<FormData, User>('users/individual', userDetails, true);
    }

    public static async getClients(filter?: ClientStatuses, page?: number) {
        const query = page ? `?pageNumber=${page}` : '';
        const endpoint = filter ? `clientByStatus/${filter}` : 'clients';

        return ApiClient.get<User[]>('users/' + endpoint + query);
    }

    public static async getClientBookingStats() {
        return ApiClient.get<BookingServicesStat>('booking-services/stats');
    }

    public static async getClientStats() {
        return ApiClient.get<ClientStats>('users/status-stats');
    }

    public static async getUsers(status?: boolean, page?: number) {
        const query = page ? `?pageNumber=${page}` : '';

        return ApiClient.get<User[]>(`users/${status === undefined ? 'users' : status}` + query);
    }

    public static async changeUserStatus(userDetails: ChangeUserStatusModel) {
        return ApiClient.patch<ChangeUserStatusModel, User>('users/activation', userDetails);
    }

    public static async viewUser(user_id: string | undefined) {
        return ApiClient.get<[]>(`users/users/${user_id}`);
    }

    public static async getUserBooking(user_id: string | undefined) {
        return ApiClient.get<[]>(`booking-services/client/${user_id}`);
    }

    public static async updateUser(data: EditUserModel) {
        return ApiClient.patch<EditUserModel, User>('users/user', data);
    }

    public static async updateUserProfile(data: EditUserProfileModel) {
        return ApiClient.patch<EditUserProfileModel, User>('users/faan-user', data);
    }

    public static async updateClient(data: UpdateClientModel, type?: number) {
        return ApiClient.patch<UpdateClientModel, User>(`${type === 1 ? 'users/corporate' : 'users/government'}`, data);
    }

    public static async updateIndividualClient(data: UpdateIndividualClientModel) {
        return ApiClient.patch<UpdateIndividualClientModel, User>('users/individual', data);
    }

    public static async updatCorporateCient(data: UpdateCorporateClientModel) {
        return ApiClient.patch<UpdateCorporateClientModel, User>('users/corporate', data);
    }

    public static async updatGovernmentCient(data: UpdateGovernmentOfficialModel) {
        return ApiClient.patch<UpdateGovernmentOfficialModel, User>('users/government', data);
    }

    public static async userActivities() {
        return ApiClient.get<UserActivity[]>(`activity/client`);
    }
}
