import { capitalize, orderBy } from 'lodash';
import moment from 'moment';
import React from 'react';
import { MoreVertical } from 'react-feather';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Cell } from 'react-table';
import { CustomDropdown, CustomPrimaryButton, DataTable } from '../../../components';
import { UpdateBookingModel } from '../../../models/request';
import { Transaction } from '../../../models/response';
import { BookingsThunk } from '../../../store/bookings/bookings.thunk';
import { ApiResponse } from '../../../util/api-client';

interface Props {
    transactions: Transaction[];
    compact?: boolean;
    refreshFunction?: (...args: any[]) => any;
    meta?: ApiResponse<Transaction[]>['meta'];
}

export const RegistrationTransactionsTable: React.FC<Props> = ({ transactions, meta, refreshFunction }) => {
    const dispatch = useDispatch();

    const handleUpdateBooking = React.useCallback(
        (bookingDetails: UpdateBookingModel) => {
            dispatch(BookingsThunk.update(bookingDetails, refreshFunction));
        },
        [dispatch, refreshFunction]
    );

    return (
        <DataTable
            columns={[
                {
                    Header: 'Date',
                    Cell: ({ row: { original } }: Cell<Transaction>) => (
                        <span>{moment(original.dateCreated).format('Do MMMM, YYYY,  h:mm:ss a')}</span>
                    ),
                },
                {
                    Header: 'Services',
                    accessor: 'transactType' as any,
                    Cell: ({ row: { original } }: Cell<Transaction>) => <span>{original.transactType}</span>,
                },
                {
                    Header: 'Amount',
                    accessor: 'amount' as any,
                    Cell: ({ row: { original } }: Cell<Transaction>) => (
                        <span>NGN {original.amount.toLocaleString()}</span>
                    ),
                },
                // {
                //     Header: 'Invoice Number',
                //     accessor: 'invoiceNo',
                //     Cell: ({ row: { original } }: Cell<Transaction>) => {
                //         return (
                //             <span style={{ color: 'var(--primary-color)' }}>
                //                 {original.invoiceNo ?? 'Processing...'}
                //             </span>
                //         );
                //     },
                // },
                // {
                //     Header: 'RRR Number',
                //     accessor: 'rrrNo' as any,
                //     Cell: ({ row: { original } }: Cell<Transaction>) => <span>{original.rrrNo ?? 'N/A'}</span>,
                // },
                // {
                //     Header: 'Status',
                //     accessor: 'paymentStatus',
                //     Cell: (cell: Cell<Transaction>) => <span>{cell.row.original.paymentStatus ?? 'Unpaid'}</span>,
                // },
                // {
                //     Header: 'Actions',
                //     Cell: ({ row: { original: transaction } }: Cell<Transaction>) => {
                //         return (
                //             <React.Fragment>
                //                 <CustomDropdown
                //                     className="mr-2"
                //                     triggerComponent={() => {
                //                         return (
                //                             <CustomPrimaryButton className="small">
                //                                 <MoreVertical size={12} />
                //                             </CustomPrimaryButton>
                //                         );
                //                     }}
                //                 >
                //                     <CustomPrimaryButton
                //                         as={Link}
                //                         className="small inverted"
                //                         // to={`/app/transacstion-service/${transaction.transactionId}`}
                //                         to={`/app/transaction-service/${transaction.transactionId}?type=${transaction.transactionType}`}
                //                     >
                //                         View service
                //                     </CustomPrimaryButton>
                //                 </CustomDropdown>
                //             </React.Fragment>
                //         );
                //     },
                // },
            ]}
            data={orderBy(transactions, (_) => new Date(_.dateCreated), 'desc')}
            meta={meta}
        />
    );
};
