import { useField } from 'formik';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import styled from 'styled-components';
import { FormTextInputContainer, FormTextInputProps, SpecialInput } from './form-text-input';

interface DatePickerProps extends FormTextInputProps {
    onBlur?: any;
    minDate?: string | Date;
    maxDate?: string | Date;
    isTime?: boolean;
    onChange?: (...args: any[]) => void;
}

enum Colors {
    Primary = 'var(--primary-color)',
    LightGrey = 'white',
    DarkGrey = '#111',
}

const DatePickerContainer = styled(FormTextInputContainer)`
    font-size: inherit;

    .react-datepicker-wrapper {
        flex: 1;
    }

    .react-datepicker {
        color: #565050;
        background: #ffffffee;
        backdrop-filter: blur(10px);
        border: 1px solid #dadada;
        border-radius: 0;
        position: unset;
    }

    .react-datepicker__header {
        background: ${Colors.LightGrey};
        border-radius: 0;
        padding-top: 15px;
        border: none;
    }

    .react-datepicker__input-container {
        width: inherit;
    }

    .react-datepicker__current-month,
    .react-datepicker-time__header,
    .react-datepicker-year-header {
        margin-bottom: 10px;
        color: ${Colors.Primary};
        font-weight: bold;
        font-size: 1em;
    }

    .react-datepicker__day-names {
        white-space: nowrap;
        padding: 5px 20px;
    }

    .react-datepicker__week {
        white-space: nowrap;
        padding: 0 20px;
    }

    .react-datepicker__day,
    .react-datepicker__day-name {
        margin: 0;
        font-size: 80%;
    }

    .react-datepicker__day {
        font-weight: bold;
    }

    .react-datepicker__day--outside-month {
        color: ${Colors.DarkGrey};
        background: #f7f7f7;
    }

    .react-datepicker__day:hover,
    .react-datepicker__month-text:hover,
    .react-datepicker__quarter-text:hover {
        border-radius: 0;
        background: ${Colors.LightGrey};
    }

    .react-datepicker__day--selected,
    .react-datepicker__day--keyboard-selected,
    .react-datepicker__month-text--keyboard-selected,
    .react-datepicker__quarter-text--keyboard-selected {
        border-radius: 0;
        background-color: ${Colors.Primary} !important;
        color: white;
    }

    .react-datepicker__triangle {
        display: none;
    }

    .react-datepicker__month {
        margin: 0;
        padding: 20px 0;
    }

    .react-datepicker__day--disabled {
        background: ${Colors.LightGrey} !important;
        color: ${Colors.DarkGrey} !important;
        cursor: not-allowed;
        font-weight: normal;
    }

    .react-datepicker__year-dropdown,
    .react-datepicker__month-dropdown,
    .react-datepicker__month-year-dropdown {
        background-color: white;
        position: absolute;
        border-radius: 0;
        border: 2px solid ${Colors.LightGrey};
    }

    .react-datepicker__year-select,
    .react-datepicker__month-select {
        height: 30px;
        padding: 5px;
        border: none;
    }

    .react-datepicker__year-dropdown-container--select,
    .react-datepicker__month-dropdown-container--select,
    .react-datepicker__month-year-dropdown-container--select,
    .react-datepicker__year-dropdown-container--scroll,
    .react-datepicker__month-dropdown-container--scroll,
    .react-datepicker__month-year-dropdown-container--scroll {
        display: inline-block;
        margin: 0 5px;
    }

    .react-datepicker__time-container
        .react-datepicker__time
        .react-datepicker__time-box
        ul.react-datepicker__time-list
        li.react-datepicker__time-list-item--selected {
        background: var(--primary-color);
    }
`;

const CustomInput = React.forwardRef((props: any, ref: any) => {
    const date = moment(props.value);

    return (
        <SpecialInput
            ref={ref}
            {...props}
            // autoComplete="password"
            value={date.isValid() ? date.format(props.isTime ? 'hh:mm (A)' : 'LL') : ''}
        />
    );
});

export const FormDatePicker: React.FC<DatePickerProps> = ({
    label,
    minDate,
    maxDate,
    onChange,
    compact,
    icon: Icon,
    isTime,
    ...props
}) => {
    const [field, meta, helpers] = useField({ name: props.name });
    const [currentDate, setCurrentDate] = useState<Date | string | null | undefined>(field.value);
    const className = compact ? 'compact ' + (props.className || '') : props.className;

    const validState = meta.touched ? (meta.error ? false : true) : null;
    const validationClass = validState === null ? '' : validState ? ' valid' : ' invalid';

    const ref = React.useRef<HTMLInputElement>(null);
    const [isFocused, setIsFocused] = React.useState(false);
    const focusClass = React.useMemo(() => (isFocused ? ' focus' : ''), [isFocused]);

    React.useEffect(() => {
        if (ref.current) {
            ref.current.onfocus = () => {
                setIsFocused(true);
            };

            ref.current.onblur = () => {
                setIsFocused(false);
            };
        }
    }, [ref]);

    const options = isTime
        ? {
              showTimeSelect: true,
              showTimeSelectOnly: true,
              timeIntervals: 15,
              timeCaption: 'Time',
              dateFormat: 'hh:mm aa',
              selected: currentDate ? moment(currentDate).toDate() : new Date(),
          }
        : {
              minDate: minDate ? moment(minDate).toDate() : null,
              maxDate: maxDate ? moment(maxDate).toDate() : null,
              dateFormat: 'LLLL',
              openToDate: field.value ? new Date(field.value) : new Date(),
          };

    useEffect(() => {
        const formattedDate = currentDate ? (isTime ? currentDate : moment(currentDate).format('YYYY-MM-DD')) : '';

        field.onChange(field.name)(formattedDate as any);

        if (onChange && formattedDate) {
            onChange(formattedDate);
        }
        // eslint-disable-next-line
    }, [currentDate]);

    return (
        <DatePickerContainer className={(props.disabled ? 'disabled ' : '') + className}>
            {label && <span className="form-text__label">{label}</span>}
            <div className={`form-text__body` + validationClass + focusClass} id={props.name}>
                {Icon ? (
                    <section className="form-text__icon-container">
                        <Icon className="form-text__icon" />
                    </section>
                ) : null}
                <ReactDatePicker
                    showMonthDropdown
                    showYearDropdown
                    className="form-text__input"
                    dropdownMode="select"
                    customInput={<CustomInput isTime={isTime} ref={ref} />}
                    {...field}
                    onSelect={(date) => {
                        helpers.setTouched(true);
                    }}
                    onChange={(date, e) => {
                        const value = isTime
                            ? moment(date?.toString()).format('YYYY-MM-DDTHH:mm:ss.sssZ')
                            : (date as Date);
                        setCurrentDate(value);
                    }}
                    {...props}
                    placeholderText={props.placeholder || 'ENTER ' + (isTime ? 'TIME' : 'DATE')}
                    {...options}
                />
            </div>
            {validState === false ? (
                <React.Fragment>
                    <span className="form-text__validation-text">{meta.error}</span>
                </React.Fragment>
            ) : null}
        </DatePickerContainer>
    );
};
