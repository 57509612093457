export enum Airports {
    ABV = 1,
    YOL,
    QUO,
    QOW,
    MIU,
    IBA,
    CBQ,
    BNI,
    BCU,
    JOS,
    SKO,
    PHC,
    LOS,
    KAN,
    KAD,
    ILR,
    ENU,
    ABB,
}

export const AirportsMap = {
    [Airports.ABV]: 'Nnamdi Azikiwe International Airport',
    [Airports.YOL]: 'Yola Airport',
    [Airports.QUO]: 'Akwa Ibom International Airport',
    [Airports.QOW]: 'Sam Mbakwe International Cargo Airport',
    [Airports.MIU]: 'Maiduguri International Airport',
    [Airports.IBA]: 'Ibadan Airport',
    [Airports.CBQ]: 'Margaret Ekpo International Airport',
    [Airports.BNI]: 'Benin Airport',
    [Airports.BCU]: 'Sir Abubakar Tafawa Balewa Airport',
    [Airports.JOS]: 'Yakubu Gowon Airport',
    [Airports.SKO]: 'Sadiq Abubakar III International Airport',
    [Airports.PHC]: 'Port Harcourt International Airport',
    [Airports.LOS]: 'Murtala Muhammed International Airport',
    [Airports.KAN]: 'Mallam Aminu Kano International Airport',
    [Airports.KAD]: 'Kaduna International Airport',
    [Airports.ILR]: 'Ilorin International Airport',
    [Airports.ENU]: 'Akanu Ibiam International Airport',
    [Airports.ABB]: 'Asaba International Airport',
};
