import React from 'react';
import { renderToString } from 'react-dom/server';
import { FilePond, FilePondProps } from 'react-filepond';
import styled from 'styled-components';

const LabelIdle = renderToString(
    <React.Fragment>
        <img src={require('../../assets/images/folder.svg')} className="file-upload__icon" alt="File icon" />
        <span className="file-upload__header">Drop your files here or click to upload</span>
        <span className="file-upload__tagline">Upload CAC certificate and other support documents</span>
    </React.Fragment>
);

export const FormFileUpload = styled(FilePond).attrs(() => {
    return {
        labelIdle: LabelIdle,
        allowRevert: false,
        instantUpload: false,
        onprocessfile: (error, file) => {
            file.abortLoad();
        },
    } as FilePondProps;
})`
    width: 600px;
    font: inherit;

    .filepond--drop-label {
        background: white;
        padding: 40px;
        height: 170px;
        border: 2px dashed #b4b4b4;
        border-radius: 13px;

        label {
            display: flex;
            flex-direction: column;
            color: black;
        }
    }

    .filepond--list {
        margin-top: 40px;
    }

    .filepond--list-scroller {
        margin-left: 80px;
        margin-right: 80px;
    }

    .filepond--panel-root {
        background: transparent;
    }

    .file-upload__icon {
        margin: auto;
        margin-bottom: 15px;
        width: 30px;
    }

    .file-upload__header {
        font-size: 18px;
        font-weight: bold;
    }

    .file-upload__tagline {
        font-size: 14px;
    }
`;
