import React from 'react';
import { LogOut } from 'react-feather';
import { useDispatch } from 'react-redux';
import { AuthThunk } from '../../../store/auth/auth.thunk';
import { SignoutStyleWrapper } from './sign-out-switch.style';

export const SignOutSwitchComponent: React.FC<{}> = () => {
    const dispatch = useDispatch();

    const handleLogout = React.useCallback(() => {
        dispatch(AuthThunk.logOut());
    }, [dispatch]);

    return (
        <SignoutStyleWrapper>
            <div className="wrapper" onClick={handleLogout}>
                <div className="content">
                    <span>Logout</span>
                </div>
                <div className="box">
                    <div className="divCircle">
                        <div>
                            <LogOut size={13} strokeWidth={3} />
                        </div>
                    </div>
                </div>
            </div>
        </SignoutStyleWrapper>
    );
};
