import styled from 'styled-components';

export const DashboardPageStyleWrapper = styled.div`
    @media only screen and (min-width: 970px) {
        .top__content {
            margin-top: 2rem !important;
        }

        .icon-row {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            overflow: auto;
            padding: 0 30px;
        }

        .icon-row__col {
            margin-right: 10px;
            cursor: pointer;
        }

        .icon-row__item {
            padding: 15px 0px;
            border: 1px solid #dadada;
            border-radius: 7px;
            display: flex;
            flex-direction: row;

            .oneline__text {
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                flex: 1;
            }
        }

        .mobile__head {
            padding: 20px;

            h3 {
                font-size: 22px;
                font-weight: bold;
            }
        }

        .deck {
            margin-top: 1rem;
        }

        .recentActivity {
            margin-top: 1rem;

            h1 {
                font-size: 30px;
                font-weight: bold;
            }

            p {
                a {
                    color: #18a956;
                    font-weight: bold;
                }
            }
        }
    }

    @media only screen and (max-width: 970px) {
        .icon-row {
            padding: 0 30px;
        }

        .mobile__head {
            padding: 0 20px;
            margin-top: 30px;
            margin-bottom: 10px;

            h3 {
                margin: 0;
                font-size: 20px;
                font-weight: bold !important;
            }
        }

        .icon-row__col {
            margin-right: 10px;
            cursor: pointer;
        }

        .icon-row__item {
            padding: 15px 0px;
            border: 1px solid #dadada;
            border-radius: 7px;
            display: flex;
            flex-direction: row;
        }
    }

    .recentActivity {
        padding: 0 20px;

        h1 {
            font-size: 20px;
            font-weight: bold;
        }

        p {
            a {
                color: #18a956;
                font-weight: bold;
            }
        }
    }

    .top__content {
        margin-top: 30px;
        padding: 0 20px;
        display: flex !important;

        .flag,
        .switch {
            flex: 1;
        }
    }

    .deck {
        padding: 20px;

        .deck__row {
            display: flex !important;
            flex-direction: row;
            align-items: flex-start;
            overflow: auto;
            white-space: nowrap;
        }

        .deck__row__item {
            flex: 1;
            cursor: pointer;
            margin-right: 10px;
        }
    }
`;
