import { IsEnum, IsIn, IsString, Matches, MinLength } from 'class-validator';
import { BusinessNatures, ClientTypes } from '../../../util/constants';
import { ConvertToNumber, IsMobileNumber } from '../../../util/validation';
import { User } from '../../response';
import { BaseModel } from '../base-model';

export class UpdateClientModel implements BaseModel {
    @IsString({ message: 'Invalid id' })
    public id: string = '';

    @Matches(/^[^\s].*[^\s]$/, { message: 'Cannot begin or end with a space' })
    public business_Name: string = '';

    @IsIn(BusinessNatures, { message: 'Invalid selection' })
    public business_Nature: string = '';

    @MinLength(3, {
        message: 'Must be at least $constraint1 characters long',
    })
    public registered_Address: string = '';

    @MinLength(3, {
        message: 'Must be at least $constraint1 characters long',
    })
    public registration_Number: string = '';

    @IsMobileNumber({ message: 'Invalid phone number format' })
    public phoneNumber: string = '';

    @ConvertToNumber()
    @IsEnum(ClientTypes, { message: 'Invalid selection' })
    public clientTypeId: number = ClientTypes.Domestic;

    constructor(client: User) {
        if (client) {
            this.id = client.id;
            this.business_Name = client.business_Name;
            this.business_Nature = client.business_Nature;
            this.registered_Address = client.registered_Address;
            this.registration_Number = client.registration_Number;
            this.phoneNumber = client.phoneNumber;
            this.clientTypeId = client.clientTypeId;
            // this.creation_Year = client.creation_Year;
            return;
        }
    }

    public beforeValidation() {
        this.clientTypeId = +this.clientTypeId;
    }
}
