import { capitalize } from 'lodash';
import moment from 'moment';
import React, { useState } from 'react';
import { Clipboard } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Cell } from 'react-table';
import { AuthLayout, DataTable, FlagTime, ServiceCardComponent } from '../../../components';
import { UserActivity } from '../../../models/response';
import { ApplicationState } from '../../../store';
import { ServiceThunk } from '../../../store/services/services.thunk';
import { UsersThunk } from '../../../store/users/users.thunk';
import { formatCurrency } from '../../../util/misc';
import { DashboardPageStyleWrapper } from '../components/dashboard-page.style';
import { FeedackPopUp } from '../../../components/client-feedback/feedback-pop-up';
import { FeedbackFormValues } from '../../../components/client-feedback/validate-feedback';
import { FeedbackThunk } from '../../../store/feedback';

export interface TopCardLink {
    name: string;
    icon: React.FC<any>;
    count: number;
    color?: string;
}

export const ClientDashboardPage: React.FC<{}> = () => {
    const dispatch = useDispatch();
    const { services, users } = useSelector((state: ApplicationState) => state);
    const activities = (users.userActivities && users.userActivities) || [];
    const latest_activities = activities
        .sort((a, b) => Date.parse(b.dateCreated) - Date.parse(a.dateCreated))
        .slice(0, 5);
    const clientStat = users?.clientBookingStats;

    const cards: TopCardLink[] = [
        {
            name: 'Bookings',
            icon: Clipboard,
            count: clientStat?.booking ?? 0,
            color: '#18A956',
        },
        {
            name: 'Service Bookings',
            icon: Clipboard,
            count: clientStat?.serviceBookings ?? 0,
            color: '#B1B71C',
        },
        {
            name: 'Processing Bookings',
            icon: Clipboard,
            count: clientStat?.processing ?? 0,
            color: '#B1B71C',
        },
        {
            name: 'No-Show Bookings',
            icon: Clipboard,
            count: clientStat?.noShow ?? 0,
            color: '#ED0000',
        },
    ];

    const [feedbackOpen, setFeedbackOpen] = useState(false);
    const airportID = localStorage.getItem('airportId');

    const handleSubmitFeedback = React.useCallback(
        async (feedback: FeedbackFormValues) => {
            feedback.airportId = +(airportID || '');
            dispatch(FeedbackThunk.clientFeedback(feedback));
            localStorage.removeItem('showFeedbackForm');
            localStorage.removeItem('airportId');
            setFeedbackOpen(false);
        },
        [dispatch]
    );

    React.useEffect(() => {
        const showFeedback = localStorage.getItem('showFeedbackForm');
        if (showFeedback === 'true') {
            setFeedbackOpen(true);
        }
    }, []);

    const handleFeedbackClose = () => {
        setFeedbackOpen(false);
        localStorage.removeItem('showFeedbackForm');
    };

    React.useEffect(() => {
        dispatch(UsersThunk.userActivities());
        dispatch(ServiceThunk.getAllServices());
        dispatch(UsersThunk.getClientBookingStats());
    }, [dispatch]);

    return (
        <AuthLayout showSidebar>
            <DashboardPageStyleWrapper>
                {feedbackOpen && (
                    <FeedackPopUp
                        isOpen={feedbackOpen}
                        toggle={handleFeedbackClose}
                        handleSubmitFeedback={handleSubmitFeedback}
                    />
                )}
                <div>
                    <div className="mobile__head">
                        <FlagTime />
                        <h3>How may we help you today?</h3>
                    </div>

                    <div className="icon-row row">
                        {cards?.map(({ name, icon: Icon, count, color }, i) => (
                            <div className="icon-row__col mb-3 col" key={i}>
                                <div className="icon-row__item row">
                                    <div className="d-flex justify-content-start col-md-9 pt-1">
                                        <Icon size={20} className="mr-2" style={{ color }} />
                                        <span className="oneline__text">{name}</span>
                                    </div>
                                    <div className="d-flex flex-row-reverse text-right col-md-3">
                                        <span style={{ fontSize: '20px', fontWeight: 'bold' }}>{count}</span>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="w-100"></div>
                <div className="deck">
                    <div className="deck__row">
                        {services.services &&
                            services.services.slice(0, 2).map((service, i) => (
                                <div className="deck__row__item" key={i}>
                                    <ServiceCardComponent
                                        title={service.name}
                                        to={`/app/service/${service.id}/create-booking`}
                                        desc={service.description}
                                        price={formatCurrency(service.price)}
                                    />
                                </div>
                            ))}
                    </div>
                </div>
                <div className="recentActivity">
                    <div className="d-flex">
                        <div className="d-flex justify-content-start">
                            <h1>Recent Activities</h1>
                        </div>
                        <div style={{ flex: 1 }} className="d-flex flex-row-reverse">
                            <p>
                                <Link to="/app/activities">
                                    View all <i className="fa fa-long-arrow-right" aria-hidden="true"></i>
                                </Link>
                            </p>
                        </div>
                    </div>
                    <div className="activity">
                        <DataTable
                            hideHead
                            columns={[
                                {
                                    accessor: 'dateCreated',
                                    Cell: ({ row: { original } }: Cell<UserActivity>) => {
                                        return <b>{moment(original.dateCreated).format('Do MMMM, YYYY, h:mm:ss a')}</b>;
                                    },
                                },
                                {
                                    accessor: 'description',
                                },
                            ]}
                            data={latest_activities}
                        />
                    </div>
                </div>
            </DashboardPageStyleWrapper>
        </AuthLayout>
    );
};
