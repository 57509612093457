import { motion } from 'framer-motion';
import React, { Dispatch, SetStateAction } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import styled from 'styled-components';
import { Transaction } from '../../models/response';

const PageWrapper = styled(motion.div)`
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #f0f0f0;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    min-height: 150px;
    padding: 0.5em 1em;

    .header {
        color: #a1a1a1 !important;
        font-size: 28px;
        font-weight: 700;

        &.small {
            font-size: 20px;
            color: black !important;
        }
    }

    .content {
        display: flex;
        align-items: center;
        padding: 0.4em 2em;
        justify-content: space-between;

        .link {
            color: var(--primary-color);
            font-weight: 600;
            font-size: 20px;
            align-self: flex-start;
            transition: opacity 0.4s ease-out;

            &:hover {
                opacity: 0.85;
            }
        }
    }
`;

interface Props {
    walletBalance: number;
    setFundingModal: Dispatch<SetStateAction<boolean>>;
    transactions?: Transaction[];
}

export const MobileWallet: React.FC<Props> = ({ walletBalance, setFundingModal }) => {
    return (
        <PageWrapper>
            <h2 className="header">Wallet Balance</h2>

            <div className="content">
                <div>
                    <div className="header small" style={{ color: 'initial' }}>
                        &#8358;&nbsp;{walletBalance.toLocaleString()}
                    </div>

                    <Button color="success" size="md" className="btn-btn mt-3" onClick={() => setFundingModal(true)}>
                        <span>Fund</span>
                    </Button>
                </div>

                <Link className="link" to="/app/transaction-services">
                    View Transactions
                </Link>
            </div>
        </PageWrapper>
    );
};
