import { Dispatch } from 'redux';
import { AirportsService } from '../../services/airports.service';
import { NotificationTypes } from '../../util/constants';
import { generateLoader } from '../../util/misc';
import { NotificationsThunk } from '../notifications/notifications.thunk';
import { AirportsActions } from './airports.actions';

export class AirportsThunk {
    public static list() {
        return async (dispatch: Dispatch) => {
            const response = await generateLoader(AirportsService.list(), dispatch, 'Fetching airports');

            if (response.data) {
                dispatch(AirportsActions.list(response.data));
                return;
            }

            NotificationsThunk.create(NotificationTypes.Error, 'Failed to fetch airports')(dispatch);
        };
    }
}
