import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, TabContent, TabPane } from 'reactstrap';
import { AppLayout, AuthLayout, Breadcrumb, TabLink, TopBar } from '../../../../components';
import { ApplicationState } from '../../../../store';
import { UsersThunk } from '../../../../store/users/users.thunk';
import { ActivitiesTable } from './user-activities-table';

export const UserActivitiesPage: React.FC = () => {
    const breadcrumbs: Breadcrumb[] = [
        { name: 'Dashboard', path: '/app/dashboard' },
        { name: 'All Activities', path: window.location.href },
    ];

    const links: TabLink[] = [{ index: 1, name: 'All Activities' }];
    const [activeTab, setActiveTab] = React.useState(1);

    const { users } = useSelector((state: ApplicationState) => state);
    const activites = users && users.userActivities;
    const dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(UsersThunk.userActivities());
    }, [dispatch]);

    return (
        <AuthLayout showSidebar={false}>
            <TopBar breadcrumbs={breadcrumbs} />
            <AppLayout.Wrapper>
                <AppLayout.Header>
                    <Col lg="8">
                        <h3 className="header__title">All Activities</h3>
                    </Col>
                </AppLayout.Header>
                <AppLayout.Body>
                    <AppLayout.TabHeader links={links} activeTab={activeTab} setActiveTab={setActiveTab} />
                    <AppLayout.TabBody>
                        <TabContent activeTab={activeTab} className="tab__content">
                            <TabPane tabId={1}>
                                {activites && activites.length ? (
                                    <ActivitiesTable activities={activites} />
                                ) : (
                                    <p>There are no activities</p>
                                )}
                            </TabPane>
                        </TabContent>
                    </AppLayout.TabBody>
                </AppLayout.Body>
            </AppLayout.Wrapper>
        </AuthLayout>
    );
};
