import styled from 'styled-components';

export const SidebarMenuStyleWrapper = styled.div`
    width: 300px;
    min-height: 100vh;
    background: #ffffff 0% 0% no-repeat padding-box;
    position: fixed;
    color: white;
    padding: 3rem;
    font-weight: normal;
    line-height: 2rem;
    border: 1px solid #dddddd;
    display: flex;
    flex-direction: column;

    img {
        max-width: 100px;
        height: auto;
    }

    .navWrapper {
        margin-top: 54px;
        flex: 1;
    }

    .nav-item {
        display: grid;
        grid-template-columns: 14px 1fr;
        column-gap: 1rem;
        font-size: 14px;
        font-weight: bold;
        color: black;
        height: 56px;
        border-radius: 7px;
        padding: 0 28px;
    }

    .nav-item__icon-container,
    .nav-item__name {
        display: flex;
        align-items: center;
    }

    .nav-item__icon {
        height: 12px;
        width: 12px;
        stroke-width: 3px;
    }

    .nav-item.active {
        background: #f0f9f4;
        color: var(--primary-color);
    }

    .contentBox {
        color: #000;
        font-size: 12px;
    }

    .profile-box {
        margin-top: 5rem;

        p {
            font-size: 13px;
            font-weight: bold;
        }
    }

    .profile-box .profile-box__img {
        width: 30px !important;
        height: auto;
    }

    .box {
        flex: 0.3;
        cursor: pointer;

        .divCircle {
            height: 30px;
            width: 30px;
            border-radius: 100%;
            background-color: #eafaf1;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            svg {
                color: #18a956;
                stroke-width: 3px;
            }
        }
    }

    @media only screen and (max-width: 970px) {
        display: none !important;
    }
`;
