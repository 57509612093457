export enum Airlines {
    A_A_RANO = 'A. A RANO',
    AERO_CONTRACTORS = 'AERO CONTRACTORS',
    AFRIQIYAH = 'AFRIQIYAH',
    AFRICA_WORLD_AIRLINES = 'AFRICA WORLD AIRLINES',
    AIR_CÔTE_D_IVOIRE = "AIR CÔTE D'IVOIRE",
    AIR_FRANCE = 'AIR FRANCE',
    AIR_PEACE = 'AIR PEACE',
    ARIK_AIR = 'ARIK AIR',
    ASKY_AIRLINES = 'ASKY AIRLINES',
    AZMAN = 'AZMAN',
    BRITISH_AIRWAYS = 'BRITISH AIRWAYS',
    CABO_VERDE_AIRLINES = 'CABOVERDE AIRLINES',
    CAMAIR_CO = 'CAMAIR CO',
    CRONOS_AIRLINEE = 'CRONOS AIRLINEE',
    DANA_AIR = 'DANA AIR',
    DELTA_AIRLINES = 'DELTA AIRLINES',
    DORNIER_AVIATION = 'DORNIER AVIATION',
    EAS_AIRLINES = 'EAS AIRLINES',
    EGYPT_AIR = 'EGYPT AIR',
    EMIRATES = 'EMIRATES',
    ETHIOPIAN_AIRLINES = 'ETHIOPIAN AIRLINES',
    FIRST_NATION = 'FIRST NATION',
    GREEN_AFRICA_AIRLINE = 'GREEN AFRICA AIRLINE',
    IBERIA_AIRLINES = 'IBERIA AIRLINES',
    IBOM_AIR = 'IBOM AIR',
    KABO_AIR = 'KABO AIR',
    KANEM_AIR = 'KANEM AIR',
    KENYA_AIRWAYS = 'KENYA AIRWAYS',
    KLM = 'KLM',
    K_IMPEX_AIRLINE = 'K-IMPEX AIRLINE',
    LUFTHANSA = 'LUFTHANSA',
    MAX_AIR = 'MAX AIR',
    MIDDLE_EAST_AIRLINE = 'MIDDLE EAST AIRLINE',
    OVERLAND_AIRWAYS = 'OVERLAND AIRWAYS',
    QATAR_AIRWAYS = 'QATAR AIRWAYS',
    ROYAL_AIR_MOROC = 'ROYAL AIR MOROC',
    RWANDAIR = 'RWANDAIR',
    SOUTH_AFRICAN_AIRWAYS = 'SOUTH AFRICAN AIRWAYS',
    SWISS_AIR = 'SWISS AIR',
    TAT_NIGERIA = 'TAT NIGERIA',
    TAAG_ANGOLA = 'TAAG ANGOLA',
    TURKISH_AIRLINES = 'TURKISH AIRLINES',
    UNITED_AIRLINES = 'UNITED AIRLINES',
    UNITED_NIGERIA_AIRLINES = 'UNITED NIGERIA AIRLINES',
    VALUE_JET = 'VALUE JET',
    VIRGIN_ATLANTIC_AIRLINE = 'VIRGIN ATLANTIC AIRLINE',
    WEST_LINK_AIRLINES = 'WEST LINK AIRLINES',
    XEJET = 'XEJET',
}

export const AirlinesMap = {
    [Airlines.A_A_RANO]: 'A. A RANO',
    [Airlines.AERO_CONTRACTORS]: 'AERO CONTRACTORS',
    [Airlines.AFRIQIYAH]: 'AFRIQIYAH',
    [Airlines.AFRICA_WORLD_AIRLINES]: 'AFRICA WORLD AIRLINES',
    [Airlines.AIR_CÔTE_D_IVOIRE]: "AIR CÔTE D'IVOIRE",
    [Airlines.AIR_FRANCE]: 'AIR FRANCE',
    [Airlines.AIR_PEACE]: 'AIR PEACE',
    [Airlines.ARIK_AIR]: 'ARIK AIR',
    [Airlines.ASKY_AIRLINES]: 'ASKY AIRLINES',
    [Airlines.AZMAN]: 'AZMAN',
    [Airlines.BRITISH_AIRWAYS]: 'BRITISH AIRWAYS',
    [Airlines.CABO_VERDE_AIRLINES]: 'CABOVERDE AIRLINES',
    [Airlines.CAMAIR_CO]: 'CAMAIR CO',
    [Airlines.CRONOS_AIRLINEE]: 'CRONOS AIRLINEE',
    [Airlines.DANA_AIR]: 'DANA AIR',
    [Airlines.DELTA_AIRLINES]: 'DELTA AIRLINES',
    [Airlines.DORNIER_AVIATION]: 'DORNIER AVIATION',
    [Airlines.EAS_AIRLINES]: 'EAS AIRLINES',
    [Airlines.EGYPT_AIR]: 'EGYPT AIR',
    [Airlines.EMIRATES]: 'EMIRATES',
    [Airlines.ETHIOPIAN_AIRLINES]: 'ETHIOPIAN AIRLINES',
    [Airlines.FIRST_NATION]: 'FIRST NATION',
    [Airlines.GREEN_AFRICA_AIRLINE]: 'GREEN AFRICA AIRLINE',
    [Airlines.IBERIA_AIRLINES]: 'IBERIA AIRLINES',
    [Airlines.IBOM_AIR]: 'IBOM AIR',
    [Airlines.KABO_AIR]: 'KABO AIR',
    [Airlines.KANEM_AIR]: 'KANEM AIR',
    [Airlines.KENYA_AIRWAYS]: 'KENYA AIRWAYS',
    [Airlines.KLM]: 'KLM',
    [Airlines.K_IMPEX_AIRLINE]: 'K-IMPEX AIRLINE',
    [Airlines.LUFTHANSA]: 'LUFTHANSA',
    [Airlines.MAX_AIR]: 'MAX AIR',
    [Airlines.MIDDLE_EAST_AIRLINE]: 'MIDDLE EAST AIRLINE',
    [Airlines.OVERLAND_AIRWAYS]: 'OVERLAND AIRWAYS',
    [Airlines.QATAR_AIRWAYS]: 'QATAR AIRWAYS',
    [Airlines.ROYAL_AIR_MOROC]: 'ROYAL AIR MOROC',
    [Airlines.RWANDAIR]: 'RWANDAIR',
    [Airlines.SOUTH_AFRICAN_AIRWAYS]: 'SOUTH AFRICAN AIRWAYS',
    [Airlines.SWISS_AIR]: 'SWISS AIR',
    [Airlines.TAT_NIGERIA]: 'TAT NIGERIA',
    [Airlines.TAAG_ANGOLA]: 'TAAG ANGOLA',
    [Airlines.TURKISH_AIRLINES]: 'TURKISH AIRLINES',
    [Airlines.UNITED_AIRLINES]: 'UNITED AIRLINES',
    [Airlines.UNITED_NIGERIA_AIRLINES]: 'UNITED NIGERIA AIRLINES',
    [Airlines.VALUE_JET]: 'VALUE JET',
    [Airlines.VIRGIN_ATLANTIC_AIRLINE]: 'VIRGIN ATLANTIC AIRLINE',
    [Airlines.WEST_LINK_AIRLINES]: 'WEST LINK AIRLINES',
    [Airlines.XEJET]: 'XEJET',
};
