import React from 'react';
import './Container.scss';

class Container extends React.Component<{}, {}> {
    public render() {
        const { children } = this.props;

        return <div className="customContainer">{children}</div>;
    }
}

export default Container;
