import { useField } from 'formik';
import React from 'react';
import { Tooltip } from 'reactstrap';
import styled from 'styled-components';

export const FormRangeInputContainer = styled.label`
    color: black;
    width: 100%;

    .focus {
        border-color: #aaa;
    }

    .form-range__body {
        display: grid;
        grid-template-columns: 80px 1fr;
    }

    .form-range__validation-text {
        font-size: 13px;
        padding: 5px 0;
        color: #f44336;
        width: 100%;
        display: block;
    }

    .form-range__body.valid {
        .form-range__icon {
            color: var(--primary-color);
        }
    }

    .form-range__body.invalid {
        .form-range__icon {
            color: #e03225;
        }
    }

    .form-range__label {
        display: flex;
        font-size: 14px;
        margin-bottom: 3px;
    }

    input {
        -webkit-appearance: none;
        width: 100%;
    }

    input:focus {
        outline: none;
    }

    input::-webkit-slider-runnable-track {
        width: 100%;
        height: 4px;
        cursor: pointer;
        background: #ededed;
        border-radius: 2px;
    }

    input::-moz-range-track {
        width: 100%;
        height: 4px;
        cursor: pointer;
        background: #ededed;
        border-radius: 2px;
    }

    input::-webkit-slider-thumb {
        height: 10px;
        width: 10px;
        border-radius: 100%;
        border: none;
        background: var(--primary-color);
        cursor: pointer;
        -webkit-appearance: none;
        transition: all 0.5 ease;
        margin-top: -3px;
    }

    input::-moz-range-thumb {
        height: 10px;
        width: 10px;
        border-radius: 100%;
        border: none;
        background: var(--primary-color);
        cursor: pointer;
        -webkit-appearance: none;
        transition: all 0.5 ease;
    }

    input:focus::-webkit-slider-runnable-track {
        background: #dadada;
    }

    input::-ms-track {
        width: 100%;
        height: 4px;
        cursor: pointer;
        background: transparent;
        border-color: transparent;
        border-width: 16px 0;
        color: transparent;
    }

    input::-ms-fill-lower {
        background: var(--primary-color);
        border: none;
        border-radius: 2px;
    }

    input::-ms-fill-upper {
        background: #ededed;
        border: none;
        border-radius: 2px;
    }

    input::-ms-thumb {
        height: 10px;
        width: 10px;
        border-radius: 100%;
        background: var(--primary-color);
        cursor: pointer;
    }
`;

export interface FormRangeInputProps {
    className?: string;
    name: string;
    label: string;
    disabled?: boolean;
    min: number;
    max: number;
    step?: number;
}

export const FormRangeIndicator: React.FC<{
    min: number;
    max: number;
    step: number;
    className?: string;
}> = ({ min, max, step, className }) => {
    const values = [...new Array(max - min + 1)].map((_, i) => (
        <div
            className="d-flex align-items-center justify-content-center"
            style={{
                background: '#ededed',
                borderRadius: '100%',
                height: 20,
                width: 20,
                fontSize: 10,
            }}
            key={i}
        >
            <span>{min + step * i}</span>
        </div>
    ));

    return (
        <div className={`d-flex ${className}`} style={{ width: '100%' }}>
            <div className="d-flex justify-content-between" style={{ flex: 1, marginLeft: 80 }}>
                {values}
            </div>
        </div>
    );
};

export const FormRangeInput: React.FC<FormRangeInputProps> = ({
    className,
    name,
    label,
    children,
    disabled,
    min,
    max,
    step,
    ...props
}) => {
    const [field, meta] = useField({ name });
    const validState = meta.touched ? (meta.error ? false : true) : null;
    const validationClass = validState === null ? '' : validState ? ' valid' : ' invalid';

    const id = name;
    const [tooltipOpen, setTooltipOpen] = React.useState(false);
    const toggle = () => setTooltipOpen(!tooltipOpen);

    const ref = React.useRef<HTMLInputElement>(null);
    const [isFocused, setIsFocused] = React.useState(false);
    const focusClass = React.useMemo(() => (isFocused ? ' focus' : ''), [isFocused]);

    React.useEffect(() => {
        if (ref.current) {
            ref.current.onfocus = () => {
                setIsFocused(true);
            };

            ref.current.onblur = () => {
                setIsFocused(false);
            };
        }
    }, [ref]);

    return (
        <FormRangeInputContainer {...props} className={className}>
            <div className={`form-range__body` + validationClass + focusClass} id={id}>
                {label && <span className="form-range__label">{label}</span>}
                <input
                    className="form-range__input"
                    type="range"
                    children={children}
                    disabled={disabled}
                    ref={ref}
                    min={min}
                    max={max}
                    step={step || 1}
                    {...field}
                />
            </div>
            {validState === false ? (
                <React.Fragment>
                    <Tooltip placement="right" isOpen={tooltipOpen} target={id} toggle={toggle}>
                        <span className="form-range__validation-text">{meta.error}</span>
                    </Tooltip>
                    <span className="form-range__validation-text">{meta.error}</span>
                </React.Fragment>
            ) : null}
        </FormRangeInputContainer>
    );
};
