import { Dispatch } from 'redux';
import { ForgetPasswordModel, ResetPasswordModel, UserLoginModel } from '../../models/request';
import { AuthService } from '../../services';
import { ApiClient } from '../../util/api-client';
import { NotificationTypes } from '../../util/constants';
import { history } from '../../util/history';
import { generateLoader } from '../../util/misc';
import { NotificationsThunk } from '../notifications/notifications.thunk';
import { AuthActions } from './auth.actions';

export class AuthThunk {
    public static logIn(loginDetails: UserLoginModel) {
        return async (dispatch: Dispatch) => {
            try {
                const response = await generateLoader(AuthService.logIn(loginDetails), dispatch, 'Logging in');

                if (response.data) {
                    ApiClient.setToken(response.data.token);

                    dispatch(AuthActions.logIn(response.data));

                    NotificationsThunk.create(NotificationTypes.Success, 'Login successful')(dispatch);

                    return history.push('/app/dashboard');
                }

                throw new Error('Login failed');
            } catch (error) {
                // TODO: dispatch error to errorReducer
                // to be implemented
            }
        };
    }

    public static requestPasswordReset(forgotPasswordDetails: ForgetPasswordModel) {
        return async (dispatch: Dispatch) => {
            try {
                const response = await generateLoader(
                    AuthService.requestPasswordReset(forgotPasswordDetails),
                    dispatch,
                    'Requesting password reset'
                );

                if (response.ok) {
                    NotificationsThunk.create(
                        NotificationTypes.Success,
                        'Password reset email sent successfully'
                    )(dispatch);

                    return history.push('/login');
                }

                throw new Error('Password reset request failed');
            } catch (error) {
                // TODO: dispatch error to errorReducer
                // to be implemented
            }
        };
    }

    public static requestResetPassword(forgotPasswordDetails: ResetPasswordModel) {
        return async (dispatch: Dispatch) => {
            try {
                const response = await generateLoader(
                    AuthService.requestResetPassword(forgotPasswordDetails),
                    dispatch,
                    'Resetting password'
                );

                if (response.ok) {
                    NotificationsThunk.create(NotificationTypes.Success, 'Password reset successful')(dispatch);

                    return history.push('/login');
                }

                throw new Error('Password not changed');
            } catch (error) {
                // TODO: dispatch error to errorReducer
                // to be implemented
            }
        };
    }

    public static verifyEmail(
        code: string,
        actions: {
            onSuccess: () => any;
            onFail: () => any;
        }
    ) {
        return async (dispatch: Dispatch) => {
            try {
                const response = await generateLoader(AuthService.verifyEmail(code), dispatch, 'Verifying email');

                if (response.ok) {
                    return actions.onSuccess();
                }

                return actions.onFail();
            } catch (error) {
                // TODO: dispatch error to errorReducer
                // to be implemented
            }
        };
    }

    public static logOut() {
        return async (dispatch: Dispatch) => {
            try {
                dispatch(AuthActions.logOut());
                ApiClient.unsetToken();
                window.localStorage.clear();

                return history.push('/login');
            } catch (error) {}
        };
    }

    public static clearSession() {
        return async (dispatch: Dispatch) => {
            dispatch(AuthActions.logOut());

            ApiClient.unsetToken();
            ApiClient.timedOut = true;

            window.localStorage.clear();
            NotificationsThunk.create(
                NotificationTypes.Warning,
                'Your session has expired. Please login again',
                true
            )(dispatch);
        };
    }
}
