import styled from 'styled-components';

export const SignoutStyleWrapper = styled.div`
    .wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        cursor: pointer;

        .content {
            span {
                color: #18a956;
                font-size: 14px;
                font-weight: bold;
                margin-right: 10px;
            }
        }

        .box {
            .divCircle {
                height: 40px;
                width: 40px;
                border-radius: 100%;
                background-color: #eafaf1;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                color: #18a956;
            }
        }
    }
`;
