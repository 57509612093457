import React, { useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Check from '../../../assets/images/check (1).svg';
import { EntryFormContainer } from '../../../components';
import { Breadcrumb, Breadcrumbs } from '../../../components/breadcrumbs/breadcrumbs';
import { FormStepper } from '../../../components/form';
import Layout, { StickyBackground } from '../../../components/layout';

export const RegistrationSuccessfulPage: React.FC = () => {
    const breadcrumbs: Breadcrumb[] = [
        { name: 'Home', path: '/' },
        { name: 'Create account', path: window.location.href },
    ];

    const history = useHistory();

    useEffect(() => {
        if (!history.location.state || !(history.location.state as any).successfulRegistration) {
            history.replace('/');
        }
    }, [history]);

    return (
        <Layout>
            <StickyBackground />
            <div className="px-5 py-3">
                <Breadcrumbs breadcrumbs={breadcrumbs} />
                <EntryFormContainer>
                    <div className="d-flex flex-column align-items-center">
                        <header className="form__header">
                            <div
                                className="d-flex justify-content-center align-items-center"
                                style={{
                                    width: 148,
                                    height: 148,
                                    background: '#e6f6ec',
                                    borderRadius: '100%',
                                }}
                            >
                                <img src={Check} width={80} alt="registration successful check icon" />
                            </div>
                            <div className="mt-4">
                                <h2>
                                    <b>Thanks for registering!</b>
                                </h2>
                            </div>
                            <FormStepper steps={1} currentStep={1} />
                        </header>
                        <main className="mt-4">
                            <p className="text-center">
                                Your registration is currently in review. We'll get back to you soon.
                                <br />
                                Kindly check your email for next procedures
                            </p>
                        </main>
                        <footer className="d-flex flex-column align-items-center">
                            <Link to="/">
                                <u>Go to homepage</u>
                            </Link>
                        </footer>
                    </div>
                </EntryFormContainer>
            </div>
        </Layout>
    );
};
