import React from 'react';
import { DropdownToggle } from 'reactstrap';
import { CustomDropdownItem, CustomDropdownMenu, StyledDropdown } from './custom-dropdown.style';

interface CustomDropdownProps {
    className?: string;
    triggerComponent: React.ComponentType;
}

export const CustomDropdown: React.FC<CustomDropdownProps> = ({
    className,
    triggerComponent: TriggerComponent,
    children,
}) => {
    const [isOpen, setIsOpen] = React.useState(false);
    const toggle = () => setIsOpen((prevState) => !prevState);

    return (
        <React.Fragment>
            <StyledDropdown isOpen={isOpen} toggle={toggle} className={className}>
                <DropdownToggle tag="div" style={{ cursor: 'pointer' }}>
                    <TriggerComponent />
                </DropdownToggle>
                <CustomDropdownMenu right>{children}</CustomDropdownMenu>
            </StyledDropdown>
        </React.Fragment>
    );
};

export { CustomDropdownMenu, CustomDropdownItem };
