import { AnimatePresence, motion, Variants } from 'framer-motion';
import { keys, map, size } from 'lodash';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Spinner } from 'reactstrap';
import styled from 'styled-components';
import { ApplicationState } from '../store';
import { LoadingSpinner } from './utilities/spinner';

const PageLoaderWrapper = styled(motion.div)`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1080;
    width: 100vw;
    height: 100vh;
    background: rgba(255, 255, 255, 0.65);
    backdrop-filter: blur(2px);
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .loader__icon {
        color: black;
    }

    .loader__message {
        font-size: 1.8rem;
        text-transform: uppercase;
        /* font-weight: bold; */
        margin-top: 30px;
        letter-spacing: 2px;
        display: flex;
        flex-direction: column;
        text-align: center;
    }
`;

export const PageLoader: React.FC = () => {
    const { tasks } = useSelector((state: ApplicationState) => state.loading);
    const isLoading = size(tasks) > 0;
    const messages = map(tasks);

    const variants: Variants = {
        initial: { opacity: 0, display: 'none' },
        visible: { opacity: 1, display: 'flex' },
    };

    const variant = useMemo(() => (isLoading ? 'visible' : 'initial'), [isLoading]);

    return (
        <PageLoaderWrapper variants={variants} animate={variant}>
            <LoadingSpinner className="loader__icon" />
            <span className="loader__message">
                <AnimatePresence>
                    {messages.length > 0
                        ? map(keys(tasks), (key) => {
                              return (
                                  <motion.span
                                      key={key}
                                      className="message"
                                      initial={{ opacity: 0 }}
                                      animate={{ opacity: 1 }}
                                      exit={{ opacity: 0 }}
                                  >
                                      {tasks[key]}
                                  </motion.span>
                              );
                          })
                        : 'Loading'}
                </AnimatePresence>
            </span>
        </PageLoaderWrapper>
    );
};
