import { saveAs } from 'file-saver';
import { CreateBookingModel, UpdateBookingModel } from '../models/request';
import { Booking, Service } from '../models/response';
import { NotificationsThunk } from '../store';
import { ApiClient } from '../util/api-client';
import { NotificationTypes, Roles } from '../util/constants';

export class BookingsService {
    public static async list(role?: Roles, page?: number) {
        const url = `booking-services${role === Roles.Client ? '/client' : ''}`;
        const query = page ? `?pageNumber=${page}` : '';
        return ApiClient.get<Booking[]>(url + query);
    }

    public static async getBookingsByServiceId(id: Service['id'], page?: number) {
        const url = `booking-services/booking-by-service/${id}`;
        const query = page ? `?pageNumber=${page}` : '';
        return ApiClient.get<Booking[]>(url + query);
    }

    public static async retrieve(id: number) {
        return ApiClient.get<Booking>(`booking-services/${id}`);
    }

    public static async create(data: CreateBookingModel | CreateBookingModel[]) {
        if (Array.isArray(data)) {
            return ApiClient.post<CreateBookingModel[], Booking>('booking-services/create-bookings', data);
        }

        return ApiClient.post<CreateBookingModel, Booking>('booking-services', data);
    }

    public static async update(data: UpdateBookingModel) {
        return ApiClient.patch<UpdateBookingModel, Booking>(`booking-services`, data);
    }

    public static async cancel(id: number) {
        return ApiClient.delete<unknown>(`booking-services/${id}`);
    }

    public static async exportBooking(batchCode: string, documentName: string, dispatch: any, fileType: string) {
        const config: RequestInit = {
            ...ApiClient.getFetchConfig(),
        };

        try {
            dispatch(NotificationsThunk.create(NotificationTypes.Info, `Downloading document`));
            const response = await fetch(
                `${process.env.REACT_APP_BASE_URL}/booking-services/export-receipt/${batchCode}`,
                config
            );

            const path =
                fileType === 'invoice'
                    ? '/booking-services/export-receipt/' // if fileType is invoice return '/tras....../'
                    : fileType === 'receipt'
                    ? '/booking-services/export-receipt/' // else if fileType is reciept return '/tras....../'
                    : ''; // elase return emplty string

            try {
                const downloadedFile = await response.blob();
                saveAs(downloadedFile, documentName + '.pdf');
            } catch (e) {
                dispatch(NotificationsThunk.create(NotificationTypes.Error, 'Error saving file'));
            }
        } catch (e) {
            dispatch(NotificationsThunk.create(NotificationTypes.Error, 'Error downloading file'));
        }
    }
}
