import { AuthenticatedUser } from '../../models/response';
import { ReduxAction } from '../common/redux-action';

export interface AuthState {
    authUser?: AuthenticatedUser;
}

export enum AuthActionNames {
    LOG_IN = 'AUTH_LOG_IN',
    LOG_OUT = 'AUTH_LOG_OUT',
}

export interface LogInAction extends ReduxAction<typeof AuthActionNames.LOG_IN, AuthState> {}
export interface LogOutAction extends ReduxAction<typeof AuthActionNames.LOG_OUT, AuthState> {}
