import React from 'react';
import { FlagTime, SignOutSwitchComponent } from '../../../components';
import { Breadcrumbs } from '../../../components/breadcrumbs/breadcrumbs';
import { TopBarStyleWrapper } from './top-bar.style';

type Props = {
    breadcrumbs: any;
};

export const TopBar: React.FC<Props> = (props) => {
    const { breadcrumbs } = props;

    return (
        <TopBarStyleWrapper>
            <div className="topbarWrapper">
                <div className="flagBox">
                    <FlagTime />
                </div>
                <div className="breadcrumbBox">
                    <Breadcrumbs breadcrumbs={breadcrumbs} />
                </div>
                <div className="logoutBox">
                    <SignOutSwitchComponent />
                </div>
            </div>
        </TopBarStyleWrapper>
    );
};
