import styled from 'styled-components';

export const CustomTableStyleWrapper = styled.div`
    table {
        color: black;
        font-size: 12px !important;

        th {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }

        th,
        td {
            padding-left: 0;
        }

        thead {
            tr,
            th {
                border: 0 !important;
                font-weight: bold !important;
            }
        }

        tbody {
            tr td span {
                color: #18a956;
                font-weight: bold;
            }

            a {
                color: #18a956;
            }

            .viewBtn {
                &:hover {
                    background-color: #fff;
                }
            }
        }
    }
`;
