import { Formik } from 'formik';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { Breadcrumb, Breadcrumbs, EntryFormContainer, StickyBackground } from '../../components';
import { FormButton, FormRadio, FormRangeIndicator, FormRangeInput, FormTextInput } from '../../components/form';
import Layout from '../../components/layout';
import { CustomerSurveyModel } from '../../models/request';
import { FeedbackThunk } from '../../store/feedback/feedback.thunk';
import { EOptions } from '../../util/constants';
import { validateFormValues } from '../../util/validation';

export const CustomerSurveyPage: React.FC = () => {
    const breadcrumbs: Breadcrumb[] = [
        { name: 'Home', path: '/' },
        { name: 'Customer survey', path: window.location.href },
    ];

    const dispatch = useDispatch();
    const { code } = useParams<{ code: string }>();

    const handleSubmitSurvey = React.useCallback(
        async (survey: CustomerSurveyModel) => {
            dispatch(FeedbackThunk.submit(survey));
        },
        [dispatch]
    );

    return (
        <Layout>
            <StickyBackground />
            <div className="px-5 py-3">
                <Breadcrumbs breadcrumbs={breadcrumbs} />
                <EntryFormContainer>
                    <Formik
                        validate={validateFormValues}
                        initialValues={new CustomerSurveyModel(code as string)}
                        onSubmit={handleSubmitSurvey}
                    >
                        {({ handleSubmit, isValid, submitCount }) => (
                            <form onSubmit={handleSubmit} className="d-flex flex-column align-items-center">
                                <header className="form__header mb-5">
                                    <h1>Customer service feedback form</h1>
                                    {!isValid && submitCount > 0 && (
                                        <h6 className="error mt-2">Please check the form fields for errors</h6>
                                    )}
                                </header>
                                <main className="d-flex flex-column mb-5" style={{ width: 330 }}>
                                    <div className="d-flex flex-column mb-3">
                                        <b className="d-flex mb-3">Overall satisfaction of service</b>
                                        <FormRangeIndicator min={1} max={5} step={1} />
                                    </div>
                                    <FormRangeInput
                                        className="mb-3"
                                        name="FriendlinessRank"
                                        label="Friendliness"
                                        min={1}
                                        max={5}
                                    />
                                    <FormRangeInput
                                        className="mb-3"
                                        name="KnowledgeRank"
                                        label="Knowledge"
                                        min={1}
                                        max={5}
                                    />
                                    <FormRangeInput
                                        className="mb-5"
                                        name="QuicknessRank"
                                        label="Quickness"
                                        min={1}
                                        max={5}
                                    />
                                    <div className="mb-5">
                                        <b className="d-flex mb-2">Would you use our service in the future?</b>
                                        <div className="d-flex">
                                            <FormRadio
                                                className="mr-2"
                                                name="EOptions"
                                                label="YES"
                                                value={EOptions.Yes}
                                            />
                                            <FormRadio
                                                className="mr-2"
                                                name="EOptions"
                                                label="NO"
                                                value={EOptions.No}
                                            />
                                            <FormRadio
                                                className="mr-2"
                                                name="EOptions"
                                                label="MAYBE"
                                                value={EOptions.Maybe}
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <b className="d-flex mb-2">How can we improve our services?</b>
                                        <FormTextInput name="FeedbackText" as="textarea" />
                                    </div>
                                </main>
                                <footer className="d-flex flex-column align-items-center">
                                    <FormButton type="submit">
                                        <span>Submit survey</span>
                                    </FormButton>
                                </footer>
                            </form>
                        )}
                    </Formik>
                </EntryFormContainer>
            </div>
        </Layout>
    );
};
