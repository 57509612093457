import React from 'react';

type State = {
    children: React.ReactNode;
};

export class ErrorBoundary extends React.Component<State> {
    public static getDerivedStateFromError(error: any) {
        return { hasError: true };
    }

    public state = {
        hasError: false,
    };

    public componentDidCatch(error: Error | null | undefined, errorInfo: any) {
        // log error on the server
    }

    public render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return <h1>Something went wrong.</h1>;
        }

        return this.props.children;
    }
}
