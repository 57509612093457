import { Formik } from 'formik';
import { isMatch } from 'lodash';
import React from 'react';
import { Book, Briefcase, Calendar, Check, MapPin } from 'react-feather';
import { useDispatch } from 'react-redux';
import { ModalBody, ModalFooter } from 'reactstrap';
import { CustomPrimaryButton } from '../../../../components';
import { FormTextInput } from '../../../../components/form';
import { CustomModal } from '../../../../components/utilities/modal/modal';
import { UpdateCorporateClientModel, UpdateIndividualClientModel } from '../../../../models/request';
import { User } from '../../../../models/response';
import { NotificationsThunk } from '../../../../store/notifications/notifications.thunk';
import { BusinessNatures, MeansOfIdList, NotificationTypes } from '../../../../util/constants';
import { validateFormValues } from '../../../../util/validation';

interface Props {
    isOpen: boolean;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    client: User;
    handleUpdateClient: (userDetails: UpdateCorporateClientModel) => void;
}

export const UpdateCorporateClientModal: React.FC<Props> = ({ isOpen, setIsOpen, client, handleUpdateClient }) => {
    const dispatch = useDispatch();

    return (
        <CustomModal isOpen={isOpen} setIsOpen={setIsOpen} title="Edit Profile">
            <Formik
                validate={validateFormValues}
                enableReinitialize
                initialValues={new UpdateCorporateClientModel(client)}
                onSubmit={(clientDetails) => {
                    if (isMatch(new UpdateCorporateClientModel(client), clientDetails)) {
                        dispatch(NotificationsThunk.create(NotificationTypes.Info, 'No changes were made'));
                        return setIsOpen(false);
                    }

                    handleUpdateClient(clientDetails);
                }}
            >
                {({ handleSubmit }) => (
                    <form onSubmit={handleSubmit}>
                        <ModalBody>
                            <div className="form-row">
                                <div className="col-6">
                                    <FormTextInput
                                        disabled
                                        name="Business_Name"
                                        label="Business Name"
                                        type="text"
                                        compact
                                    />
                                </div>
                                <div className="col-6">
                                    <FormTextInput
                                        name="Business_Nature"
                                        label="Business Nature"
                                        type="text"
                                        as="select"
                                        compact
                                    >
                                        <option value="" disabled>
                                            NATURE OF BUSINESS
                                        </option>{' '}
                                        {BusinessNatures.map((value, i) => (
                                            <option value={value} key={i}>
                                                {value}
                                            </option>
                                        ))}
                                    </FormTextInput>
                                    {/* <FormTextInput name="Business_Nature" label="Business Nature" type="text" compact /> */}
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="col-6">
                                    <FormTextInput
                                        name="Registration_Number"
                                        label="Registration Number"
                                        type="text"
                                        compact
                                    />
                                </div>
                                <div className="col-6">
                                    <FormTextInput name="Creation_Year" label="Creation Year" type="text" compact />
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="col">
                                    <FormTextInput name="PhoneNumber" label="Phone Number" type="text" compact />
                                </div>
                                <div className="col-md-6 mb-2">
                                    <FormTextInput name="Email" label="Email Adreess" type="email" compact disabled />
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="col-12">
                                    <FormTextInput
                                        name="Registered_Address"
                                        label="Registered Address"
                                        type="text"
                                        compact
                                    />
                                </div>
                            </div>
                        </ModalBody>
                        <ModalFooter className="d-flex justify-content-between align-items-center">
                            <CustomPrimaryButton type="submit">
                                <Check size={14} strokeWidth={3} />
                                <span>Edit Client</span>
                            </CustomPrimaryButton>
                            <button
                                className="modal-footer__button"
                                type="button"
                                onClick={setIsOpen.bind(undefined, false)}
                            >
                                <span>Close</span>
                            </button>
                        </ModalFooter>
                    </form>
                )}
            </Formik>
        </CustomModal>
    );
};
