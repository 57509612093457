import { SystemNotification } from '../../models/response';

export interface NotificationsState {
    notifications: SystemNotification[];
    idCounter: number;
}

export enum NotificationsActionNames {
    ADD = 'NOTIFICATIONS_ADD',
    REMOVE = 'NOTIFICATIONS_REMOVE',
}
