import { Allow, IsEnum, IsIn, IsString, Matches, MinLength, ValidateIf } from 'class-validator';
import { GovernmentStaffTypes, NigerianStates } from '../../../util/constants';
import { User } from '../../response';
import { BaseModel } from '../base-model';
import { CreateGovernmentOfficialModel } from './create-government-official.model';
import { CommonRegistrationModel } from './common-registration-model';

export class UpdateGovernmentOfficialModel implements BaseModel {
    @IsString({ message: 'Invalid id' })
    public id: string = '';

    @IsString()
    @MinLength(3, {
        message: 'Must be at least $constraint1 characters long',
    })
    public Business_Name: string = '';

    @IsString()
    @MinLength(3, {
        message: 'Must be at least $constraint1 characters long',
    })
    public Name_Of_Government_Officials_Office: string = '';

    @Allow()
    @IsEnum(GovernmentStaffTypes, { message: 'Invalid selection' })
    public GovernmentStaffType?: string = '';

    @IsIn(NigerianStates, { message: 'Invalid selection' })
    @ValidateIf((o: CreateGovernmentOfficialModel) => o.GovernmentStaffType === GovernmentStaffTypes.State)
    public State?: string = '';

    @IsString()
    @MinLength(3, {
        message: 'Must be at least $constraint1 characters long',
    })
    public Registered_Address: string = '';

    @Allow()
    public PhoneNumber: string = '';

    public Email: string = '';

    constructor(client: User) {
        if (client) {
            this.id = client.id;
            this.Name_Of_Government_Officials_Office = client.name_Of_Government_Officials_Office;
            this.GovernmentStaffType = client.governmentStaffType;
            this.State = client.state;
            this.Registered_Address = client.registered_Address;
            this.Email = client.email;
            this.PhoneNumber = client.phoneNumber;
            return;
        }
    }

    public beforeValidation() {
        // super.beforeValidation();
        this.Business_Name = this.Name_Of_Government_Officials_Office;
    }

    public finalize() {
        if (this.GovernmentStaffType === GovernmentStaffTypes.Federal) {
            delete this.State;
        }

        delete this.GovernmentStaffType;
    }
}
