import { Dispatch } from 'redux';
import { AcceptableNotificationContentTypes, SystemNotification } from '../../models/response';
import { NotificationTypes } from '../../util/constants';
import { NotificationsActions } from './notifications.actions';

export class NotificationsThunk {
    public static create(type: NotificationTypes, content: AcceptableNotificationContentTypes, sticky?: boolean) {
        return (dispatch: Dispatch) => {
            dispatch(NotificationsActions.add(new SystemNotification(type, content, sticky)));
        };
    }

    public static dismiss(id: number) {
        return (dispatch: Dispatch) => {
            dispatch(NotificationsActions.remove(id));
        };
    }
}
