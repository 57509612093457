import { find } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Check, Info, X } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { Cell } from 'react-table';
import styled from 'styled-components';
import { AppLayout, CustomPrimaryButton, DataTable, FundWalletModal } from '../../../../../components';
import { CreateBookingModel, CreatePassengerBooking } from '../../../../../models/request';
import { TransactionsService } from '../../../../../services';
import { ApplicationState } from '../../../../../store';
import { BookingsThunk } from '../../../../../store/bookings/bookings.thunk';
import { formatCurrency, formatDate, formatTime } from '../../../../../util/misc/formatters';

interface Props {
    passengerBookings: CreatePassengerBooking[];
    goBack: () => void;
}

const InsufficientFundsWrapper = styled.div`
    width: 100%;
    font-size: 0.85rem;
    padding: 0.75em;
    color: #f4f4f4;
    background-color: rgb(247, 123, 114);
    font-weight: 500;
    display: flex;
    align-items: center;
    border-radius: 8px;
    justify-content: space-between;

    button {
        background-color: #f4f4f4;
        color: rgb(247, 123, 114);
        border: none;
        padding: 0.5em 1em;
        border-radius: 8px;
        font-weight: 700;
        transition: color 0.5s ease-in;
        display: inline-flex;
        align-items: center;
        justify-content: center;

        &:hover {
            color: rgba(247, 123, 114, 0.863);
            background-color: #f4f4f4cc;
        }
    }
`;

export const CreateBookingVerificationPage: React.FC<Props> = ({ passengerBookings, goBack }) => {
    const [isFundingWallet, setFundingWallet] = useState(false);
    const [balance, setBalance] = useState(0);

    const { airports } = useSelector((state: ApplicationState) => state.airports);
    const { services } = useSelector((state: ApplicationState) => state);
    const dispatch = useDispatch();

    const getAdditionalServicePrice = (serviceId: number) => {
        let price = 0;
        for (let service of services.services) {
            if (service.id === serviceId) {
                price = service.price;
            }
        }
        return price;
    };

    const calculateAdditionalServicePrices = () => {
        let total = 0;
        for (let pass of passengerBookings) {
            total += getAdditionalServicePrice(pass.additionalServiceId);
        }
        return total;
    };

    const totalAdditionalService: number = calculateAdditionalServicePrices();

    const price = React.useMemo(
        () => passengerBookings.length * passengerBookings[0]?.Service.price + totalAdditionalService,
        [passengerBookings]
    );

    const handleCreateBooking = () => {
        const bookingsMap: Record<string, CreateBookingModel> = {};

        passengerBookings.forEach((passengerBooking) => {
            const hash = CreatePassengerBooking.hashDetails(passengerBooking);

            if (hash in bookingsMap) {
                bookingsMap[hash]?.Passengers.push(passengerBooking.toPassenger());
                return;
            }

            const booking = passengerBooking.toBooking();
            booking.finalize();
            bookingsMap[hash] = booking;
        });

        const bookings = Object.values(bookingsMap);
        dispatch(BookingsThunk.create(bookings));
    };

    useEffect(() => {
        TransactionsService.getWalletBalance()
            .then(({ data }) => {
                setBalance(data?.balance.balance ?? 0);
            })
            .catch((err) => console.log({ err }));
    }, []);

    return (
        <React.Fragment>
            {/* Booking Verification Page */}
            {balance < price && (
                <InsufficientFundsWrapper className="my-3">
                    {isFundingWallet && (
                        <FundWalletModal
                            onComplete={(value) => setBalance((prev) => prev + value)}
                            isOpen={isFundingWallet}
                            isCreatingBooking
                            setIsOpen={setFundingWallet}
                        />
                    )}
                    <span>
                        You do not have enough balance to complete this process. Please fund your wallet to procced
                    </span>

                    <button onClick={() => setFundingWallet(true)}>Fund wallet</button>
                </InsufficientFundsWrapper>
            )}
            <AppLayout.Body>
                <AppLayout.TabHeader>
                    <div className="my-4 d-flex justify-content-between">
                        <span>Kindly review and confirm your Service booking details</span>
                        <b>{passengerBookings.length} Passenger(s)</b>
                    </div>
                </AppLayout.TabHeader>
                <div className="tab__content__row mb-5">
                    <DataTable
                        columns={[
                            {
                                Header: 'S/N',
                                Cell: (cell: Cell<CreatePassengerBooking>) => cell.row.index + 1,
                            },
                            {
                                Header: 'Name',
                                Cell: ({ row: { original: passenger } }: Cell<CreatePassengerBooking>) =>
                                    passenger.FirstName + ' ' + passenger.LastName,
                            },
                            {
                                Header: 'Airport',
                                accessor: 'AirportId',
                                Cell: ({ row: { original: passenger } }: Cell<CreatePassengerBooking>) =>
                                    find(airports, { id: passenger.AirportId })?.name,
                            },
                            {
                                Header: 'Airline',
                                Cell: ({ row: { original: passenger } }: Cell<CreatePassengerBooking>) =>
                                    passenger.airline,
                            },
                            {
                                Header: 'Flight No.',
                                accessor: 'Flight_Number',
                            },
                            {
                                Header: 'Travel date',
                                accessor: 'Date_Of_Travel',
                                Cell: ({ row: { original: passenger } }: Cell<CreatePassengerBooking>) =>
                                    formatDate(passenger.Date_Of_Travel),
                            },
                            {
                                Header: 'Airport time',
                                accessor: 'Date_Of_Arrival',
                                Cell: ({ row: { original: passenger } }: Cell<CreatePassengerBooking>) =>
                                    formatTime(passenger.Date_Of_Arrival),
                            },
                            {
                                Header: 'Special R.',
                                accessor: 'special_Requirement' as any,
                                Cell: ({ row: { original: passenger } }: Cell<CreatePassengerBooking>) => {
                                    return (
                                        <div>
                                            {passenger.Wheel_Chair ? (
                                                <span>Wheel chair</span>
                                            ) : !passenger.Special_Requirement ? (
                                                <X size={14} strokeWidth={2} />
                                            ) : (
                                                <span>{passenger.Special_Requirement}</span>
                                            )}
                                        </div>
                                    );
                                },
                            },
                            // {
                            //     Header: 'Addtional service',
                            //     accessor: 'additionalServiceId' as any,
                            //     Cell: ({ row: { original: passenger } }: Cell<CreatePassengerBooking>) =>
                            //         find(services.services, { id: passenger.additionalServiceId })?.name ?? '',
                            // },
                        ]}
                        data={passengerBookings}
                    />
                    <div className="d-flex mt-3 mb-5 justify-content-end">
                        <b>Total Price {formatCurrency(price)}</b>
                    </div>
                    <div className="d-flex mt-4 justify-content-end">
                        <div>
                            <CustomPrimaryButton className="inverted" onClick={goBack}>
                                <span>Edit Details</span>
                            </CustomPrimaryButton>
                            {balance >= price && (
                                <CustomPrimaryButton onClick={handleCreateBooking}>
                                    <span>Confirm</span>
                                </CustomPrimaryButton>
                            )}
                        </div>
                    </div>
                </div>
            </AppLayout.Body>
        </React.Fragment>
    );
};
