import { CreateServiceModel, UpdateServiceModel } from '../models/request';
import { Service } from '../models/response';
import { ApiClient } from '../util/api-client';

export class ServicesService {
    public static async getAllServices() {
        return ApiClient.get<Service[]>('services');
    }

    public static async getSingleService(service_id: string) {
        return ApiClient.get<Service>(`services/${service_id}`);
    }

    public static async getClientService() {
        return ApiClient.get<Service[]>(`services/services-user`);
    }
    public static async createService(data: CreateServiceModel) {
        return ApiClient.post<CreateServiceModel, Service>('services', data);
    }

    public static async updateService(data: UpdateServiceModel) {
        return ApiClient.patch<UpdateServiceModel, Service>('services', data);
    }
}
