import { Allow, IsEmail, IsEnum, Matches, Min, MinLength } from 'class-validator';
import { ClientTypes } from '../../../util/constants';
import { ConvertToNumber, IsMobileNumber, ShouldMatchField } from '../../../util/validation';
import {
    CONTAINS_DIGIT,
    CONTAINS_LOWERCASE,
    CONTAINS_SPECIAL_CHARACTER,
    CONTAINS_UPPERCASE,
    PASSWORD_MIN_LENGTH,
} from '../auth/constants';
import { BaseModel } from '../base-model';

export class CommonRegistrationModel implements BaseModel {
    @MinLength(3, {
        message: 'Must be at least $constraint1 characters long',
    })
    public Registered_Address: string = '';

    @IsEmail(undefined, { message: 'Invalid email format' })
    public Email: string = '';

    @IsMobileNumber({ message: 'Invalid phone number format' })
    public PhoneNumber: string = '';

    @ConvertToNumber()
    @IsEnum(ClientTypes, { message: 'Invalid selection' })
    @Min(1, { message: 'Invalid Client Type Selected' })
    public ClientTypeId: number = 0;

    @Matches(CONTAINS_DIGIT, {
        message: 'Must contain a digit',
    })
    @Matches(CONTAINS_SPECIAL_CHARACTER, {
        message: 'Must contain a special character',
    })
    @Matches(CONTAINS_UPPERCASE, {
        message: 'Must contain an uppercase letter',
    })
    @Matches(CONTAINS_LOWERCASE, {
        message: 'Must contain an lowercase letter',
    })
    @MinLength(PASSWORD_MIN_LENGTH, {
        message: `Must be at least $constraint1 character(s) long`,
    })
    public Password: string = '';

    @ShouldMatchField('Password', { message: 'Must match password' })
    public Confirm_Password: string = '';

    @Allow()
    public Files: File[] = [];

    public beforeValidation() {
        this.ClientTypeId = +this.ClientTypeId;
    }
}
