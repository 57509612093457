import { capitalize, orderBy } from 'lodash';
import moment from 'moment';
import React, { useContext } from 'react';
import { MoreVertical } from 'react-feather';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Cell } from 'react-table';
import { CustomDropdown, CustomDropdownItem, CustomPrimaryButton, DataTable } from '../../../components';
import { AuthContext } from '../../../context';
import { Transaction } from '../../../models/response';
import { TransactionsActions } from '../../../store';
import { ApiResponse } from '../../../util/api-client';
import { Roles } from '../../../util/constants';

interface FundTransactionTableProps {
    transactions: Transaction[];
    hideOptions?: boolean;
    refreshFunction?: (...args: any[]) => any;
    meta?: ApiResponse<Transaction[]>['meta'];
}

export const FundsTransactionsTable: React.FC<FundTransactionTableProps> = ({ transactions, meta, hideOptions }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { user } = useContext(AuthContext.Context)!;
    const extraColumns =
        user?.role === Roles.Client && !hideOptions
            ? [
                  {
                      Header: 'Status',
                      accessor: 'paymentStatus',
                      Cell: (cell: Cell<Transaction>) => <span>{cell.row.original.paymentStatus ?? 'Unpaid'}</span>,
                  },
                  {
                    Header: 'Funded By',
                    accessor: 'createdBy',
                    Cell: ({ row: { original } }: Cell<Transaction>) => {
                        return <span style={{ color: 'var(--primary-color)' }}>{original.createdBy ?? 'N/A'}</span>;
                    },
                },
                  {
                      Header: 'Actions',
                      Cell: ({ row: { original: transaction } }: Cell<Transaction>) => {
                          return (
                              <CustomDropdown
                                  className="mr-2"
                                  triggerComponent={() => {
                                      return (
                                          <CustomPrimaryButton className="small">
                                              <MoreVertical size={12} />
                                          </CustomPrimaryButton>
                                      );
                                  }}
                              >
                                  <CustomDropdownItem
                                      onClick={() => {
                                          dispatch(TransactionsActions.retrieveType([transaction], undefined));
                                          history.push(
                                              `/app/transaction-service/${transaction.transactionId}?type=${transaction.transactionType}`
                                          );
                                      }}
                                  >
                                      View More
                                  </CustomDropdownItem>
                              </CustomDropdown>
                          );
                      },
                  },
              ]
            : [
                  {
                      Header: 'Status',
                      accessor: 'paymentStatus',
                      Cell: (cell: Cell<Transaction>) => <span>{cell.row.original.paymentStatus ?? 'Unpaid'}</span>,
                  },
              ];

    // const dispatch = useDispatch();
    // const makePayment = () => window.open('https://www.faan.gov.ng/faan-payments/', '_blank');

    return (
        <DataTable
            columns={[
                {
                    Header: 'Date',
                    Cell: ({ row: { original } }: Cell<Transaction>) => (
                        <span>{moment(original.dateCreated).format('Do MMMM, YYYY,  h:mm:ss a')}</span>
                    ),
                },
                {
                    Header: 'Service',
                    Cell: ({ row: { original } }: Cell<Transaction>) => <span>Fund Wallet</span>,
                },
                {
                    Header: 'Amount',
                    accessor: 'amount' as any,
                    Cell: ({ row: { original } }: Cell<Transaction>) => (
                        <span>NGN {original.amount.toLocaleString()}</span>
                    ),
                },
                {
                    Header: 'Invoice Number',
                    accessor: 'invoiceNo',
                    Cell: ({ row: { original } }: Cell<Transaction>) => {
                        return <span>{original.invoiceNo ?? 'N/A'}</span>;
                    },
                },
                {
                    Header: 'RRR Number',
                    accessor: 'rrrNo' as any,
                    Cell: ({ row: { original } }: Cell<Transaction>) => <span>{original.rrrNo ?? 'N/A'}</span>,
                },
                ...extraColumns,
            ]}
            data={orderBy(transactions, (_) => new Date(_.dateCreated), 'desc')}
            meta={meta}
        />
    );
};
