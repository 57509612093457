import 'filepond/dist/filepond.min.css';
import { Formik } from 'formik';
import { isMatch } from 'lodash';
import React, { useState } from 'react';
import { Briefcase, Lock, Mail, MapPin, Phone } from 'react-feather';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { EntryFormContainer } from '../../../components';
import { Breadcrumb, Breadcrumbs } from '../../../components/breadcrumbs/breadcrumbs';
import { FormBackButton, FormButton, FormStepper, FormTextInput } from '../../../components/form';
import Layout, { StickyBackground } from '../../../components/layout';
import { CreateGovernmentOfficialModel } from '../../../models/request';
import { UsersThunk } from '../../../store/users/users.thunk';
import { ClientTypesMap, GovernmentStaffTypes, GovernmentStaffTypesMap, NigerianStates } from '../../../util/constants';
import { validateFormValues } from '../../../util/validation';
import { RegistrationStages } from './common';
import { FormCheckBox } from '../../../components/form/form-check-box';
import { TermsAndConditionsModal } from '../../admin/bookings/create-booking/components/terms-and-conditions';

const StaffTypeField = () => (
    <div className="col-6">
        <FormTextInput
            className="wide"
            name="GovernmentStaffType"
            placeholder="STAFF TYPE"
            type="text"
            as="select"
            icon={Briefcase}
        >
            <option value="" disabled>
                SELECT OFFICE TYPE
            </option>
            {Object.entries(GovernmentStaffTypesMap).map(([value, type], i) => (
                <option value={value} key={i}>
                    {type}
                </option>
            ))}
        </FormTextInput>
    </div>
);

const ClientTypeField = () => (
    <div className="col-6">
        <FormTextInput
            name="ClientTypeId"
            placeholder="SERVICE TYPE"
            type="text"
            as="select"
            icon={Briefcase}
            className="wide"
        >
            <option value={0} disabled>
                SERVICE TYPE
            </option>
            {Object.entries(ClientTypesMap).map(([value, name], i) => (
                <option value={value} key={i}>
                    {name}
                </option>
            ))}
        </FormTextInput>
    </div>
);

export const RegisterGovernmentOfficialPage: React.FC = () => {
    const breadcrumbs: Breadcrumb[] = [
        { name: 'Home', path: '/' },
        { name: 'Create account', path: window.location.href },
    ];

    const [currentStage, setCurrentStage] = React.useState<RegistrationStages>(RegistrationStages.FirstStage);
    const [isTermsOpen, setTermsOpen] = useState(false);

    const dispatch = useDispatch();
    const handleRegister = React.useCallback(
        async ({ Files: files, ...values }: CreateGovernmentOfficialModel) => {
            const formData = new FormData();

            Object.entries(values).forEach(([key, value]) => {
                formData.append(key, value);
            });

            dispatch(UsersThunk.createGovernmentOfficial(formData));
        },
        [dispatch]
    );

    return (
        <Layout>
            <StickyBackground />
            <div className="px-5 py-3">
                <Breadcrumbs breadcrumbs={breadcrumbs} />
                <EntryFormContainer>
                    <Formik
                        validate={validateFormValues}
                        initialValues={new CreateGovernmentOfficialModel()}
                        onSubmit={handleRegister}
                    >
                        {({ handleSubmit, isValid, submitCount, initialValues, values }) => (
                            <form onSubmit={handleSubmit} className="d-flex flex-column align-items-center">
                                <header className="form__header mb-5">
                                    <span className="form__subheader">Government Official</span>
                                    <FormBackButton currentStep={currentStage} setCurrentStep={setCurrentStage} />
                                    <h1 className="mb-3">Create an account</h1>
                                    <FormStepper steps={2} currentStep={currentStage} />
                                    {!isValid && submitCount > 0 && (
                                        <h6 className="error mt-2">Please check the form fields for errors</h6>
                                    )}
                                </header>
                                <main className="mb-4">
                                    <React.Fragment>
                                        <div className="form-row mb-2">
                                            <div className="col-6">
                                                <FormTextInput
                                                    className="wide"
                                                    name="Name_Of_Government_Officials_Office"
                                                    placeholder="GOVERNMENT OFFICE NAME"
                                                    type="text"
                                                    icon={Briefcase}
                                                />
                                            </div>
                                            <div className="col-6">
                                                <FormTextInput
                                                    className="wide"
                                                    name="Registered_Address"
                                                    placeholder="OFFICE REGISTERED ADDRESS"
                                                    type="text"
                                                    icon={MapPin}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-row mb-2">
                                            <div className="col-6">
                                                <FormTextInput
                                                    className="wide"
                                                    name="Email"
                                                    placeholder="EMAIL ADDRESS"
                                                    type="email"
                                                    icon={Mail}
                                                />
                                            </div>
                                            <div className="col-6">
                                                <FormTextInput
                                                    className="wide"
                                                    name="PhoneNumber"
                                                    placeholder="MOBILE NUMBER"
                                                    type="text"
                                                    icon={Phone}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-row mb-2">
                                            <div className="col-6">
                                                <FormTextInput
                                                    className="wide"
                                                    name="Password"
                                                    placeholder="PASSWORD"
                                                    type="password"
                                                    icon={Lock}
                                                    toggleField
                                                />
                                            </div>
                                            <div className="col-6">
                                                <FormTextInput
                                                    className="wide"
                                                    name="Confirm_Password"
                                                    placeholder="CONFIRM PASSWORD"
                                                    type="password"
                                                    icon={Lock}
                                                    toggleField
                                                />
                                            </div>
                                        </div>
                                        {values.GovernmentStaffType === GovernmentStaffTypes.State ? (
                                            <React.Fragment>
                                                <div className="form-row mb-2">
                                                    <StaffTypeField />
                                                    <div className="col-6">
                                                        <FormTextInput
                                                            className="wide"
                                                            name="State"
                                                            placeholder="STATE"
                                                            type="text"
                                                            as="select"
                                                            icon={Briefcase}
                                                        >
                                                            <option value="" disabled>
                                                                SELECT STATE
                                                            </option>
                                                            {NigerianStates.map((state, i) => (
                                                                <option value={state} key={i}>
                                                                    {state}
                                                                </option>
                                                            ))}
                                                        </FormTextInput>
                                                    </div>
                                                </div>
                                                <div className="form-row mb-4">
                                                    <ClientTypeField />
                                                </div>
                                            </React.Fragment>
                                        ) : (
                                            <div className="form-row mb-4">
                                                <StaffTypeField />
                                                <ClientTypeField />
                                            </div>
                                        )}
                                    </React.Fragment>

                                    <div className="d-flex align-items-center gap-2">
                                        <FormCheckBox
                                            name="Accept"
                                            hideError
                                            label={
                                                <span>
                                                    I have read the terms and conditions and I agree, see terms and
                                                    condtions
                                                </span>
                                            }
                                        />
                                        <button className="text-btn" type="button" onClick={() => setTermsOpen(true)}>
                                            here.
                                        </button>
                                    </div>

                                    {isTermsOpen && (
                                        <div>
                                            <TermsAndConditionsModal
                                                isOpen={isTermsOpen}
                                                setIsOpen={setTermsOpen}
                                                title="Registration Terms and Conditions"
                                                size="lg"
                                                style={{ width: '50vw !important' }}
                                            >
                                                <div style={{ lineHeight: '1.6', color: '#000000' }}>
                                                    <p style={{ textTransform: 'uppercase' }}>
                                                        I do affirm upon registration to use the services of the FEDERAL
                                                        AIRPORTS AUTHORITY OF NIGERIA (FAAN) Protocol Unit. FAAN and her
                                                        protocol officials are indemnified from any form of criminal
                                                        liability that may occur as a result of my conduct in the course
                                                        of BEING FACILITATED at the Airport. I do agree that the
                                                        integrity of my travelling is my responsibility, and I take full
                                                        responsibility for my actions.
                                                    </p>
                                                </div>
                                            </TermsAndConditionsModal>
                                        </div>
                                    )}
                                </main>
                                <footer className="d-flex flex-column align-items-center">
                                    <FormButton
                                        className="mb-4"
                                        type="submit"
                                        disabled={!isValid || isMatch(initialValues, values)}
                                    >
                                        <span>Complete registration</span>
                                    </FormButton>
                                    <span>
                                        Already have an account?{' '}
                                        <Link to="/login">
                                            <u>Log in</u>
                                        </Link>
                                    </span>
                                </footer>
                            </form>
                        )}
                    </Formik>
                </EntryFormContainer>
            </div>
        </Layout>
    );
};
