import { Booking } from '../../models/response';
import { ApiResponse } from '../../util/api-client';
import {
    AddBookingAction,
    BookingActionNames,
    ClearBookingAction,
    GetAllBookingsAction,
    GetOneBookingAction,
    UpdateBookingAction,
} from './bookings.types';

export class BookingActions {
    public static list(bookings: ApiResponse<Booking[]>): GetAllBookingsAction {
        return {
            type: BookingActionNames.GET_ALL,
            payload: bookings,
        };
    }

    public static retrieve(booking: Booking): GetOneBookingAction {
        return {
            type: BookingActionNames.GET_ONE,
            payload: booking,
        };
    }

    public static clear(): ClearBookingAction {
        return {
            type: BookingActionNames.CLEAR,
        };
    }

    public static add(booking: Booking): AddBookingAction {
        return {
            type: BookingActionNames.ADD,
            payload: booking,
        };
    }

    public static update(booking: Booking): UpdateBookingAction {
        return {
            type: BookingActionNames.UPDATE,
            payload: booking,
        };
    }
}
