import { Allow } from 'class-validator';
import { User } from '../../response';

export class EditUserModel {
    @Allow()
    public Id: String;

    constructor(user: User) {
        this.Id = user.id;
    }
}
