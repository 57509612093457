export const BusinessNatures = [
    'Agriculture',
    'Aviation',
    'Commercial and Retail',
    'Construction',
    'Education and Training',
    'Energy and Power Generation',
    'Fashion',
    'Financial Services',
    'Haulage and Logistics',
    'Healthcare',
    'ICT',
    'Manufacturing',
    'Media and Entertainment',
    'Oil and Gas',
    'Professional Services',
    'Real Estate',
    'Telecommunications',
    'Tourism and Hospitality',
    'Transportation',
    'Waste Management',
    'Others',
];
