import { IsBoolean, IsString } from 'class-validator';
import { User } from '../../response';

export class ChangeUserStatusModel {
    @IsString({ message: 'Invalid id' })
    public Id: string = '';

    @IsBoolean({ message: 'Must be active or inactive' })
    public Status: boolean = false;

    constructor(user: User) {
        if (user) {
            this.Id = user.id;
            this.Status = user.account_Status;
            return;
        }
    }
}
