export interface FeedbackFormValues {
    friendlinessRank: number;
    knowledgeRank: number;
    quicknessRank: number;
    eOptions: number;
    isHelpful: number | null;
    processFollowed: number | null;
    wouldUseAgain: number | null;
    wouldRecommendService: number | null;
    qualityRating: number | null;
    feedbackText: string;
    airportId: number;
}

export interface FeedbackFormErrors {
    friendlinessRank?: number;
    knowledgeRank?: number;
    quicknessRank?: number;
    eOptions?: number;
    isHelpful?: string;
    processFollowed?: string;
    wouldUseAgain?: string;
    wouldRecommendService?: string;
    qualityRating?: string;
    feedbackText?: string;
    airportId?: number;
}

export const initialValues: FeedbackFormValues = {
    friendlinessRank: 1,
    knowledgeRank: 1,
    quicknessRank: 1,
    eOptions: 1,
    isHelpful: null,
    processFollowed: null,
    wouldUseAgain: null,
    wouldRecommendService: null,
    qualityRating: null,
    feedbackText: '',
    airportId: 0,
};

export const validateForm = (values: FeedbackFormValues): FeedbackFormErrors => {
    const errors: FeedbackFormErrors = {};

    if (values.isHelpful === null) {
        errors.isHelpful = 'Please provide feedback on staff professionalism';
    }

    if (values.processFollowed === null) {
        errors.processFollowed = 'Please provide feedback on the protocol process';
    }

    if (values.wouldUseAgain === null) {
        errors.wouldUseAgain = 'Please specify if you will use the service again';
    }

    if (values.wouldRecommendService === null) {
        errors.wouldRecommendService = 'Please provide feedback on recommendations';
    }

    if (values.qualityRating === null) {
        errors.qualityRating = 'Please rate the quality of our services';
    }

    if (!values.feedbackText) {
        errors.feedbackText = 'Please provide feedback';
    }

    return errors;
};
