import { AuthenticatedUser } from '../../models/response';
import { AuthActionNames, LogInAction, LogOutAction } from './auth.types';

export class AuthActions {
    public static logIn(authUser: AuthenticatedUser): LogInAction {
        return {
            type: AuthActionNames.LOG_IN,
            payload: { authUser },
        };
    }

    public static logOut(): LogOutAction {
        return {
            type: AuthActionNames.LOG_OUT,
        };
    }
}
