export enum OrgCatergoryTypes {
    Corporate = 1,
    Government = 2,
    FAAN = 3,
    Individual = 4,
}

export const OrgCatergoryTypesMap = {
    [OrgCatergoryTypes.Corporate]: 'Corporate',
    [OrgCatergoryTypes.Government]: 'Government',
    [OrgCatergoryTypes.FAAN]: 'FAAN',
    [OrgCatergoryTypes.Individual]: 'Individual',
};
