import styled from 'styled-components';

export const ActivityItemStyleWrapper = styled.div`
    .activityItemWrapper {
        display: flex;
        flex-direction: row;
        padding: 0.1rem 0rem 0.2rem 0rem;
        border-bottom: 1px solid #dddddd;

        .item-1 {
            flex: 1;
        }

        .item-2 {
            flex: 4;
        }

        .item-3 {
            flex: 0.8;
            padding: 10px;

            button {
                cursor: pointer;
                font-weight: bold;
                text-transform: uppercase;
                height: 40px !important;
                padding: 5px 15px 5px 15px;
                font-size: 12px;
            }

            .pending {
                background-color: #e5e7e9;
                color: #000;
            }

            .success {
                background-color: #eafaf1;
                color: #18a956;
            }
        }
    }
`;
