import { Dispatch } from 'redux';
import { store } from '../..';
import { CreateBookingModel, UpdateBookingModel } from '../../models/request';
import { BookingsService } from '../../services';
import { NotificationTypes } from '../../util/constants';
import { history } from '../../util/history';
import { generateLoader } from '../../util/misc';
import { NotificationsThunk } from '../notifications/notifications.thunk';
import { BookingActions } from './bookings.actions';
import {
    AddBookingAction,
    ClearBookingAction,
    GetAllBookingsAction,
    GetOneBookingAction,
    UpdateBookingAction,
} from './bookings.types';

export class BookingsThunk {
    public static list(pageNumber?: number) {
        return async (dispatch: Dispatch<GetAllBookingsAction>) => {
            try {
                const response = await generateLoader(
                    BookingsService.list(store.getState().auth.authUser?.role, pageNumber),
                    dispatch,
                    'Fetching bookings'
                );

                if (response.data) {
                    if (
                        response.meta &&
                        pageNumber &&
                        (pageNumber > response.meta.lastPage || pageNumber < response.meta.firstPage)
                    ) {
                        history.replace('?page=1');
                        return;
                    }

                    dispatch(BookingActions.list(response));
                    return;
                }

                throw new Error('Fetching bookings failed. Kindly Refresh');
            } catch (error) {
                // TODO: dispatch error to errorReducer
                // to be implemented
            }
        };
    }

    public static retrieve(id: number) {
        return async (dispatch: Dispatch<GetOneBookingAction | ClearBookingAction>) => {
            try {
                dispatch(BookingActions.clear());

                const response = await generateLoader(
                    BookingsService.retrieve(id),
                    dispatch,
                    'Fetching booking details'
                );

                if (response.data) {
                    dispatch(BookingActions.retrieve(response.data));
                    return;
                }
            } catch (error) {}
        };
    }

    public static create(details: CreateBookingModel | CreateBookingModel[], closeModal?: () => void) {
        return async (dispatch: Dispatch<AddBookingAction>) => {
            try {
                const response = await generateLoader(BookingsService.create(details), dispatch, 'Creating booking');

                if (response.data) {
                    if (closeModal) closeModal();

                    NotificationsThunk.create(NotificationTypes.Success, 'Booking made successfully')(dispatch);

                    if (response.data.id) {
                        dispatch(BookingActions.add(response.data));
                    }
                    history.replace('/app/services/');

                    return;
                }

                NotificationsThunk.create(NotificationTypes.Error, 'Booking creation failed')(dispatch);
                throw new Error('Booking creation failed');
            } catch (error) {
                // TODO: dispatch error to errorReducer
                // to be implemented
            }
        };
    }

    public static update(details: UpdateBookingModel, closeModal?: () => void) {
        return async (dispatch: Dispatch<UpdateBookingAction>) => {
            try {
                const response = await generateLoader(BookingsService.update(details), dispatch, 'Updating booking');

                if (response.data) {
                    if (closeModal) closeModal();
                    NotificationsThunk.create(NotificationTypes.Success, 'Booking updated successfully')(dispatch);
                    dispatch(BookingActions.update(response.data));
                    return;
                }

                throw new Error('Booking update failed');
            } catch (error) {
                // TODO: dispatch error to errorReducer
                // to be implemented
            }
        };
    }

    public static cancel(id: number, closeModal?: () => void) {
        return async (dispatch: Dispatch<UpdateBookingAction>) => {
            try {
                const response = await generateLoader(BookingsService.cancel(id), dispatch, 'Canceling booking');

                if (response.data) {
                    if (closeModal) closeModal();

                    NotificationsThunk.create(NotificationTypes.Success, 'Booking canceled successfully')(dispatch);

                    return;
                }

                throw new Error('Booking update failed');
            } catch (error) {
                // TODO: dispatch error to errorReducer
                // to be implemented
            }
        };
    }
}
