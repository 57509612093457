import { capitalize } from 'lodash';
import moment from 'moment';
import React from 'react';
import { Cell } from 'react-table';
import { DataTable } from '../../../../components';
import { UserActivity } from '../../../../models/response';

interface ActivitiesTableProps {
    activities: UserActivity[];
}

export const ActivitiesTable: React.FC<ActivitiesTableProps> = ({ activities }) => {
    const latest_activities = activities
        .sort((a, b) => Date.parse(b.dateCreated) - Date.parse(a.dateCreated))
        .slice(0, 7);

    return (
        <DataTable
            columns={[
                {
                    Header: 'Date Created',
                    Cell: ({ row: { original } }: Cell<UserActivity>) => {
                        return <b>{moment(original.dateCreated).format('Do MMMM, YYYY,  h:mm:ss a')}</b>;
                    },
                },
                {
                    Header: 'User',
                    Cell: ({ row: { original: activity } }: Cell<UserActivity>) => {
                        const name =
                            (activity.user && activity.user.business_Name) ||
                            (activity.user && `${activity.user.firstName} ${activity.user.lastName}`);
                        return <span>{name}</span>;
                    },
                },
                {
                    Header: 'Email',
                    Cell: ({ row: { original: activity } }: Cell<UserActivity>) => {
                        const email = activity.user && activity.user.email;
                        return <span>{email}</span>;
                    },
                },
                {
                    Header: 'Activity',
                    accessor: 'description',
                },
            ]}
            data={latest_activities}
        />
    );
};
