import { Transaction } from '../../models/response';
import { ApiResponse } from '../../util/api-client';
import { ReduxAction } from '../common/redux-action';
import { TransactionRetrievalPayload, TransactionsActionNames, TransactionsState } from './transactions.types';

const initialState: TransactionsState = {
    transactions: [],
    transactionType: [],
};

export const transactionsReducer = (
    state = initialState,
    action: ReduxAction<TransactionsActionNames>
): TransactionsState => {
    switch (action.type) {
        case TransactionsActionNames.LIST: {
            const { transactions, meta: transactionsMeta } = action.payload as {
                transactions: Transaction[];
                meta: ApiResponse<Transaction[]>['meta'];
            };

            return { ...state, transactions, transactionsMeta };
        }

        case TransactionsActionNames.RETRIEVE: {
            const { transaction, details } = action.payload as TransactionRetrievalPayload;
            return { ...state, openTransaction: transaction, details };
        }

        case TransactionsActionNames.RETRIEVE_TYPE: {
            const { transactions, meta } = action.payload as {
                transactions: Transaction[];
                meta: ApiResponse<Transaction[]>['meta'];
            };

            return { ...state, transactionType: transactions, transactionTypeMeta: meta };
        }

        default: {
            return state;
        }
    }
};
