import React, { useState } from 'react';
import { Button, Modal, ModalBody } from 'reactstrap';
import { ModalButton } from '../utilities/modal/modal-button';
import { Field, Formik } from 'formik';
import styled from 'styled-components';
import { initialValues, validateForm } from './validate-feedback';
import { useDispatch } from 'react-redux';
import { FeedbackFormValues } from './validate-feedback';
import { FeedbackThunk } from '../../store/feedback';

const StyledModal = styled(Modal)`
    input[type='radio']:checked {
        accent-color: #18a956;
    }
`;

interface FeedbackPopUpProps {
    isOpen: boolean;
    toggle: () => void;
    handleSubmitFeedback: (feedback: FeedbackFormValues) => Promise<void>;
}

export const FeedackPopUp: React.FC<FeedbackPopUpProps> = ({ isOpen, toggle, handleSubmitFeedback }) => {
    const dispatch = useDispatch();

    return (
        <>
            <StyledModal isOpen={isOpen} toggle={toggle} centered size="lg">
                <div
                    style={{
                        display: 'flex',
                        width: '90%',
                        marginRight: 'auto',
                        marginLeft: 'auto',
                        flexDirection: 'column',
                    }}
                >
                    <div className="d-flex justify-content-between align-items-center py-4">
                        <span className="modal-title font-weight-600 text-lg">Client Feedback Form</span>
                        <ModalButton onClick={toggle} className="font-weight-600 fs-5 border-0" />
                    </div>
                    <ModalBody style={{ paddingTop: '0' }}>
                        <Formik
                            validate={validateForm}
                            validateOnMount={true}
                            initialValues={initialValues}
                            onSubmit={handleSubmitFeedback}
                        >
                            {({ handleSubmit, isValid, handleChange, values, setFieldValue }) => {
                                return (
                                    <form onSubmit={handleSubmit}>
                                        <div className="row mb-2 d-flex flex-column">
                                            <label>Protocol staff were helpful, courteous and professional?</label>
                                            <div className="d-flex ">
                                                <label className="mr-3">
                                                    <Field
                                                        type="radio"
                                                        name="isHelpful"
                                                        value={1}
                                                        className="mr-2"
                                                        checked={values.isHelpful === 1}
                                                        onChange={() => setFieldValue('isHelpful', 1)}
                                                    />
                                                    Yes
                                                </label>

                                                <label>
                                                    <Field
                                                        type="radio"
                                                        name="isHelpful"
                                                        value={0}
                                                        className="mr-2"
                                                        checked={values.isHelpful === 0}
                                                        onChange={() => setFieldValue('isHelpful', 0)}
                                                    />
                                                    No
                                                </label>
                                            </div>
                                        </div>

                                        <div className="row mb-2 d-flex flex-column">
                                            <label>The entire protocol facilitation process was seamless?</label>
                                            <div className="d-flex ">
                                                <label className="mr-3">
                                                    <Field
                                                        type="radio"
                                                        name="processFollowed"
                                                        value={1}
                                                        className="mr-2"
                                                        checked={values.processFollowed === 1}
                                                        onChange={() => setFieldValue('processFollowed', 1)}
                                                    />
                                                    Yes
                                                </label>

                                                <label>
                                                    <Field
                                                        type="radio"
                                                        name="processFollowed"
                                                        value={0}
                                                        className="mr-2"
                                                        checked={values.processFollowed === 0}
                                                        onChange={() => setFieldValue('processFollowed', 0)}
                                                    />
                                                    No
                                                </label>
                                            </div>
                                        </div>

                                        <div className="row mb-2 d-flex flex-column">
                                            <label>
                                                Will you use the FAAN Protocol service when next you are travelling?
                                            </label>
                                            <div className="d-flex ">
                                                <label className="mr-3">
                                                    <Field
                                                        type="radio"
                                                        name="wouldUseAgain"
                                                        value={1}
                                                        className="mr-2"
                                                        checked={values.wouldUseAgain === 1}
                                                        onChange={() => setFieldValue('wouldUseAgain', 1)}
                                                    />
                                                    Yes
                                                </label>

                                                <label>
                                                    <Field
                                                        type="radio"
                                                        name="wouldUseAgain"
                                                        value={0}
                                                        className="mr-2"
                                                        checked={values.wouldUseAgain === 0}
                                                        onChange={() => setFieldValue('wouldUseAgain', 0)}
                                                    />
                                                    No
                                                </label>
                                            </div>
                                        </div>

                                        <div className="row mb-2 d-flex flex-column">
                                            <label>
                                                Will you recommend the FAAN Protocol service to your colleagues, friends
                                                and family?
                                            </label>
                                            <div className="d-flex me-3">
                                                <label className="mr-3">
                                                    <Field
                                                        type="radio"
                                                        name="wouldRecommendService"
                                                        value={1}
                                                        checked={values.wouldRecommendService === 1}
                                                        onChange={() => setFieldValue('wouldRecommendService', 1)}
                                                        className="mr-2"
                                                    />
                                                    Yes
                                                </label>

                                                <label>
                                                    <Field
                                                        type="radio"
                                                        name="wouldRecommendService"
                                                        value={0}
                                                        checked={values.wouldRecommendService === 0}
                                                        onChange={() => setFieldValue('wouldRecommendService', 0)}
                                                        className="mr-2"
                                                    />
                                                    No
                                                </label>
                                            </div>
                                        </div>

                                        <div className="row mb-2 d-flex flex-column">
                                            <label>
                                                How would you rate the overall quality of the protocol services provided
                                                by our team?
                                            </label>
                                            <div className="d-flex ">
                                                {['Poor', 'Fair', 'Good', 'Very Good', 'Excellent'].map(
                                                    (quality, index) => (
                                                        <label className="mr-3">
                                                            <Field
                                                                type="radio"
                                                                name="qualityRating"
                                                                value={index}
                                                                checked={values.qualityRating === index}
                                                                onChange={() => setFieldValue('qualityRating', index)}
                                                                className="mr-2"
                                                            />
                                                            {quality}
                                                        </label>
                                                    )
                                                )}
                                            </div>
                                        </div>

                                        <div className="row mb-4 d-flex flex-column mt-3">
                                            <label>
                                                How would you rate the overall quality of the protocol services provided
                                                by our team?
                                            </label>
                                            <textarea
                                                style={{ fontSize: '14px' }}
                                                name="feedbackText"
                                                className="w-50 rounded-lg"
                                                onChange={handleChange}
                                                placeholder="Please specify areas to improve here"
                                            />
                                        </div>

                                        <div className="row d-flex mt-3 justify-content-between w-75">
                                            <button
                                                onClick={toggle}
                                                style={{
                                                    border: '1px solid red',
                                                    padding: '5px 10px',
                                                    color: 'red',
                                                    backgroundColor: 'transparent',
                                                    borderRadius: '5px',
                                                    fontSize: '15px',
                                                    height: '40px',
                                                    width: '150px',
                                                }}
                                            >
                                                CANCEL
                                            </button>
                                            <button
                                                // onClick={toggle}
                                                disabled={!isValid}
                                                type="submit"
                                                style={{
                                                    backgroundColor: `${!isValid ? '#79747E' : '#18A956'}`,
                                                    color: 'white',
                                                    border: '1px',
                                                    padding: '5px 10px',
                                                    borderRadius: '5px',
                                                    fontSize: '15px',
                                                    width: '180px',
                                                    height: '40px',
                                                }}
                                            >
                                                SUBMIT FEEDBACK
                                            </button>
                                        </div>
                                    </form>
                                );
                            }}
                        </Formik>
                    </ModalBody>
                </div>
            </StyledModal>
        </>
    );
};
