import { find } from 'lodash';
import React from 'react';
import { Check, Info, X } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { Cell } from 'react-table';
import { Col, Row } from 'reactstrap';
import styled from 'styled-components';
import { CustomPrimaryButton, DataTable } from '../../../../components';
import { AuthContext } from '../../../../context';
import { UpdateBookingModel } from '../../../../models/request';
import { AuthenticatedUser, Booking, Transaction } from '../../../../models/response';
import { Passenger } from '../../../../models/response/bookings/passenger';
import { ApplicationState } from '../../../../store';
import { BookingsThunk } from '../../../../store/bookings/bookings.thunk';
import { BookingStatuses, BookingStatusesMap } from '../../../../util/constants';
import { canCancelBooking, formatCurrency } from '../../../../util/misc';
import { formatDate, formatTime } from '../../../../util/misc/formatters';

const StyleWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;

    .detail-row {
        display: grid;
        grid-template-columns: 200px 1fr;
        padding: 20px 0;
        font-size: 14px;
        border-bottom: 2px solid #eaeaea;
    }
`;

export const HeaderStyleWrapper = styled.div`
    display: flex;
    flex-direction: row;

    .left__header {
        flex: 1;
        display: flex;
        flex-direction: row;
    }

    .right {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
    }
`;

interface DetailRow {
    property: string;
    value: string | number;
}

interface Props {
    booking: Booking;
    transaction?: Transaction;
}

export const ViewBookingInformationComponent: React.FC<Props> = ({ booking, transaction }) => {
    const { user } = React.useContext(AuthContext.Context);
    const airports = useSelector((state: ApplicationState) => state.airports);
    const dispatch = useDispatch();

    const canCancel = React.useMemo(() => {
        return canCancelBooking(booking, user as AuthenticatedUser);
        // eslint-disable-next-line
    }, [booking, booking.bookingStatus, user]);

    const handleSetStatus = React.useCallback(
        (status: BookingStatuses) => {
            if (booking) {
                if (booking.bookingStatus !== status) {
                    booking.bookingStatus = status;
                    dispatch(BookingsThunk.update(new UpdateBookingModel(booking)));
                }
            }
        },
        [dispatch, booking]
    );

    const details: DetailRow[] = React.useMemo(
        () => [
            { property: 'Airport', value: find(airports.airports, { id: booking.airportId })?.name ?? 'N/A' },
            { property: 'Travel date', value: formatDate(booking.date_Of_Travel) },
            { property: 'Flight number', value: booking.flight_Number },
            {
                property: 'Airport arrival time',
                value: formatTime(booking.date_Of_Arrival),
            },
            {
                property: 'Updated By',
                value: booking.modifiedBy_FirstName ?? '',
            },
            {
                property: 'Airport Name',
                value: find(airports.airports, { id: booking?.airportId })?.name ?? '',
            },
            {
                property: 'Booked by',
                value: (
                    <span>
                        {booking?.applicationUser?.firstName} {booking?.applicationUser?.lastName}
                    </span>
                ) as any,
            },
            {
                property: 'Status',
                value: BookingStatusesMap[booking.bookingStatus]
                    .replace('Canceled', 'Cancelled')
                    .replace('Flight_Canceled', 'Flight_Cancelled'),
            },
        ],
        [booking, airports.airports]
    );

    return (
        <StyleWrapper>
            <Row>
                <Col size={12} xl={5}>
                    {details.map(({ property, value }, i) => {
                        return (
                            <div className="detail-row" key={i}>
                                <b>{property}</b>
                                <span>{value}</span>
                            </div>
                        );
                    })}
                </Col>
                <Col size={12} xl={7} className="d-flex flex-column align-items-end mt-5 mt-xl-0">
                    <DataTable
                        columns={[
                            {
                                Header: 'First name',
                                accessor: 'firstName',
                            },
                            {
                                Header: 'Last name',
                                accessor: 'lastName',
                            },
                            {
                                Header: 'Designation',
                                accessor: 'designation',
                            },
                            {
                                Header: 'Gender',
                                accessor: 'gender',
                            },
                            {
                                Header: 'Wheel chair',
                                accessor: 'wheel_Chair',
                                Cell: ({ row: { original: passenger } }: Cell<Passenger>) =>
                                    passenger.wheel_Chair ? (
                                        <Check size={14} color="var(--primary-color)" strokeWidth={2} />
                                    ) : (
                                        <X size={14} strokeWidth={2} />
                                    ),
                            },
                            {
                                Header: 'Special requirements',
                                accessor: 'special_Requirement',
                                Cell: ({ row: { original: passenger } }: Cell<Passenger>) => {
                                    const id = `passenger${passenger.id}`;

                                    return (
                                        <div id={id} title={passenger.special_Requirement ?? ''}>
                                            {passenger.special_Requirement ? (
                                                <Info size={14} strokeWidth={2} />
                                            ) : (
                                                <X size={14} strokeWidth={2} />
                                            )}
                                        </div>
                                    );
                                },
                            },
                        ]}
                        data={booking.passengers || []}
                    />
                    <div className="d-flex mt-4 justify-content-end">
                        <div className="mt-3">
                            <b className="mr-3">Total price</b>
                            <b>{formatCurrency(booking.price)}</b>
                        </div>
                    </div>
                </Col>
            </Row>
            <div className="d-flex mt-4">
                {canCancel && (
                    <CustomPrimaryButton onClick={handleSetStatus.bind(undefined, BookingStatuses.Canceled)}>
                        <span>Cancel</span>
                    </CustomPrimaryButton>
                )}
            </div>
        </StyleWrapper>
    );
};
