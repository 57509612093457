import { Field } from 'formik';
import React from 'react';
import styled from 'styled-components';

export const SwitchContainer = styled.label`
    display: flex;
    position: relative;
    align-items: center;
    flex-wrap: wrap;
    margin: 0;
    cursor: pointer;

    input {
        opacity: 0;
        filter: opacity(0);
        position: absolute;
        top: 0;
        left: 0;
        margin: 0;
        width: 100%;
        height: 100%;
    }

    i {
        position: relative;
        width: 30px;
        height: 17px;
        background: #e1f4e9;
        border-radius: 12px;
        transition: all 0.5s ease;
    }

    i::after {
        transition: left 0.15s ease;
        position: absolute;
        content: ' ';
        display: block;
        background: var(--primary-color);
        height: 11px;
        width: 11px;
        border-radius: 100%;
        left: 3px;
        top: 0;
        margin: 3px 0;
    }

    input:checked + i,
    input[value='true'] + i {
        background: #e1f4e9;
    }

    input:checked + i::after,
    input[value='true'] + i::after {
        left: calc(100% - 15px);
    }

    &.disabled {
        cursor: no-drop;

        input + i {
            background: orange;
            border-color: orange;
        }

        input + i::after {
            border-color: orange;
        }
    }
`;

interface FormSwitchProps extends React.ComponentPropsWithoutRef<'input'> {
    name: string;
    disabled?: boolean;
}

interface PresentationalFormSwitchProps {
    checked: boolean | undefined;
}

export const FormSwitch: React.FC<FormSwitchProps> = ({ disabled, ...props }) => {
    return (
        <SwitchContainer className={disabled ? 'disabled' : ''}>
            <Field type="checkbox" disabled={disabled} {...props} />
            <i />
        </SwitchContainer>
    );
};

export const PresentationalFormSwitch: React.FC<PresentationalFormSwitchProps> = ({ checked }) => {
    return (
        <SwitchContainer>
            <input type="checkbox" defaultChecked={checked} disabled />
            <i />
        </SwitchContainer>
    );
};
