import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row } from 'reactstrap';
import styled from 'styled-components';
import { AppLayout, AuthLayout, ServiceCardComponent, TopBar } from '../../../components';
import { Breadcrumb } from '../../../components/breadcrumbs/breadcrumbs';
import { ApplicationState } from '../../../store';
import { ServiceThunk } from '../../../store/services/services.thunk';
import { formatCurrency } from '../../../util/misc';

const HeaderStyleWrapper = styled.div`
    display: flex;
    flex-direction: row;

    .left__header {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .right {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
    }
`;

export const ServicePage: React.FC = () => {
    const breadcrumbs: Breadcrumb[] = [
        { name: 'Dashboard', path: '/app/dashboard' },
        { name: 'All Services', path: window.location.href },
    ];

    const dispatch = useDispatch();
    const { services } = useSelector((state: ApplicationState) => state);

    React.useEffect(() => {
        dispatch(ServiceThunk.getAllServices());
    }, [dispatch]);

    return (
        <AuthLayout showSidebar={false}>
            <TopBar breadcrumbs={breadcrumbs} />
            <AppLayout.Wrapper>
                <AppLayout.Header>
                    <HeaderStyleWrapper>
                        <div className="left__header">
                            <h3 className="header__title">All Services</h3>
                        </div>
                    </HeaderStyleWrapper>
                </AppLayout.Header>

                <Row>
                    {services.services?.length > 0 ? (
                        services.services.map((service) => (
                            <Col lg={4} key={service.id}>
                                <ServiceCardComponent
                                    title={service.name}
                                    to={`/app/service/${service.id}/create-booking`}
                                    desc={service.description}
                                    price={formatCurrency(service.price)}
                                />
                            </Col>
                        ))
                    ) : (
                        <p>There are no services</p>
                    )}
                </Row>
            </AppLayout.Wrapper>
        </AuthLayout>
    );
};
