import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row } from 'reactstrap';
import { AppLayout, AuthLayout, Breadcrumb, ServiceCardComponent, TopBar } from '../../../components';
import { ApplicationState } from '../../../store';
import { ServiceThunk } from '../../../store/services/services.thunk';
import { formatCurrency } from '../../../util/misc';

export const ClientService: React.FC<[]> = () => {
    const breadcrumbs: Breadcrumb[] = [
        { name: 'Dashboard', path: '/app/dashboard' },
        { name: 'All Services', path: window.location.href },
    ];

    const dispatch = useDispatch();
    const { services } = useSelector((state: ApplicationState) => state.services);

    useEffect(() => {
        dispatch(ServiceThunk.getClientService());
    }, [dispatch]);

    return (
        <AuthLayout showSidebar={false}>
            <TopBar breadcrumbs={breadcrumbs} />
            <AppLayout.Wrapper>
                <AppLayout.Header>
                    <h3 className="header__title">How may we help you today?</h3>
                </AppLayout.Header>
                <Row>
                    {services && services.length ? (
                        services.map((service) => (
                            <Col lg={4} key={service.id}>
                                <ServiceCardComponent
                                    title={service.name}
                                    to={`/app/service/${service.id}/create-booking`}
                                    desc={service.description}
                                    price={formatCurrency(service.price)}
                                />
                            </Col>
                        ))
                    ) : (
                        <p>There are no services</p>
                    )}
                </Row>
            </AppLayout.Wrapper>
        </AuthLayout>
    );
};
