import { Matches, MinLength } from 'class-validator';
import { ShouldMatchField } from '../../../util/validation';
import { BaseModel } from '../base-model';
import {
    CONTAINS_DIGIT,
    CONTAINS_LOWERCASE,
    CONTAINS_SPECIAL_CHARACTER,
    CONTAINS_UPPERCASE,
    PASSWORD_MIN_LENGTH,
} from './constants';

export class ResetPasswordModel implements BaseModel {
    @Matches(CONTAINS_DIGIT, {
        message: 'Password must contain a digit',
    })
    @Matches(CONTAINS_SPECIAL_CHARACTER, {
        message: 'Password must contain a special character',
    })
    @Matches(CONTAINS_UPPERCASE, {
        message: 'Password must contain an uppercase letter',
    })
    @Matches(CONTAINS_LOWERCASE, {
        message: 'Password must contain an lowercase letter',
    })
    @MinLength(PASSWORD_MIN_LENGTH, {
        message: `Password must be at least ${PASSWORD_MIN_LENGTH} character(s) long`,
    })
    public Password: string = '';

    @ShouldMatchField('Password', { message: 'Must match password' })
    public ConfirmPassword: string = '';

    public ActivationCode: String = '';

    public beforeValidation() {}
}
