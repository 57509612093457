import React from 'react';
import { ArrowLeft, ArrowRight } from 'react-feather';
import {
    TableInstance,
    TableOptions,
    usePagination,
    UsePaginationInstanceProps,
    UsePaginationState,
    useSortBy,
    useTable,
} from 'react-table';
import { Table } from 'reactstrap';
import styled from 'styled-components';
import { ApiResponse } from '../../../util/api-client';
import { setSearch } from '../../../util/history';
import { CustomPrimaryButton } from '../custom-primary-button/custom-primary-button';

const StyledTable = styled(Table)`
    color: black;
    /* width: unset;
    min-width: 100%; */
    font-size: 14px !important;

    thead {
        tr,
        th {
            border: 0 !important;
            font-weight: bold !important;
        }
    }

    th,
    td {
        padding-left: 0;
        vertical-align: middle;
    }
`;

const ButtonArea = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 1rem;
    overflow-x: auto;

    > section {
        margin-bottom: 1rem;
        display: flex;

        button:disabled {
            background: #aaa;
            border-color: #aaa;
            color: #333;
        }
    }

    .page-separator {
        display: flex;
    }

    .page-separator + .page-separator {
        &::before {
            display: block;
            content: '-';
            margin: 0 1rem;
        }
    }
`;

interface DataTableProps<T> {
    className?: string;
    hideHead?: boolean;
    meta?: ApiResponse<T>['meta'];
}

interface PaginationInstance extends UsePaginationInstanceProps<any> {
    state: UsePaginationState<any>;
}

const PAGE_SIZE = 10;

export function DataTable<T extends object = any>({
    className,
    hideHead,
    columns,
    data,
    meta,
    ...props
}: DataTableProps<T> & TableOptions<T>) {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        rows,
    }: TableInstance<any> & PaginationInstance = useTable(
        {
            columns,
            data,
            initialState: { pageSize: PAGE_SIZE },
            ...props,
        },
        useSortBy,
        usePagination
    );

    const pageIndex = React.useMemo(() => meta?.pageNumber || 1, [meta]);

    const canPreviousPage = React.useMemo(() => meta && meta.pageNumber > meta.firstPage, [meta]);
    const canNextPage = React.useMemo(() => meta && meta.pageNumber < meta.lastPage, [meta]);

    const gotoPage = React.useCallback((page: number) => {
        setSearch('page', page);
    }, []);

    const nextPage = React.useCallback(() => {
        gotoPage(meta!.nextPage);
    }, [gotoPage, meta]);

    const previousPage = React.useCallback(() => {
        gotoPage(meta!.previousPage);
    }, [gotoPage, meta]);

    const pageOptions = React.useMemo(() => {
        const pages = [...new Array(meta?.totalPages)].map((_, i) => i + 1);
        const midpoint = Math.floor(pages.length / 2);

        if (pages.length <= 8) {
            return [pages];
        }

        const head = pages.slice(0, 1);
        const tail = pages.slice(-1);

        let body =
            pageIndex === 1 || pageIndex === pages.length
                ? pages.slice(midpoint - 1, midpoint + 2)
                : pages.slice(pageIndex - 2, pageIndex + 1);

        if (head.includes(body[0])) {
            body = body.slice(1);
        }

        if (tail.includes(body[body.length - 1])) {
            body = body.slice(0, -1);
        }

        // if (meta && meta.totalPages > 6) {

        // }

        // // if (meta && meta.totalPages > 6) {
        // //     return pages.slice(0, 2).concat(pages.slice(-2));
        // // }

        // // if (!pages.includes(pageIndex)) {
        // //     pages.push(pageIndex);
        // // }

        return [head, body, tail];
    }, [pageIndex, meta]);

    return (
        <React.Fragment>
            <StyledTable {...getTableProps()} className={className} responsive>
                {hideHead ? null : (
                    <thead>
                        {headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column) => (
                                    <th {...column.getHeaderProps()}>
                                        <div>
                                            <span>{column.render('Header')}</span>
                                        </div>
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                )}
                <tbody {...getTableBodyProps()}>
                    {rows.map((row, i) => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()} key={i}>
                                {row.cells.map((cell, j) => {
                                    return (
                                        <td {...cell.getCellProps()} key={j}>
                                            {cell.render('Cell')}
                                        </td>
                                    );
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </StyledTable>
            {meta && (
                <ButtonArea>
                    <section>
                        <CustomPrimaryButton
                            className="small"
                            onClick={() => previousPage()}
                            disabled={!canPreviousPage}
                        >
                            <ArrowLeft size={15} />
                        </CustomPrimaryButton>
                        {pageOptions.map((section, i) => (
                            <section className="page-separator" key={i}>
                                {section.map((page) => (
                                    <CustomPrimaryButton
                                        className="small"
                                        onClick={() => {
                                            gotoPage(page);
                                        }}
                                        disabled={page === pageIndex}
                                        key={page}
                                    >
                                        {page}
                                    </CustomPrimaryButton>
                                ))}
                            </section>
                        ))}
                        <CustomPrimaryButton className="small" onClick={() => nextPage()} disabled={!canNextPage}>
                            <ArrowRight size={15} />
                        </CustomPrimaryButton>
                    </section>
                    <span>
                        Showing {(pageIndex - 1) * PAGE_SIZE + 1} to {(pageIndex - 1) * PAGE_SIZE + page.length} of{' '}
                        {meta.totalCount} {meta.totalCount !== 1 ? 'entries' : 'entry'}
                    </span>
                </ButtonArea>
            )}
        </React.Fragment>
    );
}
