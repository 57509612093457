export enum Honorifics {
    Mr = 'Mr.',
    Mrs = 'Mrs.',
    Master = 'Master',
    Miss = 'Ms.',
    Dr = 'Dr.',
}

export const HonorificsMap = {
    [Honorifics.Mr]: 'Mr.',
    [Honorifics.Mrs]: 'Mrs.',
    [Honorifics.Master]: 'Master',
    [Honorifics.Miss]: 'Ms.',
    [Honorifics.Dr]: 'Dr.',
};
