import { Button } from 'reactstrap';
import styled from 'styled-components';

export const CustomPrimaryButton = styled(Button)`
    background: var(--primary-color);
    height: 40px !important;
    font-weight: bold;
    color: white;
    border-radius: 5px;
    border: 1px solid var(--primary-color);
    outline: none !important;
    appearance: none;
    display: inline-flex;
    justify-content: center;
    text-transform: uppercase;
    letter-spacing: 1.4px;
    align-items: center;
    padding: 0 15px;
    box-shadow: none;
    min-width: max-content;
    font-size: 14px !important;
    margin-right: 5px;

    :hover,
    :focus,
    :active {
        color: white !important;
        background: var(--primary-color) !important;
        border-color: var(--primary-color) !important;
        box-shadow: none;
    }

    :disabled {
        background: #848484;
        cursor: not-allowed;
    }

    &.small {
        height: 28px !important;
        text-transform: none;
        letter-spacing: 0;
        font-weight: normal !important;
    }

    &.inverted {
        background: white;
        color: var(--primary-color);

        :hover,
        :focus,
        :active {
            background: white !important;
            color: var(--primary-color) !important;
        }
    }
`;
