import { uniqueId } from 'lodash';
import { Dispatch } from 'redux';
import { LoadingActions } from '../../store/loading/loading.actions';

export const generateLoader = async <T>(promise: Promise<T>, dispatch: Dispatch, message?: string) => {
    const id = uniqueId();
    dispatch(LoadingActions.setLoading(id));
    const returnValue = await promise;
    dispatch(LoadingActions.setDone(id));
    return returnValue;
};
