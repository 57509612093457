import React from 'react';
import { X } from 'react-feather';
import styled from 'styled-components';

const ButtonBody = styled.button`
    background: white;
    width: 20px;
    height: 20px;
    border-radius: 100%;
    border: 2px solid #dadada;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 !important;
    outline: none !important;
`;

export const ModalButton: React.FC<React.ComponentPropsWithoutRef<'button'>> = (props) => {
    return (
        <ButtonBody {...props}>
            <X size={12} strokeWidth={3} />
        </ButtonBody>
    );
};
