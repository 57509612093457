import { Booking, Transaction, User } from '../../models/response';
import { ApiResponse } from '../../util/api-client';

type Details = User | Booking;

export interface TransactionsState {
    transactions: Transaction[];
    transactionsMeta?: ApiResponse<Transaction[]>['meta'];
    openTransaction?: Transaction;
    transactionType: Transaction[];
    transactionTypeMeta?: ApiResponse<Transaction[]>['meta'];
    details?: Details;
}

export interface TransactionRetrievalPayload {
    transaction?: Transaction;
    details?: Details;
}

export enum TransactionsActionNames {
    LIST = 'TRANSACTIONS_LIST',
    RETRIEVE = 'TRANSACTIONS_RETRIEVE',
    RETRIEVE_TYPE = 'RETRIEVE_TYPE',
}
