import { IsEnum, IsPositive } from 'class-validator';
import { BookingStatuses } from '../../../util/constants';
import { Booking } from '../../response';
import { BaseModel } from '../base-model';

export class UpdateBookingModel implements BaseModel {
    @IsPositive({ message: 'Invalid id' })
    public Id: number;

    @IsEnum(BookingStatuses, { message: 'Must either confirm or decline' })
    public BookingStatus: BookingStatuses;

    constructor(booking: Booking) {
        this.Id = +booking.id;
        this.BookingStatus = booking.bookingStatus;
    }

    public finalize() {}
}
