import moment from 'moment';
import React, { CSSProperties, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Button, Col, Row } from 'reactstrap';
import { SignOutSwitchComponent } from '..';
import { usePageMatch } from '../../hooks';
import { TransactionsService } from '../../services';
import { ApplicationState, TransactionsThunk } from '../../store';
import { TransactionTypes } from '../../util/constants';
import { FundWalletModal } from './fund-wallet-modal';
import { MobileWallet } from './mobile-wallet';

const styles: Record<string, CSSProperties> = {
    bullet: {
        width: 12,
        height: 12,
        borderRadius: '50%',
        display: 'inline-block',
        background: '#26A955',
    },
};

export const WalletView: React.FC = () => {
    const { matches: isMobile } = usePageMatch('(max-width: 970px)');
    const [isFunding, setFundingModal] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [amount, setAmount] = useState(0);
    const { transactionType } = useSelector(({ transactions }: ApplicationState) => transactions);
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        setLoading(true);
        TransactionsService.getWalletBalance()
            .then(({ data }) => {
                setAmount(data?.balance.balance ?? 0);
            })
            .catch((err) => console.log({ err }))
            .finally(() => setLoading(false));
    }, []);

    useEffect(() => {
        dispatch(TransactionsThunk.retrieveByTransactionType(TransactionTypes.Fund, 1));
    }, [dispatch]);

    const transactions = useMemo(
        () =>
            transactionType
                .sort((a, b) => (new Date(a.dateCreated).getTime() < new Date(b.dateCreated).getTime() ? 1 : -1))
                .splice(0, 5),
        [transactionType]
    );

    return isFunding ? (
        <FundWalletModal
            isOpen={isFunding}
            onComplete={(value) => setAmount((prev) => prev + value)}
            setIsOpen={setFundingModal}
        />
    ) : isMobile ? (
        <MobileWallet walletBalance={amount} setFundingModal={setFundingModal} transactions={transactions} />
    ) : (
        <aside className="rightSidebar">
            <div className="mb-6">
                <Row>
                    <Col lg="7"></Col>
                    <Col lg="5">{/* <SignOutSwitchComponent /> */}</Col>
                </Row>
            </div>
            <div>
                <h4>Wallet</h4>
                <div className="quickDiv">
                    <div className="header">Balance</div>
                    <p>&#8358;&nbsp;{amount.toLocaleString()}</p>
                    <Button color="success" size="md" className="btn-btn" onClick={() => setFundingModal(true)}>
                        <span>Fund</span>
                    </Button>
                </div>

                <div className="contactDiv">
                    <table style={{ width: '100%' }}>
                        <thead style={{ fontSize: 15 }}>
                            <tr>
                                <th style={{ whiteSpace: 'nowrap' }}>Last Transactions</th>
                                <th>
                                    <button
                                        onClick={() => history.push('/app/transaction-services')}
                                        className="btn btn-outline-success border-0 mb-0"
                                        style={{ fontSize: 12 }}
                                    >
                                        View All
                                    </button>
                                </th>
                            </tr>
                        </thead>

                        <tbody style={{ fontSize: 12 }}>
                            {transactions.map(({ amount, id, dateCreated }) => (
                                <tr key={id}>
                                    <td>
                                        <div className="d-flex align-items-center gap-4">
                                            <span style={styles.bullet} />
                                            {amount.toLocaleString()}
                                        </div>
                                    </td>
                                    <td style={{ whiteSpace: 'nowrap' }}>
                                        {moment(dateCreated).format('Do MMMM, YYYY')}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </aside>
    );
};
