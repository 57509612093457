export enum ClientStatuses {
    Processing = 'Processing',
    Inactive = 'Inactive',
    Active = 'Active',
    New = 'New',
    Deactivated = 'Deactivated',
}

export const ClientStatusesMap = {
    [ClientStatuses.Processing]: ClientStatuses.Processing,
    [ClientStatuses.Inactive]: ClientStatuses.Inactive,
    [ClientStatuses.Active]: ClientStatuses.Active,
    [ClientStatuses.New]: ClientStatuses.New,
    [ClientStatuses.Deactivated]: ClientStatuses.Deactivated,
};
