import React, { useState } from 'react';
import { Accordion, AccordionBody, AccordionHeader, AccordionItem, UncontrolledAccordion } from 'reactstrap';
import { CustomPrimaryButton } from '../utilities/custom-primary-button/custom-primary-button';
import './accordion-style.scss';

const FAQ = () => {
    return (
        <div>
            <UncontrolledAccordion defaultOpen={['1']} stayOpen className="mb-9">
                <AccordionItem>
                    <AccordionHeader targetId="1">
                        {' '}
                        <strong>How to Register</strong>{' '}
                    </AccordionHeader>
                    <AccordionBody accordionId="1">
                        <div className="bg-custom-green p-4">
                            <ol>
                                <li>On the FAAN APS landing page click on the button “REGISTER”. </li>
                                <li>
                                    Choose what type of profile you want to register with (Individual, Government
                                    Official, Corporate Organization).{' '}
                                </li>
                                <li>Fill the form correctly and click "NEXT STEP". </li>
                                <li>Go to your mail box and verify your email. </li>
                            </ol>
                        </div>
                    </AccordionBody>
                </AccordionItem>
                <AccordionItem>
                    <AccordionHeader targetId="2">
                        {' '}
                        <strong>How to Fund Wallet </strong>{' '}
                    </AccordionHeader>
                    <AccordionBody accordionId="2">
                        <div className="bg-custom-green p-4">
                            <ol>
                                <li>On the far right of the dashboard click on the button labelled "FUND" </li>
                                <li>
                                    On the pop-up form, insert an amount to fund wallet with and click "FUND WALLET".
                                    (Example: N500,000)
                                </li>
                                <li> An invoice will be sent to your email </li>
                                <li>
                                    {' '}
                                    Use the RRR number sent to your email to make payment to REMITA and your wallet will
                                    be funded with the amount on the generated invoice.{' '}
                                </li>
                            </ol>
                        </div>
                    </AccordionBody>
                </AccordionItem>
                <AccordionItem>
                    <AccordionHeader targetId="3">
                        {' '}
                        <strong>How to Book Services</strong>{' '}
                    </AccordionHeader>
                    <AccordionBody accordionId="3">
                        <div className="bg-custom-green p-4">
                            <ol>
                                <li>
                                    On the "All Services" page click on a particular service from the list of services
                                    and click on "Book Services". You can also view recently booked services on the
                                    dashboard.
                                </li>
                                <li>
                                    {' '}
                                    Fill the form correctly and click “Add passenger” (Note you can add multiple
                                    passengers by repeating this process).{' '}
                                </li>
                                <li>
                                    {' '}
                                    Click on the terms and condition tick box and click on the button “Continue” to
                                    confirm a booking.{' '}
                                </li>
                            </ol>
                        </div>
                    </AccordionBody>
                </AccordionItem>
                <AccordionItem>
                    <AccordionHeader targetId="4">
                        {' '}
                        <strong>User manual</strong>{' '}
                    </AccordionHeader>
                    <AccordionBody accordionId="4">
                        <div className="bg-custom-green p-4">
                            <CustomPrimaryButton className="inverted" type="submit">
                                <a
                                    href={require(`../../assets/files/client_manual.pdf`)}
                                    style={{ color: ' #18a956' }}
                                    target="blank"
                                >
                                    New client manual
                                </a>
                            </CustomPrimaryButton>
                            <CustomPrimaryButton className="inverted" type="submit">
                                <a
                                    href={require(`../../assets/files/existing_user_manual.pdf`)}
                                    style={{ color: ' #18a956' }}
                                    target="blank"
                                >
                                    Existing client manual
                                </a>
                            </CustomPrimaryButton>
                        </div>
                    </AccordionBody>
                </AccordionItem>
            </UncontrolledAccordion>
        </div>
    );
};

export default FAQ;
