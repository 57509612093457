import { Formik } from 'formik';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { EntryFormContainer } from '../../../components';
import { Breadcrumb, Breadcrumbs } from '../../../components/breadcrumbs/breadcrumbs';
import { FormButton, FormLargeRadioButton } from '../../../components/form';
import Layout, { StickyBackground } from '../../../components/layout';

enum AccountTypes {
    GovernmentOfficial,
    CorporateOrganization,
    Individual,
    Family,
}

interface RegistrationAccountOptions {
    accountType: AccountTypes;
}

export const RegisterAccountPage: React.FC = () => {
    const breadcrumbs: Breadcrumb[] = [
        { name: 'Home', path: '/' },
        { name: 'Create account', path: window.location.href },
    ];

    const history = useHistory();

    return (
        <Layout>
            <StickyBackground />
            <div className="px-5 py-3">
                <Breadcrumbs breadcrumbs={breadcrumbs} />
                <EntryFormContainer>
                    <Formik
                        initialValues={
                            {
                                accountType: AccountTypes.CorporateOrganization,
                            } as RegistrationAccountOptions
                        }
                        onSubmit={({ accountType }) => {
                            const registrationPage =
                                '/register-' +
                                (+accountType === AccountTypes.CorporateOrganization
                                    ? 'corporate-organization'
                                    : +accountType === AccountTypes.GovernmentOfficial
                                    ? 'government-official'
                                    : +accountType === AccountTypes.Individual
                                    ? 'individual'
                                    : 'family');

                            history.push(registrationPage);
                        }}
                    >
                        {({ handleSubmit }) => (
                            <form onSubmit={handleSubmit} className="d-flex flex-column align-items-center">
                                <header className="form__header mb-5">
                                    <h1>Select a role to register</h1>
                                </header>
                                <main className="d-flex flex-column align-items-center mb-5">
                                    <FormLargeRadioButton
                                        className="mb-3"
                                        name="accountType"
                                        label="CORPORATE ORGANIZATION"
                                        value={AccountTypes.CorporateOrganization}
                                    />
                                    <FormLargeRadioButton
                                        className="mb-3"
                                        name="accountType"
                                        label="GOVERNMENT OFFICIAL"
                                        value={AccountTypes.GovernmentOfficial}
                                    />
                                    <FormLargeRadioButton
                                        name="accountType"
                                        label="INDIVIDUAL"
                                        value={AccountTypes.Individual}
                                    />

                                    <FormLargeRadioButton
                                        name="accountType"
                                        label="FAMILY"
                                        value={AccountTypes.Family}
                                    />
                                </main>
                                <footer className="d-flex flex-column align-items-center">
                                    <FormButton type="submit">
                                        <span>Continue</span>
                                    </FormButton>
                                </footer>
                            </form>
                        )}
                    </Formik>
                </EntryFormContainer>
            </div>
        </Layout>
    );
};
