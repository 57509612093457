import React from 'react';
import { OtherUsersTransactionServices } from '.';
import { AuthContext } from '../../context';
import { Roles } from '../../util/constants';
import { ClientTransactionServices } from './views';

export const TransactionServicesPage: React.FC = () => {
    const { user } = React.useContext(AuthContext.Context);

    return user?.role === Roles.Client ? <ClientTransactionServices user={user} /> : <OtherUsersTransactionServices />;
};
