import { Allow, IsEnum, IsString, Matches, MinLength } from 'class-validator';
import { MeansOfId } from '../../../util/constants';
import { IsMobileNumber } from '../../../util/validation';
import { User } from '../../response';
import { BaseModel } from '../base-model';

export class UpdateIndividualClientModel implements BaseModel {
    @IsString({ message: 'Invalid id' })
    public id: string = '';

    @Matches(/^[^\s].*[^\s]$/, { message: 'Cannot begin or end with a space' })
    @MinLength(3, {
        message: 'Must be at least $constraint1 characters long',
    })
    public firstName: string = '';

    @Allow()
    public middleName: string = '';

    @Matches(/^[^\s].*[^\s]$/, { message: 'Cannot begin or end with a space' })
    @MinLength(3, {
        message: 'Must be at least $constraint1 characters long',
    })
    public lastName: string = '';

    @IsMobileNumber({ message: 'Invalid phone number format' })
    public phoneNumber: string = '';

    @Matches(/^[^\s].*[^\s]$/, { message: 'Cannot begin or end with a space' })
    @MinLength(3, {
        message: 'Must be at least $constraint1 characters long',
    })
    @IsEnum(MeansOfId, { message: 'Invalid selection' })
    public meansOfId: number = 0;

    @MinLength(3, {
        message: 'Must be at least $constraint1 characters long',
    })
    public identification: string = '';

    constructor(client: User) {
        if (client) {
            this.id = client.id;
            this.firstName = client.firstName;
            this.middleName = client.middleName;
            this.lastName = client.lastName;
            this.phoneNumber = client.phoneNumber;
            this.meansOfId = client.meansOfId;
            this.identification = client.identification;
            return;
        }
    }

    public beforeValidation() {}
}
