import { registerDecorator, ValidationArguments } from 'class-validator';

export function ConvertToNumber() {
    return function (object: Object, propertyName: string) {
        registerDecorator({
            name: 'convertToNumber',
            target: object.constructor,
            propertyName,
            constraints: [],
            validator: {
                validate(value: any, args: ValidationArguments) {
                    (args.object as any)[propertyName] = +value;
                    return true;
                },
            },
        });
    };
}
